import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {message, Button, Form, Input, Radio} from "antd";

import {styles} from "../ApiService/Data";
import API from "../ApiService/ApiService";

import {
  registerGetAllStart,
  registerGetAllFail,
  registerGetAllSuccess,
} from "../Redux/slices/user";
import crud_service from "../ApiService/CrudService";

const Register = () => {
  const [form] = Form.useForm();
  const [isSaving, setSaving] = useState(false);
  const [isLoading, setloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginTrue = useSelector((state) => state?.user?.user?.token);
  const user = useSelector((state) => state?.user?.user);
  const api = new crud_service();

  console.log("loginTrue", loginTrue);

  useEffect(() => {
    if (!loginTrue && loginTrue != "") {
    } else if(loginTrue){
      console.log("i am in")
      navigate("/");
    }
  }, [loginTrue]);

  //when the user fill the details and click on submit the data will be send to api and got the response then stored in userSlice
  const registerForm = async (values) => {
    setloading(true);
    try {
      const user = await api.create(
        dispatch,
        [registerGetAllStart, registerGetAllSuccess, registerGetAllFail],
        "register",
        values,
        (err, res) => {
           console.log("register res----->", res);
          if (err) {
            // Handle API call error
            message.error(err?.response?.data?.message);            
            return;
            setloading(false);
          }

          // console.log("register res----->", res);
  
          if (res?.status === 201) {
            // Successful registration
            // console.log("register", res?.response?.data?.message);
            message.success(res?.data?.message);
            setloading(false);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("user", JSON.stringify(res.data.data));
            localStorage.setItem("login", true);
            localStorage.setItem("buyer_name", values?.buyer_number);
            localStorage.setItem("buyer_pasword", values?.buyer_password);
            setTimeout(()=>{
                          window.location.reload();
            },1000)
          } else if (res) {
            // Handle unsuccessful registration
            message.error(res.response?.data?.message || "Registration failed");
            setloading(false);
          } else {
            // Handle unexpected response (res is null or undefined)
            message.error("Unexpected response from API");
            setloading(false);
          }
        }
      );
    } catch (error) {
      message.error("Something went wrong");
      // console.error("Registration error:", error);
    }
  };
  
  

  return (
    <React.Fragment>
      <RegisterSection>
        <RegisterAlign>
          <RegisterRight>
            <Title>Registration</Title>

            <Form
              layout="vertical"
              form={form}
              name="Register_Form"
              onFinish={registerForm}
            >
              <Form.Item
                label="Name"
                name="buyer_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Enter Mobile number"
                name="buyer_number"
                rules={[
                  {
                    required: true,

                    message: "Please enter your Mobile number!",
                  },
                  {
                    pattern: new RegExp(/^(?:\+91|0)?[1-9]\d{9}$/),

                    message: "Please enter Valid Mobile number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="buyer_emailid"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter Valid Email Address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="buyer_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Password!",
                  },
                  {
                    pattern: new RegExp (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/),
                    message: 'Password must contain At least 8 characters, one uppercase letter, one lowercase letter, and one number and one special character.',
                  }
                ]}  
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="primary_btn"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            Already have an account? <Link to="/login" style={{ textDecoration: "none" }}>Login</Link>
          </RegisterRight>
        </RegisterAlign>
      </RegisterSection>
    </React.Fragment>
  );
};

export default Register;

const RegisterSection = styled.section`
  display: flex;
  width: 100%;
  /* position: relative; */
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;
    
  }
`;
const RegisterAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const RegisterRight = styled.div`
  width: 100%;
  padding: 35px 40px;
  /* border: 1px solid red; */
  border-radius: 5px;
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Resend = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 8px 0 0 0;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
