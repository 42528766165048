import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import {
  Tag,
  Button,
  Divider,
  Popconfirm,
  message,
  Empty,
  Input,
  Spin,
  Card, 
} from "antd";
import { DeleteOutlined, InfoOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Default from "../Assets/Images/Agri/b2.jpg";
import styles from "../ApiService/Data";
import "../../src/Assets/Css/style.css";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";
import { cartSuccess } from "../Redux/slices/cart";
import CartGuest from "./CartGuest";

const Cart = () => {
  const api = new API();
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user")) || "";
  const token = localStorage.getItem("token") || "";
  const CartLocal = JSON.parse(localStorage.getItem('cartLocal'));
  // console.log("CartLocal", CartLocal);
  const [cartUpdate, setCartUpdate] = useState(false);
  const [specData, setSpecData] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const login = localStorage.getItem("login") || false
  // useEffect(() => {
  //   calculateTotalAmount();
  // }, [cart]);

  // console.log("user", user);
  // console.log("token", token);
  
  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  // console.log("isDecimal==>", isDecimal);
  // console.log("user", user?._id);

  useEffect(() => {    
    // getSingleProduct();
    CartLocal?.map((item) => {
      getSingleProduct(item?.product_id, item?.product_spec, item?.qty);
    })
  }, []);

  const getSingleProduct = (Id, specId, quantity) => {
    api.getSingleProduct(Id).then((res) => {  
      console.log("res--->", res?.data?.data);
      console.log("specId--->", specId);
      const filteredSpec = res?.data?.data?.productspec?.filter((spec) => spec._id == specId);
      
     // Add qty to the filtered spec items
    const updatedSpec = filteredSpec?.map((spec) => ({
      ...spec,
      qty: quantity
    }));

      setSpecData((prevSpecData) => [
        ...prevSpecData,
        ...updatedSpec || []
      ]);                
    });
  };

  console.log("specData123", specData);

  const data4Checkout = {
    buyer_id: user?._id,
  };

  useEffect(() => {
    getAllCartss();
  }, []);
  const getAllCartss = () => {
    if (user) {
      api.getcart(data4Checkout).then((res) => {
        // console.log("res--->", res);
        if (res.response?.data?.status === 500) {
          message.error(res?.response?.data?.message);
          // navigate("/delivery-address");
          setCart({
            cart_item: [],
          });
        } else {
          setCart(res?.data?.data);
          setLoading(false);
          dispatch(cartSuccess(res?.data));
        }
      });
    }
  };
  // const getAllCart = () => {
  //   if(user){
  //     api.getcart(data4Checkout).then((res) => {
  //       if (res.status === 200) {
  //         setCart(res?.data?.data);
  //       } else {
  //         message.error(res?.response?.data?.message);
  //         setCart({
  //           cart_item: [],
  //         });
  //       }
  //     });
  //   } 
  // }

  // // console.log("cart--->", cart);
  // const discountId = cart?.cart_item?.map((item) => {
  //   return item?.product_spec?.discount_id?.discount_id?.discount_valid_to;
  // });
  // // console.log("discountId--->", discountId);
  // const formatedDiscountDate = (discountId?.map((item) => dayjs(item).format("DD-MM-YYYYhh:mm:ss")));
  // console.log("formatedDiscountDate--->", formatedDiscountDate);

  // const formatedDiscountDate1 = formatedDiscountDate?.filter((item) => item > currentDate);
  // console.log("formatedDiscountDate1--->", formatedDiscountDate1);

  const handleDelete = (id) => {
    api.deletecart(id).then((res) => {
      // console.log("delete-->", res);
      message.success("Deleted Successfully");
      getAllCartss();
    });
  };

  const handleCartUpdate = (id, action) => {
    // console.log("action", action);
    setCartUpdate(true);
    api.updatecart(id, action).then((res) => {
      // console.log("update res--->", res);
      if (res?.status == 200) {
        setCartUpdate(false);
        setCart(res?.data?.data);
        getAllCartss();
        message.success("updated Successfully");
      } else {
        setCartUpdate(false);
        setLoading(false);
        message.error("Something went wrong");
      }
    });
  };

  const handleCheckout = () => {
    api.checkout(data4Checkout).then((res) => {
      // console.log("res--->", res);
      if (res?.status == 200) {
        navigate("/checkout");
      } else {
        message.error("Something went wrong");
      }
    });
  };

  const content = (
    <div className="Info_box" style={{ padding: "10px", width: "250px" }}>
      {cart?.cart_item?.map((item, index) => {
        const filteredItem = cart?.cart_item?.find((cartItem) => {
          return cartItem?._id === item?._id;
        });

        // console.log("filteredItem--->", filteredItem);

        // Display product details once outside the map function
        return (
          <React.Fragment key={index}>
            <p className="cart_text">
              MRP :{" "}
              <span
                style={{ marginLeft: "100px", textDecoration: "line-through" }}
              >
                {styles.currency}
                {filteredItem?.mrp}
              </span>
            </p>
            <p className="cart_text">
              Special Price :{" "}
              <span style={{ marginLeft: "60px" }}>
                {styles.currency}
                {filteredItem?.sp}
              </span>
            </p>
            <p className="cart_text">
              Discount :{" "}
              <span style={{ marginLeft: "80px" }}>
                {filteredItem?.discount_percentage + "% off"}
              </span>
            </p>
            <p className="cart_text">
              Tax({filteredItem?.tax + "%"}) :{" "}
              <span style={{ marginLeft: "75px" }}>
                {styles.currency}
                {filteredItem?.tax_amount}
              </span>
            </p>
          </React.Fragment>
        );
      })}

      <p className="cart_text">
        Total Amount:{" "}
        <span style={{ marginLeft: "60px" }}>
          {styles.currency}
          {cart?.amount}
        </span>
      </p>
    </div>
  );

  useEffect(() => {
    getAllCartss();
    // if (!login) {
    //   localStorage.setItem('redirectPath', window.location.pathname);
    //   navigate("/login")
    // }
  }, []);

  // console.log('cart 177', cart)
  // console.log('cart?.cart_item?.length', cart?.cart_item?.length)
  

  return (
    <CartSection style={{ backgroundColor: "#f4f4f4" }}>
      {/* <Spin spinning={loading} indicator={<LoadingOutlined spin />} size="large"> */}
      {console.log("cart-->", cart)}
      {console.log("specData-->", specData)}
        <div className="wrapper" style={{ padding: "0px 40px 60px 40px" }}>
          <h1>Cart</h1>
          {cart?.cart_item?.length < 1 ? (
            (specData.length >= 1 && !login) ? (
              <CartGuest />
            ) : (              
              <Empty />
            )
          ) : cart == "undefined" ? (
            <Empty />
          ) : cart?.length < 1 ? (
            (specData.length >= 1 && !login) ? (
              <CartGuest />
            ) : (              
              <Empty />
            )
          ) : cartUpdate ? (
            <Spin fullScreen />
          ) : (
            <div className="cart_section">
              <Card 
              loading={loading} 
              className="cart_left" 
              style={{ backgroundColor: "white" }}
              >
                <div className="cart_list" id="style-10">
                  {cart?.cart_item?.map((item,index) => (
                    console.log("item-->", item),
                    <div className="cart_box" key={item?._id}>
                      <div className="cart_box_left">
                        <Link
                          to={`/product/${encodeURIComponent(item?.product_id?.product_name)}/` + item?.product_id?._id}
                          state={{ productSpecId: item?.product_spec?._id }}
                          className="cartbox_img"
                        >
                          <img
                            src={
                              api.rootUrl1 +
                              item?.product_spec?.product_images[0]?.image_path
                            }
                            alt="Cart"
                          />
                        </Link>
                      </div>
                      <div className="cart_box_right">
                        <Link
                          className="Produt_name"
                          to={`/product/${encodeURIComponent(item?.product_id?.product_name)}/` + item?.product_id?._id}
                          state={{ productSpecId: item?.product_spec?._id }}
                        >
                          {item?.product_id?.product_name}
                        </Link>
                        {/* {console.log("item", item)} */}
                        {item?.product_id?.common_stock ?  ((item?.product_id?.common_stock >= item?.qty) ? "" : (
                          <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                        )) : item?.product_spec?.quantity >= item?.qty ? "" : (
                          <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                        )
                        }
                        {/* {item?.product_spec?.quantity >= item?.qty ? "" : (
                          <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                        )
                        } */}

                        <h4>{item?.product_id?.common_sku ? item?.product_id?.common_sku : item?.product_spec?.sku}</h4>
                        {/* <h4>{item?.product_spec?.sku}</h4> */}
                        <div className="cart_specification">
                          {item?.product_spec?.specification?.map((spec) => (
                            <Tag color="green" key={spec?._id}>
                              {spec?.specification_details}
                            </Tag>
                          ))}
                        </div>
                        {/* {console.log("item?.product_id", item?.product_id)} */}

                       { (item?.show_mrp == true && item?.show_sp == false)  ? 
                        (
                          <div className="price">
                            <div className="mrp">
                            <span>M.R.P: </span>
                            {(item?.show_mrp == true && item?.show_sp == false) ? (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                    : ((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              ) 
                            : (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                    : ((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              )
                          }                             
                            </div>
                          </div> 
                        )
                        :
                       (item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false)  ? 
                        (
                          <div className="price">
                            <div className="mrp">
                            <span>M.R.P: </span>
                            {(item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false) ? (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item.tax / 100)))
                                    : ((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item.tax / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              ) 
                            : (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.product_spec?.mrp) + (item?.product_spec?.mrp * (item.tax / 100)))
                                    : ((item?.product_spec?.mrp) + (item?.product_spec?.mrp * (item.tax / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              )
                           }
                              {/* {styles.currency + ((item?.product_spec?.mrp) + (item?.product_spec?.mrp * ((item.tax) / 100))).toFixed(2)} */}
                            </div>
                          </div> 
                        )
                        :
                        (<div className="price">
                         
                          {/* <div className="sp">
                            {styles.currency + (Number(((item?.product_spec?.sp - ((item?.product_spec?.sp * item?.per_product_discount_amount) / 100)))) + Number(
                              (item?.product_spec?.sp - ((item?.product_spec?.sp * item?.per_product_discount_amount) / 100)) * ((item.tax) / 100)
                            )).toFixed(2)}
                          </div> */}
                          <div className="sp">
                          {styles.currency + " " + (
                            item?.discount_type === "Percentage" ? (
                              item?.product_spec?.sp ? (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.product_spec?.sp - (item?.product_spec?.sp * item?.per_product_discount_amount / 100) // Percentage discount
                                      ) +
                                      Number(
                                        (item?.product_spec?.sp - (item?.product_spec?.sp * item?.per_product_discount_amount / 100)) * (item?.tax / 100) // Tax calculation
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.product_spec?.sp - (item?.product_spec?.sp * item?.per_product_discount_amount / 100) // Percentage discount
                                      ) +
                                      Number(
                                        (item?.product_spec?.sp - (item?.product_spec?.sp * item?.per_product_discount_amount / 100)) * (item?.tax / 100) // Tax calculation
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              )
                              :
                              (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100) // Percentage discount
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100)) * (item?.tax / 100) // Tax calculation
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100) // Percentage discount
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100)) * (item?.tax / 100) // Tax calculation
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              ) 
                            ) : item?.discount_type === "Price" ? (
                              item?.product_spec?.sp ? (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.product_spec?.sp - item?.per_product_discount_amount // Flat discount (Price)
                                      ) +
                                      Number(
                                        (item?.product_spec?.sp - item?.per_product_discount_amount) * (item?.tax / 100) // Tax calculation
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.product_spec?.sp - item?.per_product_discount_amount // Flat discount (Price)
                                      ) +
                                      Number(
                                        (item?.product_spec?.sp - item?.per_product_discount_amount) * (item?.tax / 100) // Tax calculation
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              )
                              :
                              (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.product_id?.common_sp - item?.per_product_discount_amount // Flat discount (Price)
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - item?.per_product_discount_amount) * (item?.tax / 100) // Tax calculation
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.product_id?.common_sp - item?.per_product_discount_amount // Flat discount (Price)
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - item?.per_product_discount_amount) * (item?.tax / 100) // Tax calculation
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              ) 
                            ) : (
                              item?.product_id?.common_sp ? (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(item?.product_id?.common_sp) +
                                      Number(item?.product_id?.common_sp * (item?.tax / 100))
                                    )
                                  : (
                                      (Number(item?.product_id?.common_sp) +
                                      Number(item?.product_id?.common_sp * (item?.tax / 100))).toFixed(isDecimal?.Decimal_points)
                                    )
                              ) : (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(item?.product_spec?.sp) +
                                      Number(item?.product_spec?.sp * (item?.tax / 100))
                                    )
                                  : (
                                      (Number(item?.product_spec?.sp) +
                                      Number(item?.product_spec?.sp * (item?.tax / 100))).toFixed(isDecimal?.Decimal_points)
                                    )
                              )
                            )
                          )}
                          </div>

                          <div className="mrp">
                            <span>M.R.P: </span>
                          {item?.product_spec?.mrp ? (
                              styles.currency + (
                                isDecimal?.isDecimal === false
                                  ? Math.round((item?.product_spec?.mrp) + (item?.product_spec?.mrp * (item.tax / 100)))
                                  : ((item?.product_spec?.mrp) + (item?.product_spec?.mrp * (item.tax / 100))).toFixed(isDecimal?.Decimal_points)
                              )
                            )
                            :
                           (
                              styles.currency + (
                                isDecimal?.isDecimal === false
                                  ? Math.round((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item.tax / 100)))
                                  : ((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item.tax / 100))).toFixed(isDecimal?.Decimal_points)
                              )
                            )}
                            {/* {styles.currency + ((item?.product_spec?.mrp) + (item?.product_spec?.mrp * ((item.tax) / 100))).toFixed(2)} */}
                          </div>

                          {/* <div className="discount_per">
                            {item?.discount_percentage + "% off"}
                          </div> */}
                        </div>)}
                            {/* {console.log('item?.discount_amount', item?.discount_amount)} */}
                            
                        {item?.discount_amount > 0 ? (
                          item?.discount_type == "Percentage" ?
                          (
                          <div className="Tax" style={{ color: "green" }}>
                            {item?.discount_amount > 0 ? " (" + "Discount" + "  " + item?.per_product_discount_amount + "%" + " , Tax" + "  " + item?.tax + "%" + " Inclusive" + ")" : ""}
                          </div>
                          ) : (
                            <div className="Tax" style={{ color: "green" }}>
                            {item?.discount_amount > 0 ? " (" + "Discount" + "  " + `${styles?.currency}`+item?.per_product_discount_amount + " , Tax" + "  " + item?.tax + "%" + " Inclusive" + ")" : ""}
                          </div>
                          )
                        ) : (
                          <div className="Tax" style={{ color: "green" }}>
                            {item?.tax_amount > 0
                              ? "(" + "Tax " + item?.tax + "%" + " Inclusive" + ")"
                              : ""}
                          </div>
                        )}
                        <div className="cart_quantity">
                          <div className="quantity_wrapper" style={{ display: "flex", gap: "10px" }}>
                            <Button
                              onClick={() => {
                                handleCartUpdate(item?._id, "decrease")
                                setLoading(true)
                              }}
                              disabled={item?.qty === 1}
                              loading={cartUpdate}
                            >
                              -
                            </Button>
                            <Input
                              defaultValue={item?.qty}
                              value={item?.qty}
                              readOnly
                              min={1}
                              onChange={(e) => {
                                let val = parseInt(e.target.value);
                                handleCartUpdate(item?._id, val);
                              }}
                            />
                            <Button
                              onClick={() =>{
                                handleCartUpdate(item?._id, "increase")
                                setLoading(true)
                              }}
                              loading={cartUpdate}
                              // disabled={item?.product_id?.common_stock ? (item?.qty >= Number(item?.product_id?.common_stock)) : item?.qty >= item?.product_spec?.quantity}
                              // disabled={item?.qty >= item?.product_spec?.quantity}
                              disabled={
                                
                                item?.product_spec?.quantity
                                  ? item?.qty >= item?.product_spec?.quantity  
                                  : item?.product_id?.common_stock 
                                  ? item?.qty >= Number(item?.product_id?.common_stock)  
                                  : false 
                              }
                            >
                              +
                            </Button>
                          </div>

                         {(item?.show_mrp == true && item?.show_sp == false) ?
                         (
                          <div className="cart_price_total" style={{ display: "flex", marginLeft: "20px" }}>                            
                            {item?.discount_type === "Percentage" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.mrp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.mrp) -
                                          (item?.mrp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.mrp - 
                                          (item?.mrp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.mrp) -
                                            (item?.mrp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.mrp - 
                                            (item?.mrp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          (item?.sp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.sp - 
                                          (item?.sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            (item?.sp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.sp - 
                                            (item?.sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : item?.discount_type === "Price" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.mrp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.mrp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.mrp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.mrp) -
                                            item?.per_product_discount_amount 
                                          ) +
                                          Number(
                                            (item?.mrp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.sp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            item?.per_product_discount_amount 
                                          ) +
                                          Number(
                                            (item?.sp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.mrp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.mrp) +
                                          Number(item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.mrp) +
                                            Number(item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) +
                                          Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) +
                                            Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            )} 
                          </div>
                         )
                         :
                         (item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false) ?
                         (
                          <div className="cart_price_total" style={{ display: "flex", marginLeft: "20px" }}>
                            {/* <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                              Total :{" "}
                              {styles.currency +
                                "    " +
                                ((Number(((item?.product_spec?.sp - ((item?.product_spec?.sp * item?.per_product_discount_amount) / 100)))) + Number(
                                  (item?.product_spec?.sp - ((item?.product_spec?.sp * item?.per_product_discount_amount) / 100)) * ((item.tax) / 100)
                                )) * item?.qty).toFixed(2)}
                            </Tag> */}
                            {/* {console.log("item-->", item)} */}
                            {item?.discount_type === "Percentage" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_id?.common_sp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_mrp) -
                                          (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100 // Percentage discount
                                        ) +
                                        Number(
                                          (item?.product_id?.common_mrp - 
                                          (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_mrp) -
                                            (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100 // Percentage discount
                                          ) +
                                          Number(
                                            (item?.product_id?.common_mrp - 
                                            (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_spec?.sp) -
                                          (item?.product_spec?.sp * item?.per_product_discount_amount) / 100 // Percentage discount
                                        ) +
                                        Number(
                                          (item?.product_spec?.sp - 
                                          (item?.product_spec?.sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_spec?.sp) -
                                            (item?.product_spec?.sp * item?.per_product_discount_amount) / 100 // Percentage discount
                                          ) +
                                          Number(
                                            (item?.product_spec?.sp - 
                                            (item?.product_spec?.sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : item?.discount_type === "Price" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_id?.common_sp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_mrp) -
                                          item?.per_product_discount_amount // Flat discount (Price)
                                        ) +
                                        Number(
                                          (item?.product_id?.common_mrp - item?.per_product_discount_amount) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_mrp) -
                                            item?.per_product_discount_amount // Flat discount (Price)
                                          ) +
                                          Number(
                                            (item?.product_id?.common_mrp - item?.per_product_discount_amount) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_spec?.sp) -
                                          item?.per_product_discount_amount // Flat discount (Price)
                                        ) +
                                        Number(
                                          (item?.product_spec?.sp - item?.per_product_discount_amount) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_spec?.sp) -
                                            item?.per_product_discount_amount // Flat discount (Price)
                                          ) +
                                          Number(
                                            (item?.product_spec?.sp - item?.per_product_discount_amount) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_id?.common_sp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_mrp) +
                                          Number(item?.product_id?.common_mrp * (item?.tax / 100)) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_mrp) +
                                            Number(item?.product_id?.common_mrp * (item?.tax / 100)) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_spec?.sp) +
                                          Number(item?.product_spec?.sp * (item?.tax / 100)) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_spec?.sp) +
                                            Number(item?.product_spec?.sp * (item?.tax / 100)) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            )}

                            
                            {/* <div className="Tax">
                            Tax({item?.tax + "%"}) :{" "}
                            {styles?.currency + " " + item?.tax_amount}
                          </div> */}
                          </div>
                         )
                          :
                          (<div className="cart_price_total" style={{ display: "flex", marginLeft: "20px" }}>
                            {/* <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                              Total :{" "}
                              {styles.currency +
                                "    " +
                                ((Number(((item?.product_spec?.sp - ((item?.product_spec?.sp * item?.per_product_discount_amount) / 100)))) + Number(
                                  (item?.product_spec?.sp - ((item?.product_spec?.sp * item?.per_product_discount_amount) / 100)) * ((item.tax) / 100)
                                )) * item?.qty).toFixed(2)}
                            </Tag> */}
                            {/* {console.log("item-->", item)} */}
                            {item?.discount_type === "Percentage" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_spec?.sp ?  (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_spec?.sp) -
                                          (item?.product_spec?.sp * item?.per_product_discount_amount) / 100 // Percentage discount
                                        ) +
                                        Number(
                                          (item?.product_spec?.sp - 
                                          (item?.product_spec?.sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_spec?.sp) -
                                            (item?.product_spec?.sp * item?.per_product_discount_amount) / 100 // Percentage discount
                                          ) +
                                          Number(
                                            (item?.product_spec?.sp - 
                                            (item?.product_spec?.sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) 
                                :
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_sp) -
                                          (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100 // Percentage discount
                                        ) +
                                        Number(
                                          (item?.product_id?.common_sp - 
                                          (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_sp) -
                                            (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100 // Percentage discount
                                          ) +
                                          Number(
                                            (item?.product_id?.common_sp - 
                                            (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) 
                                 
                               }
                              </Tag>
                            ) : item?.discount_type === "Price" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_spec?.sp ? 
                                 (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_spec?.sp) -
                                          item?.per_product_discount_amount // Flat discount (Price)
                                        ) +
                                        Number(
                                          (item?.product_spec?.sp - item?.per_product_discount_amount) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_spec?.sp) -
                                            item?.per_product_discount_amount // Flat discount (Price)
                                          ) +
                                          Number(
                                            (item?.product_spec?.sp - item?.per_product_discount_amount) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))
                                :
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_sp) -
                                          item?.per_product_discount_amount // Flat discount (Price)
                                        ) +
                                        Number(
                                          (item?.product_id?.common_sp - item?.per_product_discount_amount) * 
                                          (item?.tax / 100) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_sp) -
                                            item?.per_product_discount_amount // Flat discount (Price)
                                          ) +
                                          Number(
                                            (item?.product_id?.common_sp - item?.per_product_discount_amount) * 
                                            (item?.tax / 100) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) 
                                
                               }
                              </Tag>
                            ) : (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_spec?.sp ? 
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_spec?.sp) +
                                          Number(item?.product_spec?.sp * (item?.tax / 100)) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_spec?.sp) +
                                            Number(item?.product_spec?.sp * (item?.tax / 100)) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))
                                :
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_sp) +
                                          Number(item?.product_id?.common_sp * (item?.tax / 100)) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_sp) +
                                            Number(item?.product_id?.common_sp * (item?.tax / 100)) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))                                  
                                }
                              </Tag>
                            )}

                            
                            {/* <div className="Tax">
                            Tax({item?.tax + "%"}) :{" "}
                            {styles?.currency + " " + item?.tax_amount}
                          </div> */}
                          </div>)
                          }
                        </div>
                        <div className="remove_cart">
                          <Popconfirm
                            title="Delete Product"
                            description="Are you sure to delete this product?"
                            onConfirm={() => handleDelete(item?._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>

              <Card loading={loading} className="cart_right" style={{ backgroundColor: "white" }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "max-content" }}>
                        Price({cart?.cart_item?.length} items x{" "}
                        {cart?.total_quantity} qty)
                      </td>
                      {cart?.delivery_charge_before_total ? (
                        <td>₹ {isDecimal?.isDecimal === false 
                          ? Math.round(Number(cart?.delivery_charge_before_total)) 
                          : Math.round(Number(cart?.delivery_charge_before_total)).toFixed(isDecimal?.Decimal_points)}
                          </td>
                      ) : (<td>{"  "}</td>)
                        }
                      {/* <td>₹ {Number(cart?.delivery_charge_before_total).toFixed(2)}</td> */}
                    </tr>
                    {/* {cart?.discount_amount ? (
                      <tr>
                        <td>Discount</td>
                        <td>₹ {Number(cart?.discount_amount).toFixed(2)}</td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>Total Tax </td>
                      <td>₹ {Number(cart?.totalTax).toFixed(2)}</td>
                    </tr> */}
                    {
                      cart?.total_delivery_charge > 0 ? (
                        <tr>
                          <td>Delivery Charges</td>
                          {cart?.total_delivery_charge ? (
                            <td>₹ {isDecimal?.isDecimal === false 
                              ? Math.round(Number(cart?.total_delivery_charge)) 
                              : Math.round(Number(cart?.total_delivery_charge)).toFixed(isDecimal?.Decimal_points)}
                            </td>
                          ) : (<td>{"  "}</td>)}
                          {/* <td>₹ {Number(cart?.total_delivery_charge).toFixed(2)}</td> */}
                        </tr>
                      ) : ""
                    }
                    <tr>
                      <td>Total Amount</td>
                      {cart?.calculated_total ? (
                        <td>₹ {isDecimal?.isDecimal === false 
                          ? Math.round(Number(cart?.calculated_total)) 
                          : Number(cart?.calculated_total).toFixed(isDecimal?.Decimal_points)}
                        </td>
                      ) : (<td>{"  "}</td>)
                      }
                      {/* <td>₹ {Number(cart?.calculated_total).toFixed(2)}</td> */}
                    </tr>
                  </tbody>
                </table>
                <Divider />
                <Button
                  type="primary"
                  danger
                  block
                  onClick={() => handleCheckout()}
                  // disabled={(cart?.cart_item?.some(item => item?.product_spec?.quantity < item?.qty))}
                  // disabled={cart?.calculated_total > 0 ? false : true}
                  disabled={
                    // Disable the button if total is 0 or if any item is out of stock
                    cart?.calculated_total >= 0? false : true || 
                    cart?.cart_item?.some(item => item?.product_spec?.quantity < item?.qty)
                  }
                >
                  Checkout
                </Button>
              </Card>
            </div>
          )}
        </div>
      {/* </Spin> */}
    </CartSection>
  );
};

export default Cart;

const CartSection = styled.section`
  width: 100%;
  /* margin: 60px 0; */
  padding: 60px 0;
  display: inline-block;

  .Tax {
    margin-top: 4px;
    color: #888;
    font-size: 13px;
  }

  .cart_price_description {
    height: 30px;
    width: 110px;
    font-size: 16px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Produt_name {
    color: black;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    width: 100%;
  }

  .Produt_name:hover {
    color: ${styles.appColor} !important;
  }

  h1 {
    font-size: 27px;
    margin: 0 0 30px;
    display: inline-block;
    width: 100%;
  }

  .cart_section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .cart_left {
    width: 65%;
    display: inline-block;
    position: relative;
    padding: 25px 20px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .info_icon {
    padding: 2px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    border-radius: 40%;
    background-color: #54a958;
    margin-bottom: 2px;
  }

  .cart_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    padding-right: 15px;
  }

  #style-10::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
    padding-left: 5px;
  }

  #style-10::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
    padding-left: 10px;
  }

  #style-10::-webkit-scrollbar-thumb {
    padding-left: 10px;
    background-color: #aaa;
    border-radius: 10px;
    /* background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent) */
  }

  .cart_box {
    display: flex;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
  .cart_box:not(:last-child) {
    margin: 0 0 27px 0;
    padding: 0 0 27px 0;
  }
  .cart_box:not(:last-child)::after {
    content: "";
    border: 0;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    /* border-style: dashed; */
  }

  .cart_box_left {
    width: 150px;
    height: 100%;
    display: inline-block;
    border: 1px solid #f5f5f5;
    margin-right: 20px;
    @media screen and (max-width: 1000px) {
      margin-right: 20px;
      width: 180px;
    }
    @media screen and (max-width: 500px) {
      margin-right: 20px;
      width: 180px;
    }
  }
  .cart_box_left .cartbox_img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .cart_box_left .cartbox_img:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  .cart_box_right {
    width: 77%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* position: relative; */
    padding: 0 155px 0 0;
    @media screen and (max-width: 1000px) {
      width: 78%;
    }
    @media screen and (max-width: 500px) {
      padding: 0 25px 0 0;
    }
  }

  .cart_box_right h2 {
    font-size: 16px;
    font-family: var(--semibold);
    @media screen and (max-width: 500px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .cart_box_right h4 {
    font-size: 13px;
    color: #888;
    font-family: var(--light);
  }
  .cart_box_right .cart_specification {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
  }
  .price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
    margin: 5px 0;
  }
  .price div:nth-child(2) {
    font-size: 12px;
    font-family: var(--light);
    color: #888;
    text-decoration: line-through;
  }
  .price div:nth-child(1) {
    font-size: 18px;
    font-family: var(--semibold);
    font-weight: 600;
  }

  .price div:nth-child(3) {
    color: #388e3c;
    font-size: 14px;
    font-weight: 600;
  }

  .cart_quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 9px;
    position: absolute;
    bottom: 0;
    right: 0;
    /* border: 1px solid red;    */
  }
  .cart_quantity button {
    padding: 0;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart_quantity button span {
    font-size: 16px;
    font-family: var(--semibold);
  }
  .cart_quantity input {
    padding: 5px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 60px;
    text-align: center;
    outline: none;
  }
  .remove_cart {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    cursor: pointer;
  }
  .remove_cart span {
    color: red;
  }
  .cart_right {
    border: 1px solid #e9e9e9;
    width: 32%;
    border-radius: 5px;
    padding: 25px 15px;
    @media screen and (max-width: 1000px) {
      width: 60%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  table {
    width: 100%;
  }
  tbody {
    width: 100%;
  }
  tbody tr {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  tbody tr:not(:last-child) {
    margin: 0 0 20px;
  }
  tbody tr td:nth-child(1) {
    font-family: var(--semibold);
    text-align: left;
    /* padding: 0 20px 20px 0px; */
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr td:nth-child(2) {
    font-family: var(--regular);
    text-align: right;
    padding: 0 0 20px 10px;
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr:last-child td:nth-child(1),
  tbody tr:last-child td:nth-child(2) {
    border-bottom: 0px;
  }

  @media screen and (max-width: 1025px) {
    .cart_quantity{
      position: relative;
      margin-top: 20px;
      flex-wrap: nowrap;
    }
    
  }

  @media screen and (max-width: 768px) {    
    .cart_right{
      width: 100%;
    }
  }

  @media screen and (max-width: 540px) {
    .cartbox_img {
      width: 50%;
    }
    .cart_box{
      flex-direction: column;
    }
    .cart_box_left{
      width: 100px;
    }
    .cart_box_right{
      padding: 0px 50px 0px 0px;
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    .cart_quantity{
    flex-direction: column;
    align-items: self-start;
    }
    .cart_price_total{
      margin-left: 0px !important;
    }       
  }

  @media screen and (max-width: 375px) {
    .cart_box_right .Produt_name{
      font-size: 14px;     
    }
    .cart_box_right{
      padding: 0px 30px 0px 0px;
    }
    .cart_box_right h4 {
      font-size: 12px;
    }
    .mrp{
      font-size: 10px !important;
    }
    .sp {
      font-size: 12px !important;
    }
    .Tax{
      font-size: 12px !important;
    }
    .cart_quantity button{
      width: 20px;
      height: 20px;
    }
    .cart_quantity input{
      width: 40px;
      padding: 0px 8px;
    }
    .cart_price_description {
      font-size: 12px;
    }    
  }

  @media screen and (max-width: 320px) {
    .cart_box_right .Produt_name{
      font-size: 14px;     
    }
    .cart_box_right{
      padding: 0px 30px 0px 0px;
    }
    .cart_box_right h4 {
      font-size: 12px;
    }
    .mrp{
      font-size: 10px !important;
    }
    .sp {
      font-size: 12px !important;
    }
    .Tax{
      font-size: 12px !important;
    }
    .cart_quantity button{
      width: 20px;
      height: 20px;
    }
    .cart_quantity input{
      width: 40px;
      padding: 0px 8px;
    }
    .cart_price_description {
      font-size: 12px;
    }    
  }

`;
