import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { Button, Rate, message, Modal, Input } from "antd";
import pro1 from "../../Assets/Images/Poornima/new1.png";
import pro2 from "../../Assets/Images/Poornima/new2.png";
import pro3 from "../../Assets/Images/Poornima/new3.png";
import pro4 from "../../Assets/Images/Poornima/new3.png";
import pro5 from "../../Assets/Images/Poornima/ArrowCircle.png";
import pro6 from "../../Assets/Images/Poornima/LeftArrow.png";
import pro7 from "../../Assets/Images/Poornima/Right Arrow.png";
import styles from "../../ApiService/Data";
import API from "../../ApiService/ApiService";
import { ArrowRightOutlined } from "@ant-design/icons";
let user = "";

const GirlsEthinicWear1 = ({ womens_wear, content_all }) => {
  // console.log("womens_wear", womens_wear);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getProductsPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1150) {
      return 3;
    } else if (screenWidth >= 992) {
      return 3;
    } else if (screenWidth >= 768) {
      return 3;
    } else if (screenWidth >= 540) {
      return 2;
    } else if (screenWidth < 540) {
      return 1;
    }
  };
  
  const getUser = localStorage.getItem("user");

  const api = new API();

  const navigate = useNavigate();

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const New_Products = [];

  if (content_all && content_all?.poornima_ethinicwear_pro) {
    content_all?.poornima_ethinicwear_pro.forEach((item) => {
      // console.log("item", item?.product_id[0]?._id);
      if (
        !New_Products.some(
          (newItem) => newItem?.value === item?.value
        )
      ) {
        New_Products.push(item);
      }
    });
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(
        prevIndex + getProductsPerPage(),
        content_all?.poornima_ethinicwear_pro.length - getProductsPerPage()
      )
    );
  };

  // Function to handle click event of the left arrow button
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(0, prevIndex - getProductsPerPage()));
  };

    const FavouritiesProduct = [
        {
          image: pro1,
          title: "Standard Lunch Bags",
          MRP: "2,499",
          SP: "1,799",
          rating: "4",
          ratingCount: "3.4",
          //   offer: "30% oFF",
        },
        {
          image: pro2,
          title: "Standard Lunch Bags",
          MRP: "2,499",
          SP: "1,799",
          rating: "4",
          //   ratingCount: "4.5",
        },
        {
          image: pro3,
          title: "Standard Lunch Bags",
          MRP: "2,499",
          SP: "1,799",
          rating: "4",
          //   ratingCount: "4.4",
        },
        // {
        //   image: pro4,
        //   title: "Standard Lunch Bags",
        //   MRP: "2,499",
        //   SP: "1,799",
        //   rating: "4",
        //   //   ratingCount: "3",
        // },
      ];
  return (
    <FavouritesAlign>
    <div className="home_wrapper">
      <div className="Top_Section">
        <div className="Left">
          {/* GIRLS ETHINIC WEAR */}
          {content_all?.poornima_ethinicwear_title}
          </div>
        <div className="Right">
          <div className="ArrowLeft" onClick={handlePrev} style={{ cursor: "pointer", position: "relative", height: "40px", width: "40px" }}>
            <img src={pro5} alt="" />
            <img src={pro6} alt="" style={{ position: "absolute", left: "11px", top: "16px", zIndex: "1" }} />
          </div>
          <div className="ArrowRight" onClick={handleNext} 
           style={{ 
            cursor: "pointer", 
            position: "relative", 
            height: "40px", 
            width: "40px",
            visibility:
            currentIndex + getProductsPerPage() >=
            content_all?.poornima_ethinicwear_pro?.length
              ? "hidden"
              : "visible", 
            }}
          >
          <img src={pro5} alt="" />
            <img src={pro7} alt="" style={{ position: "absolute", right: "11px", top: "16px", zIndex: "1" }} />
          </div>
          {/* <Button className="Button" onClick={() => navigate(`/products`)}>
            View all{" "}
            <svg
            className="svg"
            width="25"
            height="8"
            viewBox="0 0 33 8"
            fill="#000"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32.3536 4.35355C32.5488 4.15829 32.5488 3.84171 32.3536 3.64645L29.1716 0.464466C28.9763 0.269204 28.6597 0.269204 28.4645 0.464466C28.2692 0.659728 28.2692 0.976311 28.4645 1.17157L31.2929 4L28.4645 6.82843C28.2692 7.02369 28.2692 7.34027 28.4645 7.53553C28.6597 7.7308 28.9763 7.7308 29.1716 7.53553L32.3536 4.35355ZM0 4.5H32V3.5H0V4.5Z"
              fill="black"
            />
          </svg>
          </Button> */}
        </div>
      </div>  
      <div className="Fav_Product_Main">
        {content_all?.poornima_ethinicwear_pro?.slice(currentIndex, currentIndex + getProductsPerPage())?.map((item, index) => (
          // console.log("item", item),
          <>
            <Link to={`/product/${encodeURIComponent(item?.label)}/${item?.value}`} key={index} className="Fav_Product_Link" style={{ textDecoration: "none" }}>
              <div className="Fav_Product_Box" key={index}>
                <div className="Fav_Product_Image">
                  <img src={api?.rootUrl1 + item?.image} alt="" />
                </div>
                <div className="Fav_Product_Title" >
                  <span>{item?.label}</span>
                </div>
                <div className="Prices" style={{ padding: "5px 10px 0px 10px" }}>
                  {/* <span className="Sp">{styles?.currency + item?.sp}</span>
                  <span className="Mrp">{styles?.currency + item?.mrp}</span> */}
                   <Link style={{textDecoration: "none", color:"#000"}} to={`/product/${encodeURIComponent(item?.label)}/${item?.value}`}><span >View Details <ArrowRightOutlined /> </span></Link>
                </div>
                <div className="Fav_Product_Rating" style={{ padding: "5px 10px 20px 10px" }}>
                  {/* <Button
                    className="Button"
                    style={{ marginTop: "10px", fontWeight: "600" }}
                    onClick={() =>
                      (window.location.href = `/${banner?.button_link}`)
                    }
                  >
                    Add to Cart
                  </Button> */}
                </div>
                {item?.offer && (
                  <div className="Top_offer">
                    <span className="Offer_Badge">{item?.offer}</span>
                  </div>
                )}
              </div>
            </Link>
          </>
        ))}
      </div>
    </div>
  </FavouritesAlign>
  )
}

export default GirlsEthinicWear1

const FavouritesAlign = styled.div`
  .home_wrapper {
    padding: 60px 60px 60px 60px;
  }

  .Top_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
  }

  .Top_Section .Left {
    color: #000;
    font-family: Moneta;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .Top_Section .Right .Button {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 17.6px */
    text-transform: uppercase;
    border: none;
    outline: none;
  }

  .Top_Section .Right .svg {
    margin-left: 5px;
    color: #000;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .Fav_Product_Main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  .Fav_Product_Main .Fav_Product_Box {
    line-height: 30px;
    background-color: #f6f6f6;
    height: 100%;
  }

  .Fav_Product_Image { 
    height: 65%;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0 auto;
    }
  }

  .Fav_Product_Main .Fav_Product_Title {
    color: #000;
    font-family: Moneta;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-top: 10px;
  }
  @media screen and (max-width: 1440px) {
    .Fav_Product_Title {
      padding: 5px 10px 0px 10px  ;
      height: 80px;
      overflow: hidden;
    }
  }

@media screen and (max-width: 1024px) {
.Fav_Product_Title {
  padding: 5px 10px 0px 10px  ;
  height: 80px;
  overflow: hidden;
}
}

@media screen and (max-width: 768px) {
.Fav_Product_Title {
  padding: 5px 10px 0px 10px  ;
     height:80px ;
    overflow:hidden;
  }
}



@media screen and (max-width: 425px) {
.Fav_Product_Title {
  padding: 5px 10px 0px 10px ;
  height: auto;
}
}
@media screen and (max-width: 375px) {
.Fav_Product_Title {
  padding: 5px 10px 0px 10px ;
  height: auto;
}
}
@media screen and (max-width: 320px) {
.Fav_Product_Title {
  padding: 5px 10px 0px 10px ;
  height: auto;
}
}
  .Fav_Product_Main .Prices .Sp {
    color: #000;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    padding: 0 10px 0 0;
  }

  .Fav_Product_Main .Prices .Mrp {
    color: #9c9c9c;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 12.154px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-decoration-line: line-through;
  }

  .ant-rate {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000;
    /* color: #fadb14; */
    font-size: 15px;
    line-height: unset;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    display: inline-block;
    outline: none;
  }

  .Top_offer {
    width: 91.301px;
    /* height: 36.255px; */
    flex-shrink: 0;
    border-radius: 24px;
    background: #fff;
    position: relative;
    top: -460px;
    left: 185px;
    display: grid;
    place-items: center;
    height: 30px;
  }

  .Top_offer .Offer_Badge {
    color: #2f4076;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
  }

  .Fav_Product_Rating .Button {
    display: inline-flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #88ab8e;
    color: #fff;
    border: none;
    outline: none;
  }

  .Fav_Product_Rating .Button:hover {
    background: #fff;
    color: #000;
  }

  .Right .Button {
    display: inline-flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #fff;
    color: #000;
    border: 1px solid black;
    outline: black;
  }

  .Right{
      display: flex;
      gap: 10px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 1024px;
    .Fav_Product_Main {
      grid-template-columns: repeat(3, 1fr);
    }    
  }

  @media screen and (max-width: 992px) {
    max-width: 992px;
    .Fav_Product_Main {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 912px) {
    max-width: 912px;
    .Fav_Product_Main {
      grid-template-columns: repeat(2, 1fr);
    }        
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    .Fav_Product_Main {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 540px) {
    max-width: 540px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }    
  }

  @media screen and (max-width: 480px) {
    max-width: 480px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 30px !important;
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 425px) {
    max-width: 425px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }   
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 30px !important;
      margin-bottom: 10px !important;
    } 
  }

  @media screen and (max-width: 414px) {
    max-width: 414px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }   
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 30px !important;
      margin-bottom: 10px !important;
    } 
  }

  @media screen and (max-width: 412px) {
    max-width: 412px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    } 
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 30px !important;
      margin-bottom: 10px !important;
    }   
  }

  @media screen and (max-width: 390px) {
    max-width: 390px;
    .Fav_Product_Main {      
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 30px !important;
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 375px) {
    max-width: 375px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 25px !important;
      margin-bottom: 10px !important;
    }

  @media screen and (max-width: 360px) {
    max-width: 360px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 25px !important;
      margin-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 320px) {
    max-width: 320px;
    .Fav_Product_Main {
      grid-template-columns: repeat(1, 1fr);
    }
    .Top_Section{
      flex-direction: column;
    }
    .Left {
      font-size: 25px !important;
      margin-bottom: 10px !important;
    }
  }
`;