import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import API from "../ApiService/ApiService";
import { styles } from "../ApiService/Data";

import { message, Form, Input, Select, Button } from "antd";

import SideBar from "./SideBar";

import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;

// const AddAddress = () => {
//   const api = new API();
//   const navigate = useNavigate();
//   const [form] = Form.useForm();
//   const dispatch = useDispatch();


//   const addAddressForm = async (values) => {
//     const response = await api.createCommon("buyerbillingdetails", values);
//     if (response?.data?.success) {
//       form.resetFields();
//       message.success("Successfully Added");
//       navigate("/delivery-address");
//     } else {
//       message.error(response?.response?.data?.message);
//     }
//   };
const AddAddress = () => {
  const api = new API();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  // Track the loading state for the button
  const [loading, setLoading] = useState(false);

  const addAddressForm = async (values) => {
    // Prevent multiple clicks by setting loading to true
    setLoading(true);

    try {
      const response = await api.createCommon("buyerbillingdetails", values);

      if (response?.data?.success) {
        form.resetFields();
        message.success("Successfully Added");
        navigate("/delivery-address");
      } else {
        message.error(response?.response?.data?.message);
      }
    } catch (error) {
      // Handle error case if needed
      message.error("Something went wrong. Please try again.");
    } finally {
      // Reset loading state after API call completes
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Section>
        <Wrapper>
          <Title>Add Address</Title>
          <Align>
            <div>
              <SideBar />
            </div>
            <Right>
              <AddAddressSection>
                <Form
                  name="basic"
                  form={form}
                  onFinish={addAddressForm}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label="Name"
                    name="buyer_name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email Id"
                    name="buyer_emailid"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Email Id",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="buyer_delivery_contact_number"
                    rules={[
                      {
                        required: true,
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter valid Mobile Number",
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    label="Alternate Mobile Number"
                    name="buyer_delivery_alternate_number"
                    rules={[
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter valid Alternate Mobile Number",
                      },                      
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item
                    label="Address 1"
                    name="buyer_address_line_1"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address line 1",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Address 2"
                    name="buyer_address_line_2"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address line 2",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Pincode"
                    name="buyer_pincode"
                    rules={[
                      {
                        required:true,
                        pattern: /^[1-9]\d{2}\s?\d{3}$/,
                        message:"Please Enter Valid Pincode"
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                    <Input type="number" />
                  </Form.Item>
                  <Form.Item label="Landmark" name="landmark">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Country" name="buyer_country">
                      <Input defaultValue="India" readOnly />
                  </Form.Item>
                  <Form.Item
                    label="State"
                    name="buyer_state"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your state",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item
                    label="District"
                    name="buyer_district"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your District",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                <Input/>
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="buyer_city"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your city",
                      },
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                      },
                    ]}
                  >
                <Input/>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" danger htmlType="submit">
                      Save Address
                    </Button>
                  </Form.Item>
                </Form>
              </AddAddressSection>
            </Right>
          </Align>
        </Wrapper>
      </Section>
    </React.Fragment>
  );
};

export default AddAddress;

const Section = styled.section`
  margin: 40px 0 60px 0;
  width: 100%;
  position: relative;
  display: inline-block;
  /* border: 1px solid red; */
  margin-top: 100px;
`;
const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 0 0 20px 0;
`;
const Wrapper = styled.div`
  max-width: 1200px;
  padding: 0 10px;
  margin: auto;
`;
const Align = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  /* flex-wrap: wrap; */

  @media screen and (min-width: 885px) and (max-width: 1024px) {
    display: flex !important;    
  }

  @media screen and (min-width: 768px) and (max-width: 884px) {
    display: flex !important;    
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    flex-wrap: wrap !important; 
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    flex-wrap: wrap !important;     
  }

  @media screen and (min-width: 375px) and (max-width: 424px) {
    flex-wrap: wrap !important; 
  }

  @media screen and (min-width: 320px) and (max-width: 374px) {
    flex-wrap: wrap !important; 
  }
`;
const Left = styled.div`
  width: 25%;
  display: inline-block;
  border: 1px solid ${styles.light};
  padding: 24px;
`;
const Right = styled.div`
  width: 72%;
  padding: 24px;
  display: inline-block;
  border: 1px solid ${styles.light};

  @media screen and (min-width: 885px) and (max-width: 1024px) {
    width: 65%;   
  }

  @media screen and (min-width: 768px) and (max-width: 884px) {
    width: 55%;
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    width: 100%;
  }

  @media screen and (min-width: 375px) and (max-width: 424px) {
    width: 100%;
  }

  @media screen and (min-width: 320px) and (max-width: 374px) {
    width: 100%;
  }
`;

const AddAddressSection = styled.div`
  width: 100%;
  /*display: inline-block;
  position: relative;

  .ant-form-item:nth-child(odd) {
    width: 48%;
    display: flex;
    flex-direction: column;
    float: left;
  }
  .ant-form-item:nth-child(even) {
    width: 48%;
    display: flex;
    flex-direction: column;
    float: right;
  }
  .ant-form-item-label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }
  .ant-row {
    flex-direction: column;
  } */
`;
