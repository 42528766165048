import React from 'react'
import styled from "styled-components";
import {Button} from "antd";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import SwiperCore, { Navigation, Pagination } from 'swiper';
import API from "../../ApiService/ApiService";
import BannerImage from "../../Assets/Images/OneWear/OneWearOfferBannerSingle.jpg"
import '../../Assets/Fonts/fonts.css';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination]);

const OfferBanner3 = ({content_all}) => {
    // console.log("content_allOffer_Banner_Main--->", content_all);

    const offerBannerArray = [content_all?.onewearofferbanner];

  const api = new API();

  const navigate = useNavigate();

    const contentStyle = {
        // height: '80vh',
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      };
      
      const overlayStyle = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: '#fff',
        width: '50%',
      };
      
      const slideStyle = {
          position: 'relative',
        };

  return (
    <OfferbannerALign>
    <div className="home_wrapper">
        <Swiper
            slidesPerView={1} // Show 1 item on mobile and tablet
            navigation={{ nextEl: '.custom-next', prevEl: '.custom-prev' }} // Custom navigation
            autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }} // Enable autoplay      
          >
            {content_all?.onewearofferbanner?.map((item, index) =>(
                <SwiperSlide key={index}>
                    <div 
                    className="Offer_Banner_Main" 
                    key={index}
                    >
                        <img src={api?.rootUrl1 + item?.onewear_offer_banner?.image} style={contentStyle} className='img-fluid' />                       
                    <div className="overlay" style={overlayStyle}>
                        <h2>{item?.onewear_offer_banner_title}</h2>
                        <p style={{fontSize: '16px', fontFamily: 'Inter'}}>{item?.onewear_offer_banner_description}</p>   
                        <Link to={`/${item?.onewear_offer_banner_btn_link}`} style={{textDecoration: "none"}}>        
                        <Button 
                        className="custom-button" 
                        ghost 
                        style={{
                            borderRadius: 0, 
                            fontWeight: 'bold', 
                            fontFamily: 'Montserrat', 
                            fontWeight: '400'
                            }}
                        >
                            {item?.onewear_offer_banner_btn_txt}
                        </Button>
                        </Link>
                    </div>
                    
                    </div>
                </SwiperSlide>
            ))}
       </Swiper> 
    </div>
  </OfferbannerALign>
  )
}

export default OfferBanner3;

const OfferbannerALign = styled.div`
    padding-bottom: 80px;
 .home_wrapper {
   width: 100%;
   padding: 0px 0px;
 }

 .Offer_Banner_Main {
   width: 100%;  
   height: 100%;  ;
   background-color: #676F6B;
   
   img{
     width: 100% !important;
     height: auto !important;
     object-fit: contain !important;
     aspect-ratio: 3 / 1 !important;
     /* aspect-ratio: 2 / 1 !important; */
   }
 }  

 .overlay {
    padding-left: 20px;
    /* padding-right: 20px; */
    padding-top: 20px;
    padding-bottom: 20px;
    h2{
        font-size: 75px !important;
        font-family: Inter;
        font-weight: 700;
    }    
}

    .custom-button:hover {
  background-color: #ffffff !important;
  color: #C23B2C !important;
  border: 1px solid #ffffff !important;
}

.img-fluid {
    transition: all 1s ease-in-out;
}

.img-fluid:hover {
    transform: scale(1.05);
}

 @media screen and (min-width: 992px) and (max-width: 1199px) {
    .overlay {
            padding-left: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            
            h2 {
                font-size: 2.5rem !important;
            }

            p {
                font-size: 1.25rem;
            }
        }

        .custom-button {
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
        }
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    .overlay {
            padding-left: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            
            h2 {
                font-size: 2.5rem !important;
            }

            p {
                font-size: 1.25rem;
            }
        }

        .custom-button {
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
        }
  }

  @media screen and (min-width: 820px) and (max-width: 911px) {
    .overlay {
            padding-left: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            
            h2 {
                font-size: 2.5rem !important;
            }

            p {
                font-size: 1.25rem;
            }
        }

        .custom-button {
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
        }
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {
    .overlay {
            padding-left: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            
            h2 {
                font-size: 2rem !important;
            }

            p {
                font-size: 1rem;
            }
        }

        .custom-button {
            font-size: 0.8rem;
            padding: 0.3rem 0.6rem;
        }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {     
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {   

    .overlay {
      .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: 0.2rem 0.4rem;
        }
  }
}

  @media screen and (min-width: 414px) and (max-width: 425px) {  
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;            
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {    
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.4rem !important;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: -1rem 0.4rem;
        }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {   

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: -1rem 0.4rem;
        }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: -1rem 0.4rem;
        }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {    
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1rem !important;
            }

            p {
                font-size: 0.5rem !important;
            }
        }

        .custom-button {
            font-size: 0.4rem;
            padding: -1rem 0.4rem;
        }
  }

`;