import { createSlice } from "@reduxjs/toolkit";

const allproduct = createSlice({
  name: "allproduct",
  initialState: {
    allproduct: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    allproductGetAllStart: (state) => {
      state.isFetching = true;
    },
    allproductGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.allproduct = action.allproduct;
    },
    allproductGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
    allproductGetAllStart,
    allproductGetAllSuccess,
    allproductGetAllFail,
} = allproduct?.actions;
export default allproduct.reducer;
