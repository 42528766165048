import React, {useState, useEffect} from "react";
import {Card, Typography, Row, Col} from "antd";
import styled from "styled-components";
import {styles} from "../../../ApiService/Data";
import banner from "../../../Assets/Images/banner.webp";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import crud_service from "../../../ApiService/CrudService";
import PageUnderConstruction from "../../Pages/PageUnderConstruction";
import company_code from '../../../config';

const {Title, Paragraph, Text} = Typography;

const Ab1 = ({AboutUs}) => {
  // console.log("AboutUs==>", AboutUs);

  const company = useSelector((state) => state?.company?.company?.data[0]);
  // console.log("company", company?.company_code);

  const [getpage, setGetpage] = useState([]);

  //api service
  const crud = new crud_service();
  const navigate = useNavigate();
  // const AboutUs = useSelector((state) => state?.aboutus?.aboutus);
  // console.log("AboutUs", AboutUs?.data);
  
  const activeAboutusFortheCompayCode = AboutUs?.data?.find((x) => x?.company_code == company?.company_code && x?.status == true);
  const htmlContent = activeAboutusFortheCompayCode?.description;
  // const htmlContent = AboutUs?.data[0]?.description;

  // console.log("activeAboutusFortheCompayCode", activeAboutusFortheCompayCode);

  return (
    <MainSection>
      {AboutUs?.data.length ? <div className="about_container">
        <Row className="about_detail">
          <Col span={24} className="about_image_box">
            <img src={process.env.REACT_APP_BASE + activeAboutusFortheCompayCode?.image} width="100%" />
            {/* <img src={process.env.REACT_APP_BASE + AboutUs?.data[0].image} width="100%" /> */}
          </Col>
          <Col xs={24} lg={24} sm={24} xxl={24} className="our_mission">
            <h4 style={{textAlign: "center"}}>{activeAboutusFortheCompayCode?.title}</h4>
            {/* <h4 style={{textAlign: "center"}}>{AboutUs?.data[0].title}</h4> */}
            <div className="about_content" dangerouslySetInnerHTML={{__html: htmlContent}} />
          </Col>
          {/* <Col xs={24} lg={12} sm={24} xxl={12} className='our_mission'>
            <h4>Our Mission</h4>
            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh.</p>
          </Col> */}
        </Row>
      </div> : <PageUnderConstruction />}
    </MainSection>
  );
};

export default Ab1;

const MainSection = styled.section`
/* margin: ${({ company_code }) => (company_code === '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg' ? '60px 0' : '0px 0')}; */
margin-top: 60px;
width: 100%;
  .about_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */

    .about_detail{
      max-width: 1250px;
      line-height: 21px;
      color: black;
    }

    .our_mission{
      width: 100%;
      /* margin: 10px 0px; */
      margin: 50px 0px;
      /* border: 1px solid red; */
    }
  }

  .about_image_box {
      width: 100%;
      /* height: 350px; */
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f5f5;

      img {
        width: 100%;
        /* height: 100%; */
        height: auto;
        aspect-ratio: 3 / 1;
        object-fit: contain;
      }

    }

  @media only screen and (min-width: 320px) and (max-width: 1024px) {
    .about_content {
      /* border: 1px solid red; */
      margin: 0 auto !important;
      width: 90%;
      /* margin-top: 50px !important; */
      word-wrap: break-word !important;
    }
  }
`;
