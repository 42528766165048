import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Button, Modal, Input, Select, Form, message, Row, Col } from "antd";
import API from "../ApiService/ApiService";

import {
  updateAddressStart,
  updateAddressSuccess,
  updateAddressFail,
} from "../Redux/slices/updateAddress";

const { Option } = Select;

const api = new API();

const UpdateAddress = ({ getUserAddress, address, showModal, setShowModal }) => {

  const id = address?._id;

  const formRef = React.createRef();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  // console.log("cities", cities);


  // console.log("address", address);

  useEffect(() => {
    if (address) {
      form.setFieldsValue({
          ...address
      });
  }
  }, [address]);


  const handleUpdate = async (values) => {
    setShowModal(false);

    const res = await api.update(
      dispatch,
      [updateAddressStart, updateAddressSuccess, updateAddressFail],
      "buyerbillingdetails",
      id,
      values
    );
    if (res?.data?.success) {
      message.success(`update successfully`);
    } else {
      message.warning("Check your Data");
    }
    getUserAddress();
  };

  const customFormItemStyle = {
    marginBottom: "8px", // Adjust the margin as per your requirement
  };

  return (
    <ModalWrapper>
      <Modal
        title="Update Address"
        centered
        open={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
        width={900}
        footer={[
          <Button
            type="primary"
            form="myForm"
            key="submit"
            htmlType="submit"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="myForm"
          name="basic"
          form={form}
          onFinish={handleUpdate}
          autoComplete="off"
          ref={formRef}
          layout="vertical"
          className="custom-form"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="buyer_name"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your Name!",
                  },                  
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email Id"
                name="buyer_emailid"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your Email Id!",
                  },                 
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Mobile Number"
                name="buyer_delivery_contact_number"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: "Phone number must be 10 digits.",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Alternate Mobile Number"
                name="buyer_delivery_alternate_number"
                style={customFormItemStyle}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Address 1"
                name="buyer_address_line_1"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Address 2"
                name="buyer_address_line_2"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please input your address",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Pincode"
                name="buyer_pincode"
                style={customFormItemStyle}
                rules={[
                  {
                    pattern: /^\d{6}$/,
                    message: "Pincode must be a 6-digit number.",
                  },
                  {
                    required: true,
                    message: "Please input your pincode",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Landmark"
                name="landmark"
                style={customFormItemStyle}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="buyer_country"
                style={customFormItemStyle}
              >
                <Input defaultValue="India" readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="State"
                name="buyer_state"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please enter your State",
                  },
                ]}
              >
                <Input/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="District"
                name="buyer_district"
                rules={[
                  {
                    required: true,
                    message: "Please enter your district",
                  },
                ]}
              >
               <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="City"
                name="buyer_city"
                style={customFormItemStyle}
                rules={[
                  {
                    required: true,
                    message: "Please enter your city",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </ModalWrapper>
  );
};

export default UpdateAddress;

const ModalWrapper = styled.div`
  .custom-form .ant-form-item {
    margin-bottom: 0px;
  }
`;
