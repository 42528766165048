import React, { useRef, useState, useEffect } from 'react'
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { Button } from "antd";
import Marquee from "react-fast-marquee";
import API from "../../ApiService/ApiService";
import { Navigate, useNavigate } from "react-router-dom";
import Banner10 from "../Banner/Banner10";
import TC10 from "../TopCategory/TC10" ;
import OurFavourites from '../Our Favourites/OurFavourites';
import HCGirlsHub from '../HomeTwoBanner/HCGirlsHub';
import NewArrival2 from '../NewArrivals/NewArrival2';
import NewArrival3 from '../NewArrivals/NewArrival3';
import OfferBanner2 from '../OfferBanner/OfferBanner2';
import TrendingOfMonth1 from '../Trending of Month/TrendingOfMonth1';
import WhyUsSection4 from '../WhyUS/WhyUsSection4';
import WhyUsSection5 from '../WhyUS/WhyUsSection5';
import HCGirlsHub2 from '../HomeTwoBanner/HCGirlsHub2';
import FP10 from '../FeatureProduct/FP10';
import FP12 from '../FeatureProduct/FP12';
import Instagram1 from '../Instagram/Instagram1';
import EmptyDiv from './EmptyDiv';
import Header10 from '../Header/Header10';
import Footer10 from '../Footer/Footer10';
import Header7 from "../Header/Header7";
import Header8 from "../Header/Header8";
import Header9 from "../Header/Header9";
import Header11 from "../Header/Header11";
import Footer7 from "../Footer/Footer7";
import Footer9 from "../Footer/Footer9";
import Footer8 from "../Footer/Footer8";
import Footer11 from "../Footer/Footer11";
import Banner7 from "../Banner/Banner7";
import Banner9 from "../Banner/Banner9";
import Banner8 from "../Banner/Banner8";
import Banner12 from "../Banner/Banner12";
import BA11 from '../Banner/Banner13';
import TC1 from "../TopCategory/TC1";
import TC2 from "../TopCategory/TC2";
import TC3 from "../TopCategory/TC3";
import TC4 from "../TopCategory/TC4";
import TC5 from "../TopCategory/TC5";
import TC6 from "../TopCategory/TC6";
import TC7 from "../TopCategory/TC7";
import TC12 from "../TopCategory/TC12";
import NewArrival1 from "../NewArrivals/NewArrival1";
import OfferbannerSection from "../OfferBanner/OfferbannerSection";
import WhyUsSection from "../WhyUS/WhyUsSection";
import WhyUsSection2 from "../WhyUS/WhyUsSection2";
import WhyUsSection3 from "../WhyUS/WhyUsSection3";
import FP1 from '../FeatureProduct/FP1';
import FP2 from '../FeatureProduct/FP2';
import FP3 from '../FeatureProduct/FP3';
import FP4 from '../FeatureProduct/FP4';
import FP5 from '../FeatureProduct/FP5';
import FP6 from '../FeatureProduct/FP6';
import HomeProduct1 from "../HomeProductSection/HomeProduct1";
import HomeProduct2 from "../HomeProductSection/HomeProduct2";
import AboutUsClient from "../AboutUs(Client Say)/AboutUsClient";
import AboutUsFurniture from "../AboutUs(Client Say)/AboutUsFurniture";
import QuestionBanner from "../QuestionBanner/QuestionBanner";
import BestSeller1 from "../BestSeller/BestSeller1";
import BestSeller2 from "../BestSeller/BestSeller2";
import HCFurniture from "../Hc2/HCFurniture";
import BannerFurniture from "./BannerFurniture.jsx/BannerFurniture";
import ClientReview1 from "../ClientReviews/ClientReview1";
import ClientReview2 from "../ClientReviews/ClientReview2";
import ClientReview3 from "../ClientReviews/ClientReview3";
import HomeContact1 from "../HomeContacts/HomeContact1";
import Features1 from '../Features/Features1';
import Features2 from '../Features/Features2';
import Sales from "../Pages/Sales";
import AvailablePlatform from "../Pages/AvailablePlatform";
import FAQ1 from '../FAQ/FAQ1';
import CustomizableCollection from "../Pages/CustomizableCollection";
import ProductsAndService1 from "../Products & Services/ProductsAndService1";
import GiftSection7 from "../GIftSection/GiftSection7";
import GiftStoriesSection from "../GiftStories/GiftStoriesSection";
import GiftProductSection from "../GiftProducts/GiftProductSection";
import Brand1 from '../Brand/Brand1';
import Marquee1 from "../Marquee/Marquee";
import OfferBanner3 from '../OfferBanner/OfferBanner3';
import AboutUsFashion from '../Pages/AboutUs/Ab6';
import FollowUs from '../FollowUs/FollowUs';
import OurServices from '../OurServices/OurServices';
import OurStory from '../OurStory/OurStory';
import TC11 from "../TopCategory/TC11";
import NA3 from "../NewArrivals/NewArrival4";
import WhyUsSection6 from "../WhyUS/WhyUsSection6";
import WomenWear1 from '../WomenWear/WomenWear1';
import PoornimaFourBanner from "../HomeTwoBanner/PoornimaFourBanner";
import Baskets from "../Baskets/Baskets";
import GirlsEthinicWear1 from '../GirlsEthinicWear/GirlsEthinicWear1';
import ContactPoornima from '../Pages/ContactUs/ContactPoornima';

import { useSelector } from "react-redux"


const TestDemoSwitchMain = ({section_type}) => {

  // const content = localStorage.getItem("content");
  // const contentAll = JSON.parse(content) || [];  

    // console.log("homecustom==>", homecustom)   
    const contentAll = useSelector((state) => state?.templateOrderArraySlice?.templateorder?.company[0]);
    // const contentAll = useSelector((state) => state?.templateOrderArraySlice?.templateorder?.data[state?.templateOrderArraySlice?.templateorder?.data?.length - 1]);
    const homecustom = useSelector((state) => state?.homeCustomSlice?.homecustom?.data[0]?.theme_4);
    const banner = useSelector((state) => state?.banner?.banner?.data);
    const category = useSelector((state) => state?.category?.category?.data);
    // const product = useSelector((state) => state?.product?.product?.data)
    const product = useSelector((state) => state?.product?.product?.data[0].data);
    const socialmedia = useSelector(
      (state) => state?.socialMedia?.socialmedia?.data
    );
    // console.log("contentAll==>", contentAll)
    const company = useSelector((state) => state?.company?.company?.data[0]);
    const About_us = useSelector((state) => state?.aboutus?.aboutus);
    const review = useSelector((state) => state?.review?.review);
    const contact = useSelector((state) => state?.contact?.contact);
    const best_selling = useSelector((state) => state?.best_selling?.best_selling);
    const touchwith_us = useSelector((state) => state?.touchwith_us?.touchwith_us);
    const brand = useSelector((state) => state?.brand?.brand?.data);

    const removeUniqueIdentifiers = (obj) => {
      const newObj = {};
      for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
              const newKey = key.split('-')[0];
              newObj[newKey] = obj[key];
          }
      }
      return newObj;
  };
  
  const newContent = removeUniqueIdentifiers(contentAll?.content);
  // console.log("newContent==>", newContent);

    const renderCounter = useRef({});

    if (!newContent) {
        return null; // Or some placeholder content
    }

    const getContentForSection = (type) => {
        if (!newContent) return null;
        const contentKeys = Object.keys(newContent);
        return contentKeys
            .filter(key => newContent[key].cardType === type)
            .map(key => newContent[key]);
    };

    const content = getContentForSection(section_type);

    if (!content || content.length === 0) {
        return null;
    }

    // Initialize counter for section_type if not already initialized
    if (!renderCounter.current[section_type]) {
        renderCounter.current[section_type] = 0;
    } else {
        renderCounter.current[section_type] = renderCounter.current[section_type] + 1;
    }

    // Get the current index for rendering
    const currentIndex = renderCounter.current[section_type];    

    // Increment the counter for the next render
    renderCounter.current[section_type] += 1;

    

    // Select the content item based on the current index
    const contentItem = content[currentIndex];

    if (!contentItem) {
        return null;
    }


    // console.log("section_type==>", section_type)
    // console.log("contentItem==>", contentItem)
    
  switch (section_type) {    
    // case "HeaderWith2layersandSearchBar":   //smile
    //   return <Header7  home_custom={homecustom} />;
    // case "HeaderWithAdMarquee":   //gowri
    //   return <Header8  home_custom={homecustom}   />;
    // case "HeaderWith3layersandSearchBar":  //dyken
    //   return <Header9  home_custom={homecustom}   />;
    // case "HeaderWithTransparentBackground":   //girlshub
    //   // return <Header10  home_custom={homecustom} />;
    //   return <Header10  home_custom={homecustom}  content_all={contentAll?.content?.HeaderWithTransparentBackground} />;
    // case "HeaderWithoutSearchBar":    //poornima
    //   return <Header11  home_custom={homecustom}   />;
    case "TopBannerWithSwiperImgandTextWithButton": 
      return <Banner7 banner={homecustom?.banner} content_all={newContent?.TopBannerWithSwiperImgandTextWithButton} />;   //smile banner
    case "TopBannerWithImgTitileDescriptionAndButton":
      return <Banner8 homecustom={homecustom?.banner} content_all={newContent?.TopBannerWithImgTitileDescriptionAndButton} />;   //gowri banner
    case "DykenBanner":
      return <Banner9 banner={homecustom?.banner} content_all={newContent?.DykenBanner} />;    
    case "TopBannerWithCarousal":
      return <Banner12 banner={homecustom?.banner} content_all={newContent?.TopBannerWithCarousal} />;    // one wear banner
    case "TopBannerWithGradientbgImgTitileDescriptionAndButtonAndMarquee":
      return <Banner10  banner={homecustom?.banner}  offertext={homecustom?.top_offer} content_all={newContent?.TopBannerWithGradientbgImgTitileDescriptionAndButtonAndMarquee} />;   //Gh Main Banner
    case "TopCategorywithoutSlider":
      return <TC1 category={homecustom?.category?.category_id} />;
    case "TopCategorywithArrowSlideonClick":
      return <TC2 category={homecustom?.category?.category_id} />;
    case "TopCategoryColorBoxeswithoutSlider":
      return <TC3 category={homecustom?.category?.category_id} />;
    case "TopCategorywithDoubleArrowSlideonClick":
      return <TC4 category={homecustom?.category?.category_id} />;
    case "TopCategorywithCenterImagePartitionDesign":
      return <TC5 category={homecustom?.category?.category_id} />;
    case "TopCategoryOvalDesignwithoutSlider":
      return <TC6 category={homecustom?.category?.category_id} />;
    case "TopCategoryWithTwoBannerswithoutSlider":
      return <TC12 category={homecustom?.category?.category_id} content_all={newContent?.TopCategoryWithTwoBannerswithoutSlider} />;   //one wear category
    case "TopCategoryInSmallCircleswithoutSlider":
      return <TC7 category={homecustom?.category?.category_id} content_all={newContent?.TopCategoryInSmallCircleswithoutSlider} />;  //smile top category
    case "TopCategoryOvalDesignwithSlider":
      return <TC10 category={homecustom?.category} content_all={newContent?.TopCategoryOvalDesignwithSlider} />;  //gh top category
    case "TC11":
      return <TC11 category={homecustom?.category} content_all={newContent?.TC11} />;  //gh top category
    case "OurFavourites":
      return <OurFavourites favourites={homecustom?.favorite_products} content_all={newContent?.OurFavourites} />;  //gh our favourites
    case "BannerWithTwoImages":
      return <HCGirlsHub banner={homecustom?.banner_custom} content_all={newContent?.BannerWithTwoImages} />;   //gh right left banner
    case "DykenTwoBanner":
      return <HCFurniture banner={homecustom?.banner_custom} content_all={newContent?.DykenTwoBanner} />;
    case "NewArrival2":
      return <NewArrival2 new_arraival={homecustom?.new_arrival} content_all={newContent?.NewArrival2} />;    //gh new arrival
    case "DykenNewArrival":
      return <NewArrival1 homecustom={homecustom?.new_arrival} content_all={newContent?.DykenNewArrival} />;
    case "NewArrival3":
      return <NewArrival3 homecustom={homecustom?.new_arrival} content_all={newContent?.NewArrival3} />;    //one wear new arrival
    case "NA3":
      return <NA3 homecustom={homecustom?.new_arrival} content_all={newContent?.NA3} />;    //poornima new arrival
    case "WomenWear1":
      return <WomenWear1 homecustom={homecustom?.WomenWear1} content_all={newContent?.WomenWear1} />;    //poornima women wear
    case "PoornimaFourBanner":
      return <PoornimaFourBanner homecustom={homecustom?.PoornimaFourBanner} content_all={newContent?.PoornimaFourBanner} />;    //poornima four banner
    case "Baskets":
      return <Baskets homecustom={homecustom?.Baskets} content_all={newContent?.Baskets} />;    //poornima Baskets
    case "GirlsEthinicWear1":
      return <GirlsEthinicWear1 homecustom={homecustom?.GirlsEthinicWear1} content_all={newContent?.GirlsEthinicWear1} />;    //poornima GirlsEthinicWear1
    case "ContactPoornima":
      return <ContactPoornima homecustom={homecustom?.ContactPoornima} content_all={newContent?.ContactPoornima} />;    //poornima GirlsEthinicWear1
    case "OfferBanner2":
      return <OfferBanner2 offer={homecustom?.customize_offer_banner} content_all={newContent?.OfferBanner2} />;   //gh offer banner
    case "OfferbannerSection":
      return <OfferbannerSection offer={homecustom?.customize_offer_banner} content_all={newContent?.OfferbannerSection} />;   //smile single offer banner
    case "TrendingOfMonth1":
      return <TrendingOfMonth1 trending={homecustom?.tranding_products} content_all={newContent?.TrendingOfMonth1} />;  //gh trending of month 
    case "WhyUsSection1":
      return <WhyUsSection why_us={homecustom?.whyus_banner} content_all={newContent?.WhyUsSection1} />;   //smile why us
    case "WhyUsSection2":
      return <WhyUsSection2 why_us={homecustom?.whyus_banner} content_all={newContent?.WhyUsSection2} />;   //gowri why us
    case "DykenWhyUs":
      return <WhyUsSection3 why_us={homecustom?.whyus_banner} content_all={newContent?.DykenWhyUs} />;
    case "WhyUsSection5":
      return <WhyUsSection5 why_us={homecustom?.whyus_banner} content_all={newContent?.WhyUsSection5} />;   //one wear why us
    case "WhyUsSection6":
      return <WhyUsSection6 why_us={homecustom?.whyus_banner} content_all={newContent?.WhyUsSection6} />;   //poornima why us
    case "WhyUsSection4":
      return <WhyUsSection4 whyus_banner={homecustom?.whyus_banner} content_all={newContent?.WhyUsSection4} />;   //gh why us
    case "HCGirlsHub2":
      return <HCGirlsHub2 banner={homecustom?.customize_banner} content_all={newContent?.HCGirlsHub2} />;   //gh left right banner
    case "FP1":
      return <FP1 featuredproduct_type={homecustom?.featured_products?.product_id} />;
    case "FP2":
      return <FP2 featuredproduct_type={homecustom?.featured_products?.product_id} />;
    case "FP3":
      return <FP3 featuredproduct_type={homecustom?.featured_products?.product_id} />;
    case "FP4":
      return <FP4 featuredproduct_type={homecustom?.featured_products?.product_id} />;
    case "FP5":
      return <FP5 featuredproduct_type={homecustom?.featured_products?.product_id} />;
    case "FP6":
      return <FP6 featuredproduct_type={homecustom?.featured_products?.product_id} />;
    case "FP12":
      return <FP12 featuredproduct_type={homecustom?.featured_products?.product_id} content_all={newContent?.FP12} />;   //one wear product section
    case "FP10":
      return <FP10 featureProduct={homecustom?.featured_products} content_all={newContent?.FP10} />;   //gh featured product
    case "Instagram1":
      return <Instagram1 followusoninstagram={homecustom?.follow_us_on_instagram} content_all={newContent?.Instagram1} />;   //gh instagram
    // case "Footer7":
    //   return <Footer7 home_custom={homecustom?.category} />;   //smile footer
    // case "Footer8":
    //   return <Footer8 home_custom={homecustom?.category} />;
    // case "Footer9":
    //   return <Footer9 home_custom={homecustom?.category} />;
    // case "Footer10":
    //   return <Footer10 home_custom={homecustom?.category} content_all={contentAll?.content?.Footer10} />;   //gh footer
    // case "Footer11":
    //   return <Footer11 home_custom={homecustom?.category} />;
    case "DykenSingleProduct":
      return <HomeProduct1 product={product} content_all={newContent?.DykenSingleProduct} />;
    case "HP2":
      return <HomeProduct2 homecustom={homecustom?.HP2} content_all={newContent?.HP2} />;  //gowri home product
    case "AC1":
      return <AboutUsClient review={review} />;   //smile about us
    case "DykenAboutUs":
      return <AboutUsFurniture review={review} About_us={About_us} content_all={newContent?.DykenAboutUs} />;   
    case "BestSeller1":
      return <BestSeller1 best_selling={best_selling} content_all={newContent?.BestSeller1} />;   //smile best seller
    case "DykenProducts":
      return <BestSeller2 homecustom={homecustom} content_all={newContent?.DykenProducts} />;
    case "DykenSingleBanner":
      return <BannerFurniture homecustom={homecustom} content_all={newContent?.DykenSingleBanner} />;
    case "DykenReview":
      return <ClientReview1 homecustom={homecustom} content_all={newContent?.DykenReview} />;
    case "Review_2":
      return <ClientReview2 homecustom={homecustom?.review_2} content_all={newContent?.Review_2} />;   //gowri client review
    case "review_3":
      return <ClientReview3 banner={banner} />;
    case "DykenAddress":
      return <HomeContact1 touchwith_us={touchwith_us} content_all={newContent?.DykenAddress} />;
    case "F1":
      return <Features1 homecustom={homecustom} />;
    case "F2":
      return <Features2 homecustom={homecustom?.F2} content_all={newContent?.F2} />;   //gowri features
    case "Sales":
      return <Sales homecustom={homecustom?.banner_mid} content_all={newContent?.Sales} />;   //gowri sales
    case "AvailablePlatform":
      return <AvailablePlatform homecustom={homecustom?.available_platforms} content_all={newContent?.AvailablePlatform} />;    //gowri available platform
    case "FAQ1":
      return <FAQ1 homecustom={homecustom?.faq_1} content_all={newContent?.FAQ1} />;   //gowri faq
    case "CustomizableCollection":
      return <CustomizableCollection homecustom={homecustom?.theme_2?.custom_collection}  content_all={newContent?.CustomizableCollection} />;   //gowri customizable collection
    case "PS1":
      return <ProductsAndService1 product_service={homecustom} content_all={newContent?.PS1}  />;   //smile product and service
    case "GS1":
      return <GiftSection7 product={product}  company={company} socialmedia={socialmedia} content_all={newContent?.GS1} />;   //smile gift section
    case "GSC1":
      return <GiftStoriesSection company={company} socialmedia={socialmedia} category={category} content_all={newContent?.GSC1} />;    //smile stories section
    case "GP1":
      return <GiftProductSection product={product} company={company} socialmedia={socialmedia} content_all={newContent?.GP1} />;    //smile gift product section
    case "Brand1":
      return <Brand1 brand={brand} content_all={newContent?.Brand1} />;    //smile brand
    case "QuestionBanner":
      return <QuestionBanner contact={contact} content_all={newContent?.QuestionBanner} />;    //smile question banner
    case "Marquee1":
      return <Marquee1 content_all={contentItem} />;    //one wear marquee
      // return <Marquee1 content_all={contentAll?.content?.Marquee1} />;    //one wear marquee
    case "SingleImageBanner":
      return <OfferBanner3 content_all={newContent?.SingleImageBanner} />;    //one wear single image banner
    case "AboutUsFashion":
      return <AboutUsFashion content_all={newContent?.AboutUsFashion} />;    //one wear about us
    case "FollowUs":
      return <FollowUs content_all={newContent?.FollowUs} />;    //one wear follow us (why us section 5)
    case "OurServices":
      return <OurServices content_all={newContent?.OurServices} />;    //one wear our services
    case "OurStory":
      return <OurStory content_all={newContent?.OurStory} />;    //one wear our story
    case "BA11":
      return <BA11 content_all={newContent?.BA11} />;    //Poornima main banner
    // default:
    //   return <EmptyDiv />;
    default:
            return null;
  } 
}

export default TestDemoSwitchMain