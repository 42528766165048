import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  HomeOutlined,
  AppstoreOutlined,
  BarsOutlined,
  FilterOutlined,
  LeftOutlined,
  RightOutlined,
  HeartOutlined,
  HeartFilled, 
} from "@ant-design/icons";

import {
  Breadcrumb,
  Menu,
  Slider,
  Segmented,
  Select,
  Button,
  Pagination,
  Divider,
  Row,
  Col,
  Input,
  Empty,
  Form,
  Spin,
  Drawer,
  Cascader,
  Space,
  Tabs, 
  Tag,
  message,
  Modal,
} from "antd";

import { font, theme } from "../ApiService/Theme";
import crud_service from "../ApiService/ApiService";
import API from "../ApiService/ApiService";
import { base_url, company_code } from "../config";
// import { useHistory } from 'react-router-dom';
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../Redux/slices/wishList";
import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "../Redux/slices/product";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../Redux/slices/user";
import { styles } from "../ApiService/Data";
import DataNotFound from "../Assets/Images/cloth stitching gif.gif";
import { updateLocalWishlistLength } from '../Redux/slices/wishlistLocalLength';

import { debounce } from "lodash";

const { Option } = Select;

const api = new API();
let price_Range = [];

let user = "";

const Shop = () => {
  // console.log("company code", company_code);
  //to get the params value
  const location = useLocation();
  // const history = useHistory();
  const valueSearch = location.state?.value;
  // console.log("subItem", valueSearch);
  const params = new URLSearchParams(location.search);
  const PARAMS = params;
  const catName = params.get("catName");
  const specDetailsName = params.get("specDetailsName");
  const [pageView, setPageView] = useState("grid");
  const catId = params.get("catId");
  const product_name = params.get("product_name");
  const loginTrue = localStorage.getItem("login");
  const getUser = localStorage.getItem("user");
  const wishlistLocal = JSON.parse(localStorage.getItem('wishList'));
  // console.log("wishlistLocal", wishlistLocal);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [count, setCount] = useState(1);
  const [specData, setSpecData] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openFilterDrawerLoading, setOpenFilterDrawerLoading] = useState(false);
  const [wishListLoading, setWishListLoading] = useState(false);
  const [hoverIndexes, setHoverIndexes] = useState({}); // Track hover image index per product ID
  const [hoverStates, setHoverStates] = useState({});
  const [foundSpectId1, setFoundSpectId1] = useState(null);
  const [wishList, setWishList] = useState([]);
  const [loginLoading, setLoginLoading] = useState(false);

  const [loginForm] = Form.useForm();


  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const showDrawer = () => {
    setOpenFilterDrawer(true);
    setOpenFilterDrawerLoading(true);

    setTimeout(() => {
      setOpenFilterDrawerLoading(false);
    }, 2000);
  };
  const onClose = () => {
    setOpenFilterDrawer(false);
    // clearAllFilters();
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleOk1 = () => {
    setOpen1(false);
  };
  const handleCancel1 = () => {
    setOpen1(false);
  };

  const login = async (values) => {
    setLoginLoading(true);
    let user;
    try {
      user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values
      );
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
    if (user?.status === 200) {
      // console.log("res--------->", user);
      message.success("Login Successful");
      localStorage.setItem("token", user?.data?.token);
      localStorage.setItem("user", JSON.stringify(user?.data?.data));
      localStorage.setItem("login", true);
      localStorage.setItem("buyer_name", values?.buyer_number);
      localStorage.setItem("buyer_pasword", values?.buyer_password);
      setLoginLoading(false);
      setOpen(false);
      window.location.reload();
    } else {
      message.error(user?.response?.data?.message);
      setLoginLoading(false);
    }
  };

  var defaultImage;
  // var hoverImage;

  // console.log("catId", catId)

  const navigate = useNavigate();

  // console.log("catName", catName);
  // console.log("specDetailsName", specDetailsName);
  // console.log("PARAMS", PARAMS)

  const dispatch = useDispatch();

  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  // console.log("isDecimal==>", isDecimal);

  //getting categoty from redux
  const category = useSelector((state) => state?.CetgoryMenu?.CetgoryMenu);
  // console.log("category===>", category)

  // const tax = useSelector((state) => state?.tax?.data);

  const products = useSelector(
    (state) => state?.product?.product?.data[0].data
  );
  // console.log("products123", products)

  const totalProducts = useSelector(
    (state) => state?.product?.product?.data[0].totalCount[0]?.total
  );

  const totalProducts1 = useSelector(
    (state) => state?.product?.product?.data[0]
  );

  // console.log("totalProducts1", totalProducts1);

  const specification = useSelector((state) => state?.specificationSlice?.specification?.data);

  const specificationColor = specification?.filter((item) => {
    return item?.name.toLowerCase() == "color";
  })?.[0]?._id;

  // const productFilter1 = products?.map((item) => ({
  //   ...item,
  //   prospecdocs: item?.prospecdocs?.filter((spec) => 
  //     spec?.specification?.includes(specificationColor)
  //   )
  // }));

  // const filterUniqueProspecdocs = (products) => {
  //   return products.map((product) => {
  //     const seenImageArrays = new Set();
  //     console.log("seenImageArrays", seenImageArrays)
  
  //     const filteredProspecdocs = product.prospecdocs.filter((doc) => {
  //       // Create a signature for the product_images based on their _id properties
  //       const imageSignature = JSON.stringify(
  //         doc.product_images.map((img) => img._id).sort()
  //       );

  //       console.log("seenImageArrays", seenImageArrays)
  
  //       if (seenImageArrays.has(imageSignature)) {
  //         // If the signature is already seen, skip this prospecdoc
  //         return false;
  //       } else {
  //         // Otherwise, add the signature to the set and keep this prospecdoc
  //         seenImageArrays.add(imageSignature);
  //         return true;
  //       }
  //     });
  
  //     return {
  //       ...product,
  //       prospecdocs: filteredProspecdocs,
  //     };
  //   });
  // };

  // Apply the function
  // const productFilter1 = filterUniqueProspecdocs(products);
  
  // console.log("uniqueProducts", productFilter1);
  


  // console.log("specification--->", specification);
  // console.log("specificationColor--->", specificationColor);
  // console.log("products", products)
  // console.log("productFilter1--->", productFilter1);

  // Function to filter and copy products based on specificationColor in their specification array
  // const filterAndCopyProducts = (products, specificationColor) => {
  //   return products.flatMap((product) => {
  //     // Find the specifications where the color matches
  //     const matchingSpecs = product.specification?.filter(item => item.specification === specificationColor);
  
  //     // For each matching specification, create copies based on the length of its specification_details array
  //     return matchingSpecs?.flatMap((spec) => {
  //       const length = spec.specification_details.length;
  //       return Array.from({ length }, () => ({ ...product }));
  //     }) || [product];
  //   });
  // };

  const filterAndCopyProducts = (products, specificationColor) => {
    return products.flatMap((product) => {
      // Find the specifications where the color matches
      const matchingSpecs = product.specification?.filter(
        (item) => item.specification === specificationColor
      );

      // console.log("matchingSpecs", matchingSpecs);
  
      // For each matching specification, create copies based on the length of its specification_details array
      return matchingSpecs?.flatMap((spec) => {
        const length = spec.specification_details.length;
        const prospecdocsLength = product.prospecdocs?.length || 0;

         // Calculate indices to map prospecdocs proportionally
         const indices = Array.from({ length }, (_, i) => {
          return Math.floor((prospecdocsLength / length) * i);
        });
  
        // Calculate the indices to extract from product.prospecdocs
        // const indices = Array.from({ length }, (_, i) => {
        //   return Math.floor((prospecdocsLength / (length - 1)) * i);
        // });
  
        // Ensure the last index points to the last element if not already
        // indices[length - 1] = prospecdocsLength - 1;
  
        // Create copies of the product with filtered prospecdocs
        return indices.map((index) => {
          const productCopy = { ...product, prospecdocs: [product.prospecdocs[index]] };
          return productCopy;
        });
      }) || [product];
    });
  }


  const filteredProducts123 = filterAndCopyProducts(products, specificationColor);
  // console.log("filteredProducts123--->", filteredProducts123);

  const specificationDetails = useSelector(
    (state) => state?.specificationdetailsSlice?.specificationdetails?.data
  );

  // console.log("specificationDetails--->", specificationDetails);

  const companyCode = useSelector(
    (state) => state?.company?.company?.data[0]?.company_code
  );

  // console.log("companyCode--->", companyCode);
  // console.log("companyCodeCheck--->", companyCode == "e9237dd0-d58a-426b-b9bb-e89bbee2817a");
  const [isNumber, setIsNumber] = useState(false);
  const [priceRange, setPriceRange] = useState([]);
  const [minMax, setMinMax] = useState([0, price_Range?.max_price]);
  const prevPriceRange = useRef(priceRange);

  // console.log("price_Range?.max_price", price_Range?.max_price);

  // Fetch initial price range
const getPriceRange1 = () => {
  api.getPriceRange().then((res) => {
    const price_Range = res?.data?.data[0];
    setMinMax([price_Range?.min_price, price_Range?.max_price]);
    setIsLoading(false);
  });
};

// Fetch price range on mount
useEffect(() => {
  getPriceRange1();
}, []);

useEffect(() => {
  getAllWishList();
}, []);
const getAllWishList = () => {
  api.getWishList(user?._id).then((res) => {
    setWishList(res?.data?.data || []);
    dispatch(wiseListSuccess(res?.data));
  });
};

// console.log("wishList", wishList);

  const SpecDetails = [];
  const SpecDetailsColor = [];

  specificationDetails.map((item) => {
    if (item?.company_code === companyCode && item?.specification_id?.name === "Gender") {
      SpecDetails.push({
        label: item?.specification_details,
        value: item?._id,
      });
    }
  })

  specificationDetails.map((item) => {
    if (item?.company_code === companyCode && item?.specification_id?.name.toLowerCase() == "color") {
      SpecDetailsColor.push({
        label: item?.specification_details,
        value: item?._id,
      });
    }
  })

  // console.log("specificationDetails--->", specificationDetails);
  // console.log("SpecDetailsColor--->", SpecDetailsColor);

  const SpecificationArray = [];

  specificationDetails.forEach((item) => {
    // console.log("specificationDetails-item", item)
    specification?.forEach((spec) => {
      // console.log("spec-item", spec)
      if (item?.specification_id?.name === spec?.name) {
        // Check if the key already exists, if not, create an empty array
        if (!SpecificationArray[spec?.name]) {
          SpecificationArray[spec?.name] = [];
        }
        // Push the new label and value object into the array
        SpecificationArray[spec?.name].push({
          label: item?.specification_details,
          value: item?._id,
          alignment: item?.alignment, // Include alignment to sort later
        });
         // Sort the group by alignment in ascending order
      SpecificationArray[spec?.name].sort((a, b) => a.alignment - b.alignment);
      }
    });
  });
  // console.log("SpecificationArray", SpecificationArray)
  
  const [size, setSize] = useState(null); // State for size selection
  // const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    const specDetails1 = SpecDetails?.find((item) => item?.label == specDetailsName);
    // console.log("specDetails1", specDetails1);
    // console.log("SpecDetails", SpecDetails);
    setSize(specDetailsName ? [specDetails1?.value] : null);
  }, [specDetailsName]);

  const sizeOptions = [
    // Size options
    { key: "s", label: "S" },
    { key: "m", label: "M" },
    { key: "l", label: "L" },
    { key: "xl", label: "XL" },
    { key: "2xl", label: "XXL" },
    { key: "3xl", label: "XXXL" },
  ];

  const sortOptions = [
    // { key: "sort-by-position", label: "Sort by position" },
    { key: "alphabetical-a-z", label: "Alphabetical, A-Z" },
    { key: "alphabetical-z-a", label: "Alphabetical, Z-A" },
    { key: "price-high-to-low", label: "Price, High to Low" },
    { key: "price-low-to-high", label: "Price, Low to High" },
    { key: "date-new-to-old", label: "Date, new to old" },
    { key: "date-old-to-new", label: "Date, old to new" },
  ];

  const [filteredProducts, setFilteredProducts] = useState(products);
  const [sortBy, setSortBy] = useState(null);
  const [categoryId, setCategoryId] = useState("");
  const [categoryId1, setCategoryId1] = useState("");

  // useEffect(() => {
  //   // console.log("catNameuseEffect", catName);
  //   // console.log("category===>useEffect", category);

  //   if (category && catName) {
  //     const matchedCategory = category.find((item) => item.label == catName);
  //     // console.log("matchedCategory===>", matchedCategory)
  //     if (matchedCategory) {
  //       // console.log("Matched category value:", matchedCategory.key);
  //       setCategoryId([matchedCategory.key]);
  //       setCategoryId1([matchedCategory.key])
  //     } else {
  //       // console.log("No matching category found");
  //       setCategoryId("");
  //     }
  //   }
  // }, [catName, category]);
  

  useEffect(() => {
        setCategoryId("");
        // setCategoryId1("");
        setSortOption("");
        setPriceRange([]);
        setSearchQuery("");
        // setcategoryName("")
        setSize(null);
        // setSortBy("Sort by position");
        setSortBy(null);
        setIsPaginationAvailable(false);
        setspecDetailsName1(null);
        setCurrentPage1(1);
        setIsLoading(false);
        setIsNumber(false);
        setOpenFilterDrawer(false);
  }, [catName]);

 // Filter and sort products
useEffect(() => {
  // console.log("products", products)
  // console.log("filteredProducts123", filteredProducts123)
  setIsLoading(true);
  let filtered = filteredProducts123?.length > 0 ? filteredProducts123 : products;
  // let filtered = filteredProducts123;
  // console.log("filtered", filtered)
  let categorizedIds = {};

  Object.keys(SpecificationArray).forEach((category) => {
    categorizedIds[category] = size?.filter((id) =>
      SpecificationArray[category].some((item) => item?.value === id)
    );
  });

  const nonEmptyCategories = Object.keys(categorizedIds).filter(
    (category) => categorizedIds[category]?.length > 0
  );

  // Filtering logic for specifications
  if (size?.length) {
    filtered = products?.filter((product) => {
      return product?.specification?.some((item) => {
        if (nonEmptyCategories.length > 1) {
          return nonEmptyCategories.every((category) => {
            return categorizedIds[category].some((id) =>
              product.specification.some((spec) =>
                spec.specification_details.includes(id)
              )
            );
          });
        } else if (nonEmptyCategories.length === 1) {
          const category = nonEmptyCategories[0];
          return categorizedIds[category].some((id) =>
            product.specification.some((spec) =>
              spec.specification_details.includes(id)
            )
          );
        }
        return false;
      });
    });
  }

  // Apply price range filter if minMax is set
  // const [minPrice, maxPrice] = minMax;
  const [minPrice, maxPrice] = priceRange.length > 0 ? priceRange : minMax;
  // console.log("minMax", minMax)
  // console.log("priceRange", priceRange)


  if (minPrice !== 0 || maxPrice !== 0) {
    // console.log("filtered??????", filtered);
    filtered = filtered.filter((product) => {
      // const price = product.common_sp
      //   ? product.common_sp + (product.common_sp * (product.taxdocs?.[0]?.tax_percentage || 0)) / 100
      //   : (product.prospecdocs?.[0]?.sp || 0) +
      //     ((product.prospecdocs?.[0]?.sp || 0) * (product.taxdocs?.[0]?.tax_percentage || 0)) / 100;

      // console.log("product???", product)
      
      if(product?.prospecdocs?.[0]?.show_mrp == true && product?.prospecdocs?.[0]?.show_sp == false){
        // console.log("I am in if", product?.prospecdocs?.[0]?.mrp);
        // console.log("I am in minPrice", minPrice);
        // console.log("I am in maxPrice", maxPrice);
        const price = Math.round((product.prospecdocs?.[0]?.mrp || 0) +
        ((product.prospecdocs?.[0]?.mrp || 0) * (product.taxdocs?.[0]?.tax_percentage || 0)) / 100);
        // console.log("I am in Price", price);
        return price >= minPrice && price <= maxPrice;
      } else if(product?.show_common_mrp == true && product?.show_common_sp == false) {      
        // console.log("I am in if");
        const price = product?.common_mrp;
        return price >= minPrice && price <= maxPrice;
      } else {
        // console.log("I am in else");
        const price = product.prospecdocs?.[0]?.sp ? (product.prospecdocs?.[0]?.sp || 0) +
        ((product.prospecdocs?.[0]?.sp || 0) * (product.taxdocs?.[0]?.tax_percentage || 0)) / 100 : 
        product.common_sp + (product.common_sp * (product.taxdocs?.[0]?.tax_percentage || 0)) / 100;
        // console.log("product.prospecdocs?.[0]?.sp", product)
        // console.log("price", price)
        // console.log("minPrice", minPrice)
        // console.log("maxPrice", maxPrice)
        return price >= minPrice && price <= maxPrice;
      }
    });
  }

  // console.log("filtered----->", filtered);

  // Sort the filtered products
  sortProducts(sortBy, filtered);
// }, [size, sortBy, products, minMax]);
}, [size, sortBy, products, minMax]);

  const handleSizeChange = (value) => {
    // console.log("handleSizeChange", value);
    // console.log("handleSizeChange", size);
    const updatedSize = size || [];
    if (updatedSize?.includes(value)) {      
      setSize(updatedSize?.filter((item) => item !== value));
    } else {     
      setSize([...updatedSize, value]);
    }
    // setSize(value);
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
    setspecDetailsName1([value]);
  };

  const handleSizeChange1 = (value) => {
    // console.log("handleSizeChange", value); 
    if(value?.length > 0) {
      setSize(value);
      setIsPaginationAvailable(true);
      setCurrentPage1(1);
      setspecDetailsName1([value]);
    } else {
      setSize(value);
      setIsPaginationAvailable(true);
      setCurrentPage1(1);
      setspecDetailsName1(null);
    }
  };

  const handleCategoryChange = (value) => {
    // Check if the selected category is already the current category
    if (categoryId == value) {
      // Deselect the category by clearing the state
      setCategoryId(null);
      setCategoryId1([]);
    } else {
      // Select the new category
      setCategoryId(value);
      setCategoryId1([value]);
    }
  
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
  };
  
  const handleSortChange = (value) => {
    // console.log("handleSortChange", value);
    if (sortBy == value) {
      // Deselect the category by clearing the state
      setSortBy(null);
    } else {
      // Select the new category
      setSortBy(value);
    }
    // setSortBy(value);
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
    setIsNumber(true);
  };

  const sortProducts = (sortKey, productsToSort) => {
    let sorted = [...productsToSort];
    // console.log("sorted==>", sorted);
    switch (sortKey) {
      case "alphabetical-a-z":
        sorted.sort((a, b) =>
          a.product_name
            .replace(/[^a-zA-Z]/g, "")
            .localeCompare(b.product_name.replace(/[^a-zA-Z]/g, ""))
        );
        // sorted.sort((a, b) => a.product_name.localeCompare(b.product_name));
        break;
      case "alphabetical-z-a":
        sorted.sort((a, b) =>
          b.product_name
            .replace(/[^a-zA-Z]/g, "")
            .localeCompare(a.product_name.replace(/[^a-zA-Z]/g, ""))
        );
        // sorted.sort((a, b) => b.product_name.localeCompare(a.product_name));
        break;
      case "price-low-to-high":
        sorted.sort((a, b) => {
          const aPrice = a.common_sp ? (a.common_sp + (a.common_sp * a.taxdocs?.[0]?.tax_percentage) / 100) : 
            (a.prospecdocs?.[0]?.sp +
            (a.prospecdocs?.[0]?.sp * a.taxdocs?.[0]?.tax_percentage) / 100);
          const bPrice =  b.common_sp ? (b.common_sp + (b.common_sp * b.taxdocs?.[0]?.tax_percentage) / 100) :
            (b.prospecdocs?.[0]?.sp +
            (b.prospecdocs?.[0]?.sp * b.taxdocs?.[0]?.tax_percentage) / 100);
          return aPrice - bPrice;
        });
        break;
      case "price-high-to-low":
        sorted.sort((a, b) => {
          const aPrice = a.common_sp ? (a.common_sp + (a.common_sp * a.taxdocs?.[0]?.tax_percentage) / 100) :
            (a.prospecdocs?.[0]?.sp +
            (a.prospecdocs?.[0]?.sp * a.taxdocs?.[0]?.tax_percentage) / 100);
          const bPrice = b.common_sp ? (b.common_sp + (b.common_sp * b.taxdocs?.[0]?.tax_percentage) / 100) :
            (b.prospecdocs?.[0]?.sp +
            (b.prospecdocs?.[0]?.sp * b.taxdocs?.[0]?.tax_percentage) / 100);
          return bPrice - aPrice;
        });
        break;
      case "date-new-to-old":
        sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case "date-old-to-new":
        sorted.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      default:
        break;
    }
    setFilteredProducts(sorted);
    setResultNumbers(sorted?.length);
    setIsLoading(false);
  };

  

  // const [menu, setMenu] = useState(category);

  //states for product filter
  const [isPaginationAvailable, setIsPaginationAvailable] = useState(specDetailsName ? true : false);
  const [resultNumbers, setResultNumbers] = useState(0);
  
  const [sortOption, setSortOption] = useState("");
  // const [priceRange, setPriceRange] = useState([]);
  const [searchQuery, setSearchQuery] = useState(valueSearch ? valueSearch : "");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [currentPage, setCurrentPage] = useState(1); //holding current page number for pagination
  const [perPage, setPerPage] = useState(10); //holding how much data should be available in single page
  const [categoryName, setcategoryName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [specDetailsName1, setspecDetailsName1] = useState(null);
  // const [minMax, setMinMax] = useState([0, price_Range?.max_price]);
  // const prevPriceRange = useRef(priceRange);

  const [categorys, setCategory] = useState([]);

  const [finalOne, setFinalOne] = useState([]);

  const [currentPage1, setCurrentPage1] = useState(1); // State to track the current page
  const [pageSize1, setPageSize1] = useState(10); // State to track the number of products per page

  const handlePageChange1 = (page, pageSize) => {
    setCurrentPage1(page);
    setPageSize1(pageSize);
  };

  // Calculate the products to display for the current page
  const paginatedProducts = filteredProducts?.slice(
    (currentPage1 - 1) * pageSize1,
    currentPage1 * pageSize1
  );

  //to check if any filter applied
  const anyFilterApplied =
    categoryId !== "" ||
    sortOption !== "" ||
    priceRange.length > 0 ||
    categoryName !== "" ||
    searchQuery !== "" ||
    size !== null;

  // console.log("anyFilterApplied", anyFilterApplied);
  // console.log("searchQuery", searchQuery);

  useEffect(() => {
    setSearchQuery(valueSearch ? valueSearch : "");
  },[valueSearch]);

  //clear all filters
  const clearAllFilters = () => {
    const currentPathname = window.location.pathname.toLowerCase();
    const currentSearch = window.location.search.toLowerCase();

    // console.log("currentPathname", currentPathname);
    // console.log("currentSearch", currentSearch);
    setIsLoading(true);
    setTimeout(() => {
      setCategoryId("");
      setCategoryId1("");
      handlePriceFilter("");
      setPriceRange([]); // Reset to the initial price range
      setSearchQuery("");
      setSize(null); // Reset the size filter
      setSortOption(""); // Reset sort option
      setspecDetailsName1(null);
      setIsPaginationAvailable(false); 
      setIsNumber(false);
      setOpenFilterDrawer(false);
  
      if (currentSearch) {
        // console.log("/products-->");
        navigate("/products");
        setIsPaginationAvailable(false);
        setCurrentPage1(1);
        setCategoryId("");
        setCategoryId1("");
        setSortOption("");
        setPriceRange([]);
        setSearchQuery("");
        setcategoryName("");
        setSize(null);
        // setSortBy("Sort by position");
        setSortBy(null);
        setspecDetailsName1(null);
        setIsLoading(false);
        setIsNumber(false);
        setOpenFilterDrawer(false);
      } else {
        // console.log("currentSearch-->");
        setCategoryId("");
        setCategoryId1("");
        setSortOption("");
        setPriceRange([]);
        setSearchQuery("");
        // setcategoryName("")
        setSize(null);
        // setSortBy("Sort by position");
        setSortBy(null);
        setIsPaginationAvailable(false);
        setspecDetailsName1(null);
        setCurrentPage1(1);
        setIsLoading(false);
        setIsNumber(false);
        setOpenFilterDrawer(false);
        navigate("/products");
      }
      setIsLoading(false);
    }, 250)   
    
    // setCategoryId("");
    // setSortOption("");
    // setPriceRange([]);
    // setSearchQuery("");
  };

  // console.log("minMax", minMax)

  const getPriceRange = () => {
    api.getPriceRange().then((res) => {
      // console.log("price range", res)
      price_Range = res?.data?.data[0];
      // console.log("priceRange", priceRange)
      setMinMax([price_Range?.min_price, price_Range?.max_price]);
      setIsLoading(false);
    });
  };

  const getAllCategory = async(category) => {
    await api.getcategory().then((res) => {
      if(res?.data?.success === true){
        const data = res?.data?.data?.filter((item) => item?.status == true);
        setCategory(data);
        categoryTree(data);
      }
    })
  }

  const categoryTree = (category) => {
    let filter = category.filter((e) => {
      return e.category_id == null;
    });
    // console.log("filter", filter);
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // console.log("final", final);
    setFinalOne(final);
    return final;
  };
  //subcategory tree
  const categoryTree2 = (id, category) => {
    let filter = category.filter((e) => {
      return e.category_id == id;
    });
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // setFinalOne(final);
    return final;
  };

  useEffect(() => {
    if (category && catName) {
      const matchedCategory = category.find((item) => item.label == catName);
      // console.log("matchedCategory===>", matchedCategory)
      // console.log("finalOne===>", finalOne)
      if (matchedCategory) {
        // console.log("Matched category value:", matchedCategory.key);
        setCategoryId([matchedCategory.key]);
        setCategoryId1([matchedCategory.key])

        // Function to find the full path (parent to child) for the matched category
        const findParentPath = (options, targetId) => {
          // console.log("options--", options)
          // console.log("targetId--", targetId)
          for (const option of options) {
            // console.log("option--", option)
            if (option.value === targetId) {
              return [option.value]; // If the target matches, return its value
            }
            if (option.children && option.children.length > 0) {
              const childPath = findParentPath(option.children, targetId);
              if (childPath) {
                return [option.value, ...childPath]; // Prepend parent value to the path
              }
            }
          }
          return null; // Return null if no match is found
        };

        // Find the full hierarchical path
        const fullPath = findParentPath(finalOne, matchedCategory.key);
        if (fullPath) {
          // console.log("Full category path:", fullPath);

          // Set the full path to `categoryId1`
          setCategoryId1(fullPath);

          // Optionally, set `categoryId` to the last value in the path (subcategory ID)
          setCategoryId(fullPath[fullPath.length - 1]);
        } else {
          console.log("Category not found in final structure.");
        }

      } else {
        // console.log("No matching category found");
        setCategoryId("");
      }
    }
  }, [catName, category, finalOne]);

  // console.log("categoryId1---->", categoryId1)

  useEffect(() => {
    getPriceRange();
  }, []);
  useEffect(() => {
    // getPriceRange();
    if(product_name != null & product_name != ""){
      setIsPaginationAvailable(true)
    }
  }, [product_name]);
  // console.log("product_name", product_name)
  useEffect(() => {
    getPriceRange();
    // console.log("priceRange")
    getAllCategory();
    setIsLoading(true);
    setCurrentPage(1);
  }, [catName]);

  const itemNav = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    // {
    //   href: "/products",
    //   title: "Shop",
    // },
  ];

  const itemNav1 = [
    {
      href: "/products",
      title: "Clear all",
    },
  ];

  const itemFilter = [
    {
      key: 1,
      label: "Search",
    },
    {
      key: 2,
      label: "Styles",
    },
    {
      key: 3,
      label: "Specifications",
    },
    {
      key: 4,
      label: "Sort",
    },
    {
      key: 5,
      label: "Price Range",
    },    
  ];

  //to calculate the total page number
  const totalPageCount = Math.ceil(totalProducts / perPage);

  //get params value if someone try to access the product using catogery name
  const setPrams = () => {
    if (catName) {      
      setcategoryName(catName);
    } else if (catId) {
      setCategoryId(catId);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500); // 500ms delay for debounce

    return () => clearTimeout(handler); // Cleanup timeout
  }, [searchQuery]);

  //when the filter apllies it takes the filter value in the params and get the products from api
  const getProducts = useCallback(async () => {
    const isPriceRangeChanged = prevPriceRange.current[0] !== priceRange[0] || prevPriceRange.current[1] !== priceRange[1];
    // console.log("isPaginationAvailable", isPaginationAvailable);
    // console.log("catName", catName);
    // console.log("currentPage", currentPage);
    if (isPaginationAvailable === true) {
      // console.log("isPaginationAvailable???", "true");
      // console.log("categoryId???", categoryId);
      // console.log("sortOption???", sortOption);
      // console.log("priceRange???", priceRange);
      // console.log("searchQuery???", searchQuery);
      // console.log("categoryName???", categoryName);
      // console.log("sortBy???", sortBy);
      // console.log("size???", size);
      // console.log("i am in isPaginationAvailable true");
     
        setCurrentPage(1);
        const data = await api.getAll(
          dispatch,
          [productGetAllStart, productGetAllSuccess, productGetAllFail],
          "products",
          {
            category: categoryId,
            page: 1,
            perpage: 200000000000,
            sortfilter: sortOption,
            min: priceRange[0],
            max: priceRange[1],
            // search: searchQuery || product_name,
            search: debouncedSearchQuery  || product_name,
            brandname: "",
            fields: "",
            categoryname: categoryName,
            newsort: sortBy,
            Size: size,
          },
          (err, res) => {}
        );    
    } else {
      // console.log("isPaginationAvailable???", "false");
      // console.log("specDetailsName", specDetailsName);
      // console.log("product_name", product_name);
      // console.log("i am in isPaginationAvailable false");
      const data = await api.getAll(
        dispatch,
        [productGetAllStart, productGetAllSuccess, productGetAllFail],
        "products",
        {
          // category: categoryId,
          category: catId,
          page: currentPage,
          perpage:  specDetailsName ? 200000000000 : product_name ? 200000000000 : perPage,
          sortfilter: sortOption,
          min: priceRange[0],
          max: priceRange[1],
          // search: searchQuery || product_name,
          search: debouncedSearchQuery  || product_name,
          brandname: "",
          fields: "",
          // categoryname: categoryName,
          categoryname: catName,
          newsort: sortBy,
          Size: sizeOptions,
        },
        (err, res) => {}
      );
    }
    getPriceRange();
    // Only scroll if priceRange hasn't changed
    if (!isPriceRangeChanged) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    prevPriceRange.current = priceRange;
  }, [
    categoryId,
    categoryId1,
    currentPage,
    perPage,
    size,
    sortBy,
    priceRange,
    // searchQuery,
    debouncedSearchQuery,
    categoryName,
    product_name,
  ]);

  // console.log("price", minMax)
  useEffect(() => {
    getProducts();
    // set
  }, [getProducts]);

  useEffect(() => {
    setPrams();
  }, [catName, catId]);

  useEffect(() => {
    const amounts = paginatedProducts
      .map((product) => {
        const sp = Number(product?.prospecdocs?.[0]?.sp);
        const taxPercentage = Number(product?.taxdocs?.[0]?.tax_percentage);
        const total = sp + Number(((sp * taxPercentage) / 100).toFixed(2));
        // console.log("total-->", total);
  
        // Return total or NaN if either `sp` or `taxPercentage` is invalid
        return isNaN(total) ? null : total;
      })
      .filter((value) => value !== null); // Filter out NaN or invalid values
  
    const largestAmount = Math.max(...amounts);
    const smallestAmount = Math.min(...amounts);

    // setMinMax([smallestAmount, largestAmount]);
  
    // console.log('Largest Amount:', largestAmount);
    // console.log('Smallest Amount:', smallestAmount);
  }, [paginatedProducts]);
  // console.log("size==>", size);
  // console.log("sortBy==>", sortBy);
  // console.log("totalProducts==>", totalProducts);
  // console.log("resultNumbers==>", resultNumbers);

  //function handle price filter
  const handlePriceFilter = (value) => {
    setPriceRange(value);
    setIsPaginationAvailable(true);
    setCurrentPage1(1);
    setIsNumber(true);
  };

  //handle sort filter
  const handleSortProduct = (option) => {
    setSortOption(option);
    getProducts();
  };

  //function handle pagination
  const handlePaginationChange = (page, pageSize) => {
    // console.log("page", page, "pageSize", pageSize);
    setCurrentPage(page);
    setPerPage(pageSize);
  };

  const handleViewChange = (value) => {
    // console.log("value", value);
    setPageView(value);
  };

  const clearSize = (value) => setSize(size.filter(item => item !== value));
const clearCategory = () => setCategoryId(null);
const clearSort = () => setSortBy(null);
const clearPrice = () => setPriceRange([minMax[0], minMax[1]]);

const categorizeSelectedSpecifications = () => {
  const categorizedIds = {};

  Object.keys(SpecificationArray).forEach((category) => {
    categorizedIds[category] = size?.filter((id) =>
      SpecificationArray[category].some((item) => item?.value === id)
    );
  });

  return categorizedIds;
};

// Hover enter and leave handlers
const handleMouseEnter = (productId) => {
  setHoverStates((prev) => ({ ...prev, [productId]: true }));

  // Initialize hover index for this product if not already set
  setHoverIndexes((prev) => ({
    ...prev,
    [productId]: prev[productId] !== undefined ? prev[productId] : 0,
  }));
};

const handleMouseLeave = (productId) => {
  setHoverStates((prev) => ({ ...prev, [productId]: false }));
};

// Navigate to the next image
const handleNextImage = (productId, imageCount) => {
  setHoverIndexes((prev) => {
    const newIndex = (prev[productId] + 1) % imageCount;
    console.log(`Next Index for ${productId}: ${newIndex}`);
    return { ...prev, [productId]: newIndex };
  });
};

const handlePrevImage = (productId, imageCount) => {
  setHoverIndexes((prev) => {
    const newIndex = (prev[productId] - 1 + imageCount) % imageCount;
    // console.log(`Prev Index for ${productId}: ${newIndex}`);
    return { ...prev, [productId]: newIndex };
  });
};

const addToWiseList = (pro) => {
  // console.log("pro", pro)
  setWishListLoading(true);

  const combinedData = {
    qty: count,
    product_id: pro?.prospecdocs?.[0]?.product_id,
    product_spec: pro?.prospecdocs?.[0]?._id,
    company_code: user?.company_code ? user?.company_code : companyCode,
  };


  if (!loginTrue) {
  //   setWishListLoading(false);
  //   setOpen(true);
    // Retrieve existing data from local storage
    let storedList = JSON.parse(localStorage.getItem('wishList')) || [];

    // Check if a matching entry exists
    const existingIndex = storedList.findIndex(
      item => 
        item.product_id === combinedData.product_id &&
        item.product_spec === combinedData.product_spec
    );

    if (existingIndex !== -1) {
      // Update the quantity of the existing item
      storedList[existingIndex].qty += combinedData.qty;
    } else {
      // Add the new combinedData object to the list
      storedList.push(combinedData);
    }

    // Store the updated list back to local storage
    localStorage.setItem('wishList', JSON.stringify(storedList));
    dispatch(updateLocalWishlistLength(storedList.length));
    setHoverStates(false);

    setWishListLoading(false);
  } else {
    // const combinedData = {
    //   buyer_id: user?._id,
    //   qty: count,
    //   product_id: pro?.prospecdocs?.[0]?.product_id,
    //   product_spec: pro?.prospecdocs?.[0]?._id,
    //   company_code: user?.company_code ? user?.company_code : companyCode,
    // };

    const combinedDataWithUser = {
      ...combinedData,
      buyer_id: user?._id,
    };

    // console.log("combinedDataWishlist", combinedData);
    api.addWiseList(combinedDataWithUser).then((res) => {
      // console.log(res);
      if (res?.status == 201) {
        dispatch(wiseListStart());
        // dispatch(wiseListStart);
        api.getWishList().then((res) => {
          // console.log("res--->", res);
          dispatch(wiseListSuccess(res?.data));
        });
        // message.success(res?.data?.message);
        setWishListLoading(false);
        getAllWishList();
        // window.location.reload();
      } else {
        dispatch(wiseListFailure());
        // dispatch(wiseListFailure(res?.data));
        // message.error(res?.response?.data?.message);
        setWishListLoading(false);
        getAllWishList();
      }
    });
  }
};

const deleteItem = (id) => {
  api.deleteWishList(id).then((res) => {
    // message.success("Deleted Successfully");
    getAllWishList()

  });
};

const deleteItem1 = (specId) => {
  // Remove from specData
  setSpecData((prevSpecData) => prevSpecData.filter((spec) => spec._id !== specId));

  // Remove from localStorage
  let storedList = JSON.parse(localStorage.getItem('wishList')) || [];
  storedList = storedList.filter((item) => item.product_spec !== specId);
  localStorage.setItem('wishList', JSON.stringify(storedList));
  dispatch(updateLocalWishlistLength(storedList.length));
  setHoverStates(false);
  // if(storedList.length <= 0) {
  //   window.location.reload();
  // };
};

useEffect(() => {
  // console.log("Hover Indexes:", hoverIndexes);
}, [hoverIndexes]);

const findParentPath = (options, targetId) => {
  for (const option of options) {
    if (option.value === targetId) {
      
      return [option.value];
    }
    if (option.children && option.children.length > 0) {
      const childPath = findParentPath(option.children, targetId);
      if (childPath) {
        
        return [option.value, ...childPath];
      }
    }
  }
  return null; 
};

  return (
    <ShopSection>
      {isLoading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
          }}
        >
          <div className="wrapper" style={{ padding: "60px 40px" }}>
            <div className="shop_section">
              <div className="shop_head_bar_align">
                <div className="shop_head_bar_left">
                  <h1>Products</h1>
                </div>
                <div className="shop_head_bar_right">
                  <Breadcrumb items={itemNav} />
                </div>
              </div>
              <div className="shop_filter_align">
                {/* <div className="shop_filter_left">
                <Input
                  placeholder="Search Products..."
                  allowClear
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
                <Divider />
                <h2>Category</h2>
                <Menu
                  mode="inline"
                  items={category}
                  selectedKeys={[categoryId]}
                  onSelect={(e) => {
                    setCategoryId(e?.key);
                  }}
                />
                <Divider />
                <h2>Filter by Price</h2>
                <Slider
                  range={{
                    draggableTrack: true,
                  }}
                  onChange={handlePriceFilter}
                />
                <Divider />
              </div> */}
                <div className="shop_filter_right">
                  <div className="shop_filter_right_top">
                    <Input
                      placeholder="Search Products..."
                      allowClear
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      style={{
                        width: "180px",
                      }}
                    />
                    <Select
                      allowClear
                      label="Category"
                      placeholder="Select Category"
                      mode="inline"
                      value={categoryId ? categoryId : (companyCode == "e9237dd0-d58a-426b-b9bb-e89bbee2817a") ? "--Styles--" : "--Categories--"}
                      onChange={(value) => {
                        // console.log("value", value);
                        setCategoryId(value);
                      }}
                      style={{
                        width: "140px",
                      }}
                      // defaultValue={categoryId ? categoryId : "--Select--"}
                    >
                      {category?.map((item) => (
                        <Option key={item?.key} value={item?.key}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 10 }}>Price: </span>
                      <Slider
                        min={minMax[0]}
                        max={minMax[1]}
                        range={{
                          draggableTrack: true,
                        }}
                        onChange={handlePriceFilter}
                        style={{
                          width: "180px",
                        }}
                        defaultValue={minMax}
                      />
                    </div>
                    <div className="shop_filter_right_top_left">
                      <p className="shop_filter_right_top_left_totalProducts">
                        Showing of1 {totalProducts} results
                      </p>
                      <p
                        className="shop_filter_right_top_left_clear"
                        onClick={clearAllFilters}
                      >
                        {anyFilterApplied ? "Clear all" : ""}
                      </p>
                    </div>
                    {/* <Row>
                    <Col span={24}>
                      Sort : &nbsp;
                      <Select
                        onChange={handleSortProduct}
                        placeholder="Select to Sort "
                        value={sortOption}
                      >
                        <Option key="0" value="">
                          Default Sort
                        </Option>
                        <Option key="1" value="hightolow">
                          High to Low
                        </Option>
                        <Option key="2" value="lowtohigh">
                          Low to High
                        </Option>
                        <Option key="3" value="newtoold ">
                          New to Old
                        </Option>
                        <Option key="4" value="oldtonew">
                          Old to New
                        </Option>
                      </Select>
                    </Col>
                  </Row> */}
                    {/* <Segmented
                    options={[
                      {
                        value: "List",
                        icon: <BarsOutlined />,
                      },
                      {
                        value: "grid",
                        icon: <AppstoreOutlined />,
                      },
                    ]}
                    onChange={(value) => handleViewChange(value)}
                  /> */}
                  </div>
                  <br />
                  <br />
                  {products?.length > 0 && (
                    <div className="product_align_filter">
                      {products?.length > 0 &&
                        products?.map((product) => (
                          <Link
                            // to={`/product/${product?._id}`}
                            to={`/product/${product?.product_name}/${product?._id}`}
                            // to={{pathname: `/product/${product?.product_name}/${product?._id}`, state: { fromDashboard: true }}}
                            key={product?._id}
                            style={{ textDecoration: "none" }}
                          >
                            <div className="product_align_filter_box">
                              <div className="p_image">
                                <img
                                  src={
                                    product?.images &&
                                    base_url + product?.images
                                  }
                                  alt="Default"
                                />
                              </div>
                              <div className="p_content">
                                <h2 style={{ color: "black" }}>
                                  {product?.product_name}
                                </h2>
                                <div className="price">
                                  <div className="sp">
                                    {product?.prospecdocs &&
                                    product?.prospecdocs?.length > 0
                                      ? styles?.currency +
                                        (Number(product?.prospecdocs[0]?.sp) +
                                          Number(
                                            (product?.prospecdocs[0]?.sp *
                                              product?.taxdocs[0]
                                                ?.tax_percentage) /
                                              100
                                          ))
                                      : 0}
                                  </div>
                                  <div className="mrp">
                                    {product?.prospecdocs &&
                                    product?.prospecdocs?.length > 0
                                      ? styles?.currency +
                                        (product?.prospecdocs[0]?.mrp +
                                          Number(
                                            (product?.prospecdocs[0]?.sp *
                                              product?.taxdocs[0]
                                                ?.tax_percentage) /
                                              100
                                          ))
                                      : 0}
                                  </div>
                                </div>
                                <div>
                                  <Button size="medium">View Detail</Button>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  )}
                  {products?.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Coming Soon" />}
                  <div className="shop_pagination">
                    <Pagination
                      Current={currentPage}
                      size="small"
                      onChange={handlePaginationChange}
                      pageSizeOptions={[10, 20, 30]}
                      showSizeChanger
                      showLessItems={totalPageCount <= 2}
                      total={totalProducts}
                    />
                  </div>

                  <div className="shop_filter_right_top_left">
                    <p className="shop_filter_right_top_left_totalProducts">
                      Showing of {totalProducts} results
                    </p>
                    <p
                      className="shop_filter_right_top_left_clear"
                      onClick={clearAllFilters}
                    >
                      {anyFilterApplied ? "Clear all" : ""}
                    </p>
                  </div>

                  {/* <Row>
                  <Col span={24}>
                    Sort : &nbsp;
                    <Select
                      onChange={handleSortProduct}
                      placeholder="Select to Sort "
                      value={sortOption}
                    >
                      <Option key="0" value="">
                        Default Sort
                      </Option>
                      <Option key="1" value="hightolow">
                        High to Low
                      </Option>
                      <Option key="2" value="lowtohigh">
                        Low to High
                      </Option>
                      <Option key="3" value="newtoold ">
                        New to Old
                      </Option>
                      <Option key="4" value="oldtonew">
                        Old to New
                      </Option>
                    </Select>
                  </Col>
                </Row> */}
                  {/* <Segmented
                  options={[
                    {
                      value: "List",
                      icon: <BarsOutlined />,
                    },
                    {
                      value: "grid",
                      icon: <AppstoreOutlined />,
                    },
                  ]}
                  onChange={(value) => handleViewChange(value)}
                /> */}
                </div>
                <br />
                <br />
                {products?.length > 0 && (
                  <div className="product_align_filter">
                    {products?.length > 0 &&
                      products?.map((product) => (
                        <Link
                          // to={`/product/${product?._id}`}
                          to={`/product/${product?.product_name}/${product?._id}`}
                          key={product?._id}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="product_align_filter_box">
                            <div className="p_image">
                              <img
                                src={
                                  product?.images && base_url + product?.images
                                }
                                alt="Default"
                              />
                            </div>
                            <div className="p_content">
                              <h2 style={{ color: "black" }}>
                                {product?.product_name}
                              </h2>
                              <div className="price">
                                <div className="sp">
                                  {product?.prospecdocs &&
                                  product?.prospecdocs?.length > 0
                                    ? styles?.currency +
                                      (Number(product?.prospecdocs[0]?.sp) +
                                        Number(
                                          (product?.prospecdocs[0]?.sp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                            100
                                        ))
                                    : 0}
                                </div>
                                <div className="mrp">
                                  {product?.prospecdocs &&
                                  product?.prospecdocs?.length > 0
                                    ? styles?.currency +
                                      (product?.prospecdocs[0]?.mrp +
                                        Number(
                                          (product?.prospecdocs[0]?.mrp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                            100
                                        ))
                                    : 0}
                                </div>
                              </div>
                              <div>
                                <Button size="medium">View Detail</Button>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                  </div>
                )}
                {products?.length == 0 && 
                // <Empty description="No Products Found" />
                <Empty 
                    image={DataNotFound}
                    description="Coming Soon..."
                    imageStyle={{
                      // height: 400,
                      display: "flex",
                      justifyContent: "center",                      
                    }}
                    style={{
                      backgroundColor: "#f7f7f7",
                      fontSize: "25px",
                      padding: "20px 0px",
                      // opacity: "0.01",
                    }}
                  />
                }

                {/* <div className="shop_pagination">
                  <Pagination
                    Current={currentPage}
                    size="small"
                    onChange={handlePaginationChange}
                    pageSizeOptions={[10, 20, 30]}
                    showSizeChanger
                    showLessItems={totalPageCount <= 2}
                    total={totalProducts}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </Spin>
      ) : (
        <div className="wrapper" style={{ padding: "60px 40px" }}>
          <div className="shop_section">
            <div className="shop_head_bar_align">
              <div className="shop_head_bar_left">
                <h1>Products</h1>
              </div>
              <div className="shop_head_bar_right">
                <Breadcrumb items={itemNav} />
                <div className="mobile_filter_view">
                  {/* <Button type="primary" onClick={showDrawer}> */}
                    {/* Filter */}
                    <FilterOutlined onClick={showDrawer} />
                  {/* </Button> */}
                  <Drawer
                  title="Filters"
                  placement="left"
                  onClose={onClose}
                  open={openFilterDrawer}
                  loading={openFilterDrawerLoading}
                  extra={
                    <Space>
                      <Button 
                        type="primary" 
                        onClick={clearAllFilters} 
                        size="small"
                        disabled={anyFilterApplied ? false : true}
                      >
                        Clear All
                      </Button>
                      <Button 
                        type="primary" 
                        onClick={onClose} 
                        size="small"
                      >
                        Submit
                      </Button>
                    </Space>
                  }
                  bodyStyle={{ padding: "10px 10px 5px 5px" }}
                  >
                    {/* <Tabs
                      tabPosition="left"
                      items={itemFilter}
                    /> */}
                    <div style={{ padding: "10px 0", gap: "5px", display: "flex", flexWrap: "wrap" }}>
                      {categoryId && (
                        <Tag closable onClose={clearCategory}>
                          Category: {finalOne?.find(option => option.value === categoryId)?.label || 'Unknown'}
                        </Tag>
                      )}
                      {Object.entries(categorizeSelectedSpecifications()).map(([category, ids]) =>
                        ids?.map((id) => (
                          <Tag key={id} closable onClose={() => clearSize(id)}>
                            {category}: {SpecificationArray[category]?.find(item => item.value === id)?.label || 'Unknown'}
                          </Tag>
                        ))
                      )}
                      {sortBy && (
                        <Tag closable onClose={clearSort}>
                          Sort: {sortOptions?.find(option => option.key === sortBy)?.label || 'Unknown'}
                        </Tag>
                      )}
                      {/* {(priceRange[0] !== minMax[0] || priceRange[1] !== minMax[1]) && (
                        <Tag closable onClose={clearPrice}>
                          Price: {priceRange?.[0]?.toFixed(2)} - {priceRange?.[1]?.toFixed(2)}
                        </Tag>
                      )} */}
                    </div>
                    <Tabs 
                      tabPosition="left"
                      size="small" 
                      cardPadding={{
                        padding: "0px !important",
                      }}                 
                    >
                      {/* Search Tab */}
                      <Tabs.TabPane 
                        tab="Search" 
                        key="1" 
                        style={{
                        padding: "0px !important",
                        }}
                      >
                        <Input
                          placeholder="Search Products..."
                          className="shop_filter_right_top_input"
                          allowClear
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setIsPaginationAvailable(true);
                            setCurrentPage1(1);
                          }}
                          style={{ width: "100%" }}
                        />
                      </Tabs.TabPane>

                      {/* Category Tab */}
                      <Tabs.TabPane tab="Category" key="2">
                        {finalOne && finalOne.length > 0 ? (
                          finalOne.map((option) => (
                            <Tag.CheckableTag
                              key={option.value}
                              checked={categoryId === option.value}
                              // onChange={() => {
                              //   setCategoryId(option.value);
                              //   setCategoryId1([option.value]);
                              //   setIsPaginationAvailable(true);
                              //   setCurrentPage1(1);
                              // }}
                              onChange={() => handleCategoryChange(option.value)}
                              style={{ 
                                border: "1px solid #D9D9D9",
                                outline: "none",
                                fontSize: "10px !important",
                                marginBottom: "8px", 
                                marginRight: "8px",
                               }}
                            >
                              {option.label}
                            </Tag.CheckableTag>
                          ))
                        ) : (
                          <span>Please select a category</span>
                        )}
                      </Tabs.TabPane>

                      {/* Specification Tab */}
                      <Tabs.TabPane tab="Specification" key="3">
                        {Object.keys(SpecificationArray)
                          .sort((a, b) => {
                            // Sort by specalignment
                            const specA = specification.find((spec) => spec.name === a);
                            const specB = specification.find((spec) => spec.name === b);
                            return (specA?.specalignment || 0) - (specB?.specalignment || 0);
                          })
                          .map((specKey) => (
                            <div key={specKey}>
                              <p style={{fontWeight: "bold"}}>{specKey}:</p>
                              {SpecificationArray[specKey].map((spec) => (
                                <Tag.CheckableTag
                                  key={spec.value}
                                  checked={size?.includes(spec?.value)}
                                  onChange={() => handleSizeChange(spec.value)}
                                  style={{ 
                                    border: "1px solid #D9D9D9",
                                    outline: "none",
                                    fontSize: "10px !important",
                                    marginBottom: "8px", 
                                    marginRight: "8px",
                                   }}
                                >
                                  {spec.label}
                                </Tag.CheckableTag>
                              ))}
                            </div>
                          ))}
                      </Tabs.TabPane>

                      {/* Sort Tab */}
                      <Tabs.TabPane tab="Sort" key="4">
                        {sortOptions.map((option) => (
                          <Tag.CheckableTag
                            key={option.key}
                            checked={sortBy === option.key}
                            onChange={() => handleSortChange(option.key)}
                            style={{ 
                              border: "1px solid #D9D9D9",
                              outline: "none",
                              fontSize: "10px !important",
                              marginBottom: "8px", 
                              marginRight: "8px",
                             }}
                          >
                            {option.label}
                          </Tag.CheckableTag>
                        ))}
                      </Tabs.TabPane>

                      {/* Price Filter Tab */}
                      <Tabs.TabPane tab="Price" key="5">
                        <div className="shop_filter_right_top_left_price" style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: 10 }}>Price: </span>
                          <Slider
                            min={minMax[0]}
                            max={minMax[1]}
                            range={{ draggableTrack: true }}
                            onChange={handlePriceFilter}
                            style={{ width: "100%" }}
                            defaultValue={minMax}
                            tipFormatter={(value) => value?.toFixed(2)}  // Format the value with 2 decimal places
                          />
                        </div>
                      </Tabs.TabPane>
                    </Tabs>
                  </Drawer>
                </div>
              </div>
            </div>
            <div className="shop_filter_align">
              {/* <div className="shop_filter_left">
            <Input
              placeholder="Search Products..."
              allowClear
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
            <Divider />
            <h2>Category</h2>
            <Menu
              mode="inline"
              items={category}
              selectedKeys={[categoryId]}
              onSelect={(e) => {
                setCategoryId(e?.key);
              }}
            />
            <Divider />
            <h2>Filter by Price</h2>
            <Slider
              range={{
                draggableTrack: true,
              }}               
              onChange={handlePriceFilter}                
            />
            <Divider />              
          </div> */}

              <div className="shop_filter_right">
                <div className="shop_filter_right_top">
                  <Row gutter={[8, 8]} style={{ width: "100%" }}>
                        <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={4}>
                          <Input
                            placeholder="Search Products..."
                            className="shop_filter_right_top_input"
                            allowClear
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              setIsPaginationAvailable(true);
                              setCurrentPage1(1);
                              setIsNumber(true);
                            }}
                            style={{
                              // width: "250px",
                              width: "100%",
                            }}
                          />
                        </Col>
                        {/* {console.log("categoryId==>", categoryId)} */}
                        {/* {
                          company_code !== "69488701-2976-4c9e-b1a3-e864db691e76" &&
                          <Select
                          allowClear
                          className="shop_filter_right_top_left_category"
                          label="Category"
                          placeholder="Select Category"
                          mode="inline"
                          value={categoryId ? categoryId : "Categories"}
                          onChange={(value) => {
                            console.log("value", value);
                            setCategoryId(value);
                            setIsPaginationAvailable(true);
                            setCurrentPage1(1);
                          }}
                          style={{
                            width: "170px",
                          }}
                          defaultValue={categoryId ? categoryId : "--Select--"}
                        >
                          {category?.map((item) => (
                            <Option key={item?.key} value={item?.key}>
                              {item?.label}
                            </Option>
                          ))}
                        </Select>} */}
                        {/* {
                          company_code == "69488701-2976-4c9e-b1a3-e864db691e76" && */}
                          <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={4}>
                          {/* {console.log("categoryId1??", categoryId1)} */}
                          {/* {console.log("finalOne??", finalOne)} */}
                            <Cascader
                              allowClear={true}
                              className="shop_filter_right_top_left_category"
                              label="Category"
                              value={categoryId1 ? categoryId1 : categoryId ? categoryId : "--Categories--"}
                              // value={categoryId ? categoryId : (companyCode == "e9237dd0-d58a-426b-b9bb-e89bbee2817a") ? "--Styles--" : "--Categories--"}
                              // value={
                              //   categoryId1
                              //     ? findParentPath(finalOne, categoryId1)
                              //     : categoryId ? categoryId : "Categories"
                              // }
                              onChange={(value) => {
                                // console.log("value???", value);
                              if (value && value.length > 0) {
                                // console.log("i am in ifff")
                              const lastValue = value[value.length - 1];
                                setCategoryId(lastValue);
                                setCategoryId1(value);
                                setIsPaginationAvailable(true);
                                    setCurrentPage1(1);
                                    setIsNumber(true);
                                } else {
                                  // console.log("i am in elseee")
                                  setCategoryId("");
                                  setCategoryId1("")
                                  setcategoryName("")
                                  setIsPaginationAvailable(true);
                                    setCurrentPage1(1);
                                    setIsNumber(true);
                                }
                              }}
                                //  options={finalOne ? finalOne : `Please select category`}
                                options={finalOne ? finalOne.map(option => ({ ...option, label: option.label})) : [{ label: 'Please select category', value: 'placeholder' }]}
                                // placeholder="--Categories--"
                                style={{ width: "100%" }}
                            />
                          </Col>
                  {/* } */}
                  {/* } */}

                        {/* } */}

                  {/* {console.log("finalOne", finalOne)} */}
                  {/* {console.log("finalOne", finalOne)} */}

                        {/* {console.log("finalOne", finalOne)} */}

                        {/* <Cascader
                      allowClear
                      className="shop_filter_right_top_left_category"
                      label="Category"
                        onChange={(value) => {
                      if (value && value.length > 0) {
                      const lastValue = value[value.length - 1];
                        setCategoryId(lastValue);
                        setIsPaginationAvailable(true);
                            setCurrentPage1(1);
                        }
                        }}
                        options={finalOne ? finalOne : `Please select category`}
                      placeholder="Please select category"
                        /> */}
                        {/* {console.log("SpecificationArray", SpecificationArray)} */}
                        {/* Size Select */}
                        <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={4}>
                          <Select
                            allowClear
                            className="shop_filter_right_top_left_category"
                            label="Specification"
                            placeholder="--Select Spec--"
                            mode="multiple"
                            value={size && size.length > 0 ? size : undefined}
                            // value={size ? size : "Select Size"}
                            onChange={handleSizeChange1}
                            // style={{ width: "170px" }}
                            style={{ width: "100%" }}  
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.label.props.children.toLowerCase().includes(input.toLowerCase())
                            }                     
                            // options={Object.keys(SpecificationArray).map((specKey) => ({
                            //   label: <span>{specKey}</span>,
                            //   title: specKey,
                            //   options: SpecificationArray[specKey].map((spec) => ({
                            //     label: <span>{spec.label}</span>,
                            //     value: spec.value,
                            //   })),
                            // }))}
                            options={
                              Object.keys(SpecificationArray)
                                .sort((a, b) => {
                                  // Sort by specalignment
                                  const specA = specification.find((spec) => spec.name === a);
                                  const specB = specification.find((spec) => spec.name === b);
                                  return (specA?.specalignment || 0) - (specB?.specalignment || 0);
                                })
                                .map((specKey) => ({
                                  label: <span>{specKey}</span>,
                                  title: specKey,
                                  options: SpecificationArray[specKey].map((spec) => ({
                                    label: <span>{spec.label}</span>,
                                    value: spec.value,
                                  })),
                                }))
                            }
                          />
                        </Col>
                          {/* {sizeOptions.map((item) => (
                            <Option key={item.key} value={item.key}>
                              {item.label}
                            </Option>
                          ))}
                        </Select> */}
                        {/* Sorting Selection Dropdown */}
                        <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
                          <Select
                            allowClear
                            // defaultValue="sort-by-position"
                            // value={sortBy ? sortBy : "--Sort by position--"}
                            placeholder="--Sort by position--"
                            onChange={handleSortChange}
                            // style={{ width: "170px" }}
                            style={{ width: "100%" }}
                          >
                            {sortOptions.map((option) => (
                              <Option key={option.key} value={option.key}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={4}>
                          <div
                            className="shop_filter_right_top_left_price"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span style={{ marginRight: 10 }}>Price: </span>
                            <Slider
                              min={minMax[0]}
                              max={minMax[1]}
                              range={{
                                draggableTrack: true,
                              }}
                              onChange={handlePriceFilter}
                              style={{
                                // width: "180px",
                                width: "100%",
                              }}
                              defaultValue={minMax}
                              tipFormatter={(value) => value?.toFixed(2)}  // Format the value with 2 decimal places
                            />
                          </div>
                        </Col>
                        {/* {console.log("resultNumbers", resultNumbers)} */}
                        {/* {console.log("size", size)} */}
                        <Col xs={24} sm={24} md={8} lg={5} xl={4} xxl={4} style={{ display: "flex", alignItems: "center" }}>
                          <div className="shop_filter_right_top_left" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            {size != null && size != undefined ? (
                              <p className="shop_filter_right_top_left_totalProducts">
                                Showing of {resultNumbers} results
                                {/* Showing of {totalProducts} results */}
                              </p>
                            ) : ( isNumber == true ?
                              (<p className="shop_filter_right_top_left_totalProducts">
                                Showing of {resultNumbers} results
                                {/* Showing of {totalProducts} results */}
                              </p>) :
                              (
                                <p className="shop_filter_right_top_left_totalProducts">
                                Showing of {totalProducts} results
                                {/* Showing all results */}
                              </p>
                              )
                            )}
                            <p
                              className="shop_filter_right_top_left_clear"
                              onClick={clearAllFilters}
                            >
                              {anyFilterApplied ? "Clear all" : ""}
                            </p>
                          </div>
                        </Col>
                        {/* <Row>
                      <Col span={24}>
                        Sort : &nbsp;
                        <Select
                          onChange={handleSortProduct}
                          placeholder="Select to Sort "
                          value={sortOption}
                        >
                          <Option key="0" value="">
                            Default Sort
                          </Option>
                          <Option key="1" value="hightolow">
                            High to Low
                          </Option>
                          <Option key="2" value="lowtohigh">
                            Low to High
                          </Option>
                          <Option key="3" value="newtoold ">
                            New to Old
                          </Option>
                          <Option key="4" value="oldtonew">
                            Old to New
                          </Option>
                        </Select>
                      </Col>
                    </Row> */}
                        {/* <Segmented
                      options={[
                        {
                          value: "List",
                          icon: <BarsOutlined />,
                        },
                        {
                          value: "grid",
                          icon: <AppstoreOutlined />,
                        },
                      ]}
                      onChange={(value) => handleViewChange(value)}
                    /> */}
                  </Row>
                </div>

                

                

                <br />
                <br />
                {console.log("filteredProducts", filteredProducts)}
                {/* {console.log("paginatedProducts", paginatedProducts)} */}
                {filteredProducts?.length > 0 ? (
                  <div className="product_align_filter">
                    {/* {paginatedProducts.map((product) => ( */}
                    {filteredProducts.map((product) => {
                      // console.log("product===>", product);                    
                      // console.log("wishList===>", wishList);                    
                      // console.log("product?._id===>", product?._id);                    
                      // console.log("hoverIndexes===>", hoverIndexes);                   
                      
                      // const hoverImage = (product?.prospecdocs?.flatMap((item) => item?.product_images) || []).slice(1);
                      const hoverImage = (Array.isArray(product?.prospecdocs) 
                                            ? product.prospecdocs.flatMap((item) => item?.product_images) 
                                            : []
                                        ).slice(1);
                      // const isHovered = hoverStates[product._id] || false;
                      const isHovered = hoverStates[product?.prospecdocs?.[0]?._id] || false;
                      // const hoverIndex = hoverIndexes[product._id] || 0;
                      const hoverIndex = hoverIndexes[product?.prospecdocs?.[0]?._id] || 0;
                      // const isWishlisted = wishList?.some((item) => item?.product_id?._id == product?._id);
                      const isWishlisted = wishList?.some((item) => item?.product_spec?._id == product?.prospecdocs?.[0]?._id);
                      const isWishlisted1 = wishlistLocal?.some((item) => item?.product_spec == product?.prospecdocs?.[0]?._id);
                      // const wishlistId = isWishlisted ? wishList?.find((item) => item?.product_id?._id == product?._id)?._id : null;
                      const wishlistId = isWishlisted ? wishList?.find((item) => item?.product_spec?._id == product?.prospecdocs?.[0]?._id)?._id : null;
                      const wishlistId1 = isWishlisted1 ? wishlistLocal?.find((item) => item?.product_spec == product?.prospecdocs?.[0]?._id)?.product_spec : null;
                      // console.log("wishlistId1", wishlistId1)
                      return (
                      <Link
                        // to={`/product/${product?._id}`}
                        to={`/product/${encodeURIComponent(product?.product_name)}/${product?._id}`}
                        // key={product?._id}
                        key={product?.prospecdocs?.[0]?._id}
                        state={{ productSpecId: product?.prospecdocs?.[0]?._id }}
                        // state={{ size: size }} 
                        style={{ textDecoration: "none" }}
                      >
                        <div 
                          className="product_align_filter_box"
                          // onMouseEnter={() => handleMouseEnter(product._id)}
                          onMouseEnter={() => handleMouseEnter(product?.prospecdocs?.[0]?._id)}
                          // onMouseLeave={() => handleMouseLeave(product._id)}
                          onMouseLeave={() => handleMouseLeave(product?.prospecdocs?.[0]?._id)}
                        >
                           {loginTrue && getUser && <div 
                              className="wishlist-icon"
                              onClick={(e) => {
                                e.preventDefault();
                                isWishlisted ? deleteItem(wishlistId) : addToWiseList(product);
                              }}
                            >
                              {/* <HeartFilled style={{ color: '#000' }} /> */}
                              {isWishlisted ? <HeartFilled style={{ color: '#000' }} /> : <HeartOutlined  style={{ color: '#000' }} />}
                            </div>}

                            {!loginTrue && !getUser && 
                              <div 
                                className="wishlist-icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  isWishlisted1 ? deleteItem1(wishlistId1) : addToWiseList(product);
                                }}
                              >
                                {/* <HeartFilled style={{ color: '#000' }} /> */}
                                {isWishlisted1 ? <HeartFilled style={{ color: '#000' }} /> : <HeartOutlined  style={{ color: '#000' }} />}
                              </div>                            
                            }
                          <div className="p_image" style={{ position: 'relative' }}>                         
                          {/* {
                            console.log("sizeee", product?.prospecdocs?.map((item) => {
                              let defaultImage;
                              
                              if (item.specification.includes(size?.[0])) {
                                defaultImage = item.product_images.find(img => img?._id === item?.default_image);
                              }
                            
                              console.log("item===>", defaultImage);
                              return defaultImage; // Return defaultImage if you need it in the output array
                            }))
                          } */}
                          {/* {console.log("product", product)} */}

            {
               // Find default image based on size specification
              product?.prospecdocs?.map((item) => {                
                if (item.specification.includes(size?.[0])) {
                  defaultImage = item.product_images.find(
                    (img) => img?._id === item?.default_image
                  );
                }
                // console.log("item===>", defaultImage);                
              })
            }
            {/* {product?.prospecdocs?.map((item) => {
               hoverImage = item?.product_images
             })                      
            }    */}
            {/* {console.log("hoverImage", hoverImage)}     */}
            {/* {console.log("Image URL: ", base_url + hoverImage[hoverIndex]?.image_path)}  */}
            {/* {console.log("product?.images", product?.images)}   */}
            {/* {console.log("specDetailsName", specDetailsName)}   */}
            {/* {console.log("specDetailsName1", specDetailsName1)}   */}
            {
              (specDetailsName || specDetailsName1) ? (
                defaultImage ? (
                  <img
                  src={
                     base_url + defaultImage?.image_path
                  }
                  alt="Default"
                />   
                ) : (
                  <img
                    src={ product?.images && base_url + product?.images } 
                    alt="Default" />     
                )
              ) 
              : 
              (
                <>
                  {/* <img
                    src={product?.images && base_url + product?.images}
                    alt="Default"
                    className="product_img"
                  />                                  */}
                  {isHovered ? (
                      <>
                        <img
                          src={base_url + hoverImage[hoverIndex]?.image_path}
                          alt="Hover"
                          className="hover_product_img"
                        />
                        {/* Left and Right Arrows for image navigation */}
                        <LeftOutlined 
                          className="arrow left" 
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // handlePrevImage(product._id, hoverImage.length);
                            handlePrevImage(product?.prospecdocs?.[0]?._id, hoverImage.length);
                          }}             
                        />
                        <RightOutlined 
                          className="arrow right"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // handleNextImage(product._id, hoverImage.length);
                            handleNextImage(product?.prospecdocs?.[0]?._id, hoverImage.length);
                          }}                
                        />
                      </>
                    ) 
                    : 
                    (
                      <img
                        // src={product?.images && base_url + product?.images}
                        src={product?.prospecdocs?.[0]?.product_images?.[0]?.image_path && base_url + product?.prospecdocs?.[0]?.product_images?.[0]?.image_path}
                        alt="Default"
                        className="product_img"
                      />
                    )}
                      </>                 
                    )
            }                
               
              
                          
                            {/* <img
                              src={
                                product?.images && base_url + product?.images
                              }
                              alt="Default"
                            />                            */}
                          </div>
                          <div className="p_content">
                            <h2 style={{ color: "black" }}>
                              {product?.product_name}
                            </h2>
                            {/* {console.log("product--->", product)} */}
                            {/* {console.log("product--->show_mrp", product?.prospecdocs[0]?.show_mrp)} */}
                            {/* {console.log("product--->show_sp", product?.prospecdocs[0]?.show_sp)} */}
                            {(product?.prospecdocs[0]?.show_mrp == true && product?.prospecdocs[0]?.show_sp == false) ?
                            (
                              <div className="price">
                                {/* {console.log("prooooo==> i am in")} */}
                                <div className="sp">
                                  <span style={{fontSize: "small"}}>M.R.P:</span>                                  
                                  {product?.prospecdocs && product?.prospecdocs?.length > 0
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                Number(product?.prospecdocs[0]?.mrp) +
                                                Number(
                                                  (
                                                    (product?.prospecdocs[0]?.mrp *
                                                      product?.taxdocs[0]?.tax_percentage) / 100
                                                  ).toFixed(2)
                                                )
                                              )
                                            : (
                                                Number(product?.prospecdocs[0]?.mrp) +
                                                Number(
                                                  (
                                                    (product?.prospecdocs[0]?.mrp *
                                                      product?.taxdocs[0]?.tax_percentage) / 100
                                                  ).toFixed(2)
                                                )
                                              ).toFixed(isDecimal?.Decimal_points))
                                        : 0
                                    }
                                    
                                </div>
                              </div>
                            )
                            : (product?.show_common_mrp == true && product?.show_common_sp == false) ?
                            (
                              <div className="price">
                                <div className="sp">
                                  
                                  <span style={{fontSize: "small"}}>M.R.P:</span>
                                  {product?.common_mrp > 0
                                    ? styles?.currency +  
                                      (isDecimal?.isDecimal === false
                                        ? Math.round(
                                            Number(product?.common_mrp) +
                                            Number(
                                              (
                                                (product?.common_mrp *
                                                  product?.taxdocs[0]?.tax_percentage) / 100
                                              ).toFixed(2)
                                            )
                                          )
                                        : (
                                            Number(product?.common_mrp) +
                                            Number(
                                              (
                                                (product?.common_mrp *
                                                  product?.taxdocs[0]?.tax_percentage) / 100
                                              ).toFixed(2)
                                            )
                                          ).toFixed(isDecimal?.Decimal_points))
                                    : (
                                      product?.prospecdocs && product?.prospecdocs?.length > 0
                                        ? styles?.currency +
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(
                                                Number(product?.prospecdocs[0]?.mrp) +
                                                Number(
                                                  (
                                                    (product?.prospecdocs[0]?.mrp *
                                                      product?.taxdocs[0]?.tax_percentage) / 100
                                                  ).toFixed(2)
                                                )
                                              )
                                            : (
                                                Number(product?.prospecdocs[0]?.mrp) +
                                                Number(
                                                  (
                                                    (product?.prospecdocs[0]?.mrp *
                                                      product?.taxdocs[0]?.tax_percentage) / 100
                                                  ).toFixed(2)
                                                )
                                              ).toFixed(isDecimal?.Decimal_points))
                                        : 0
                                    )}
                                    
                                </div>
                              </div>
                            )
                            :
                            (<div className="price">
                              <div className="sp">
                                {/* {product?.prospecdocs &&
                                product?.prospecdocs?.length > 0
                                  ? styles?.currency +
                                    Number(Number(product?.prospecdocs[0]?.sp) +
                                      Number(
                                        (
                                          (product?.prospecdocs[0]?.sp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                          100
                                        ).toFixed(2)
                                      )).toFixed(2)
                                  : 0} */}
                                  <span></span>
                                  {/* {console.log("isDecimal?.Decimal_points", isDecimal?.Decimal_points)} */}
                                  {product?.prospecdocs[0]?.sp > 0
                                      ? styles?.currency + 
                                        (isDecimal?.isDecimal === false
                                          ? Math.round(Number(product?.prospecdocs[0]?.sp) +
                                              Number(
                                                (
                                                  (product?.prospecdocs[0]?.sp *
                                                    product?.taxdocs[0]?.tax_percentage) / 100
                                                ).toFixed(2)
                                              ))
                                          : (Number(product?.prospecdocs[0]?.sp) +
                                              Number(
                                                (
                                                  (product?.prospecdocs[0]?.sp *
                                                    product?.taxdocs[0]?.tax_percentage) / 100
                                                ).toFixed(2)
                                              )).toFixed(isDecimal?.Decimal_points))
                                        :(product?.common_sp > 0
                                        ? styles?.currency + 
                                          (isDecimal?.isDecimal === false
                                            ? Math.round(Number(product?.common_sp) +
                                                Number(
                                                  (
                                                    (product?.common_sp *
                                                      product?.taxdocs[0]?.tax_percentage) / 100
                                                  ).toFixed(2)
                                                ))
                                            : (Number(product?.common_sp) +
                                                Number(
                                                  (
                                                    (product?.common_sp *
                                                      product?.taxdocs[0]?.tax_percentage) / 100
                                                  ).toFixed(2)
                                                )).toFixed(isDecimal?.Decimal_points))
                                            : 0
                                            )
                                  }
                              </div>
                              <div className="mrp">
                                {/* {product?.prospecdocs &&
                                product?.prospecdocs?.length > 0
                                  ? styles?.currency +
                                    Number(product?.prospecdocs[0]?.mrp +
                                      Number(
                                        (
                                          (product?.prospecdocs[0]?.mrp *
                                            product?.taxdocs[0]
                                              ?.tax_percentage) /
                                          100
                                        ).toFixed(2)
                                      )).toFixed(2)
                                  : 0} */}
                                  <span>M.R.P:</span>
                                {product?.common_mrp > 0
                                  ? styles?.currency +
                                    (isDecimal?.isDecimal === false
                                      ? Math.round(
                                          Number(product?.common_mrp) +
                                          Number(
                                            (
                                              (product?.common_mrp *
                                                product?.taxdocs[0]?.tax_percentage) / 100
                                            ).toFixed(2)
                                          )
                                        )
                                      : (
                                          Number(product?.common_mrp) +
                                          Number(
                                            (
                                              (product?.common_mrp *
                                                product?.taxdocs[0]?.tax_percentage) / 100
                                            ).toFixed(2)
                                          )
                                        ).toFixed(isDecimal?.Decimal_points))
                                  : (
                                    product?.prospecdocs && product?.prospecdocs?.length > 0
                                      ? styles?.currency +
                                        (isDecimal?.isDecimal === false
                                          ? Math.round(
                                              Number(product?.prospecdocs[0]?.mrp) +
                                              Number(
                                                (
                                                  (product?.prospecdocs[0]?.mrp *
                                                    product?.taxdocs[0]?.tax_percentage) / 100
                                                ).toFixed(2)
                                              )
                                            )
                                          : (
                                              Number(product?.prospecdocs[0]?.mrp) +
                                              Number(
                                                (
                                                  (product?.prospecdocs[0]?.mrp *
                                                    product?.taxdocs[0]?.tax_percentage) / 100
                                                ).toFixed(2)
                                              )
                                            ).toFixed(isDecimal?.Decimal_points))
                                      : 0
                                  )}
                              </div>
                            </div>)}
                            <div>
                              {/* <Button size="medium">View Detail</Button> */}
                              <Button  color="default" variant="solid" size="medium">Quick Buy</Button>
                            </div>
                          </div>
                        </div>
                      </Link>
                      )
                    })}
                  </div>
                ) : (
                  <Empty 
                    image={DataNotFound}
                    description="Coming Soon..."
                    imageStyle={{
                      // height: 400,
                      display: "flex",
                      justifyContent: "center",                      
                    }}
                    style={{
                      backgroundColor: "#f5f5f5",
                      fontSize: "20px",
                      padding: "100px 0px",
                      // opacity: "0.01",
                    }}
                  />
                  // <div></div>
                )}

                <div className="shop_pagination">
                  {/* {console.log("isPaginationAvailable", isPaginationAvailable)} */}
                  {/* {console.log("filteredProducts", filteredProducts?.length)} */}
                  {isPaginationAvailable == false && filteredProducts?.length > 0 &&(
                    <Pagination
                      Current={currentPage}
                      size="small"
                      onChange={handlePaginationChange}
                      pageSizeOptions={[10, 20, 30]}
                      showSizeChanger
                      showLessItems={totalPageCount <= 2}
                      total={totalProducts}
                    />
                  )}
                  {/* Add Pagination component */}
                  {/* {console.log("currentPage1 ==>", currentPage1)} */}
                  {/* {console.log("pageSize1 ==>", pageSize1)} */}
                  {isPaginationAvailable == true && (
                    // <Pagination
                    //   current={currentPage1}
                    //   pageSize={pageSize1}
                    //   total={filteredProducts.length}
                    //   onChange={handlePageChange1}
                    //   showSizeChanger
                    //   pageSizeOptions={[10, 20, 50]}
                    // />
                    <div></div>
                  )}
                </div>

                <Modal
                  open={open}
                  onCancel={handleCancel}
                  onOk={handleOk}
                  footer={null}
                  title="Login"
                  width={400}
                >
                  <Form
                    layout="vertical"
                    form={loginForm}
                    name="Login_Form"
                    onFinish={login}
                  >
                    <Form.Item
                      label="Enter Mobile number"
                      name="buyer_number"
                      rules={[
                        {
                          required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "Please enter your Mobile number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Enter Password"
                      name="buyer_password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loginLoading}
                        className="primary_btn"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                  <Or>or</Or>

                  <NewAccount>
                    <Link to="/register">Click Here</Link>
                    to Register a Account
                  </NewAccount>
                </Modal>
                <Modal
                  open={open1}
                  onCancel={handleCancel1}
                  onOk={handleOk1}
                  footer={null}
                  title="Add Address"
                  width={300}
                >
                  <Button type="primary" onClick={() => navigate("/add-address")}>
                    {" "}
                    Go to Address Screen{" "}
                  </Button>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      )}
    </ShopSection>
  );
};

export default Shop;

const ShopSection = styled.section`
  /* margin: ${({ company_code }) => (company_code == '6451e84cb9cf0e72dc18ca1b12o24a4clh780tgg' ? '100px 0' : '0px 0')}; */
  margin: 60px 0;
  width: 100%;
  display: inline-block;
  position: relative;
  .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding: 0;
    padding-left: 0 !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
  .ant-menu-light
    > .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-submenu-title:active {
    background-color: transparent;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu-title {
    margin: 0;
    width: 100%;
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical,
  .ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0px;
  }
  .ant-menu-inline .ant-menu-submenu,
  .ant-menu-vertical .ant-menu-submenu,
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
  .ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: transparent;
  }
  .ant-menu .ant-menu-submenu,
  .ant-menu .ant-menu-submenu-inline {
    background: transparent;
  }
  .shop_head_bar_left {
  }

  .shop_section {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .shop_head_bar_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 0 0 50px; */
  }
  .shop_filter_align {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  .shop_filter_left {
    width: 21%;
    display: inline-block;
    border: 1px solid #f5f5f5;
    border-radius: 7px;
    padding: 25px;
  }
  .shop_filter_right {
    width: 100%;
    display: inline-block;
  }
  .shop_filter_left h2 {
    font-size: 19px;
    margin: 0 0 25px;
  }

  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px 10px;
  }
  .ant-divider {
    margin: 35px 0;
  }
  .shop_filter_right_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .shop_filter_right_top_left {
      display: flex;
      align-items: center;
      .shop_filter_right_top_left_totalProducts {
        /* width: 180px; */
        margin-bottom: 0px;
      }
      .shop_filter_right_top_left_clear {
        cursor: pointer;
        color: #ff0000;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 0px !important;
      }
    }
  }

  /* .product_align_filter .product_align_filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 25px 0 50px 0;
  } */   

    .p_image {
  position: relative;
  overflow: hidden;
}

.product_img,
.hover_product_img {
  width: 100%;
  transition: opacity 0.3s ease;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  color: #fff;
  padding: 0 10px;
  cursor: pointer;
  opacity: 0; /* Hide by default */
  transition: opacity 0.3s ease;
}

.p_image:hover .arrow {
  opacity: 1; /* Show arrows on hover */
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

  .product_align_filter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* gap: 20px; */
    gap: 10px;
    margin: 25px 0 50px 0;
    justify-content: center;
  }

  .product_align_filter_List {
    width: 100%;
    gap: 20px;
  }

  .product_align_filter_box_List {
    border: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
  }

  .p_image_List img {
    width: 35%;
    height: auto;
    border-radius: 4px;
  }

  .p_content_List {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    list-style-type: none;
  }

  .price_List {
    display: flex;
    gap: 20px;
    line-height: 60px;
    margin-top: 8px;
  }

  .price_List .sp {
    font-weight: bold;
    font-size: 20px;
    text-decoration: none;
    color: #000;
    font-family: ${font?.bold};
  }

  .price_List .sp {
    color: #000;
    font-size: 18px;
    text-decoration: none;
  }

  .price_List .mrp {
    text-decoration: line-through;
    color: #c9c7c7;
  }

  .product_align_filter_box {
    width: 99%;
    /* height: 470px; */
    height: 100%;
    border-radius: 7px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
    position: relative;
  }
  .wishlist-icon {
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 24px;
  /* color: #888; */
  cursor: pointer;
  transition: color 0.2s ease;
  z-index: 2;
  }

  .wishlist-icon:hover {
    color: #000;
  }
  .p_image {
    width: 100%;
    height: 70%;
    display: inline-block;
    background-color: #f5f5f5;
  }
  .p_image img {
    width: 100%;
    /* height: 100%; */
    aspect-ratio: 2 / 3;
    /* aspect-ratio: 3 / 4; */
    object-fit: contain;    
  }

  .p_content {
    /* padding: 15px 18px; */
    padding: 15px 18px 0px 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    text-decoration: none;
    height: 30%;
  }
  .p_content h2 {
    font-size: 17px;
    font-family: ${font?.bold};
    color: ${font?.color};
    text-decoration: none;
    height: 25%;
  }
  .price {
    display: flex;
    align-items: flex-end;
    /* align-items: center; */
    gap: 8px 11px;
  }
  .price div:nth-child(1) {
    color: ${theme?.color};
    font-size: 20px;
    font-weight: 600;
    /* text-decoration: none; */
  }
  .price div:nth-child(2) {
    font-size: 12px;
    color: ${theme?.gray};
    text-decoration: line-through;
    margin-bottom: 1%;
  }
  .p_content button {
    padding: 2px 9px;
    height: auto;
    font-size: 14px;
    /* background-color: ${theme?.color}; */
    /* color: #fff; */
    /* border: none; */
  }
  .p_content button * {
    font-size: 14px;
  }
  .shop_pagination {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .mobile_filter_view {
    display: none;
  }

  .shop_head_bar_right {
    display: flex;
    align-items: center;  
    gap: 0px 10px; 
  }

  img, svg{
    vertical-align: unset !important;
  }

  .ant-select .ant-select-selection-placeholder {
    color: #000 !important; /* Change the color */
    /* font-style: italic;  */
    font-size: 14px; 
    opacity: 1; /* Control the transparency */
  }

  .ant-input::placeholder {
    color: #000; /* Custom color */
    /* font-style: italic;  */
    font-size: 14px; /* Custom font size */
    opacity: 1; /* Full opacity */
  }

  /* :where(.css-dev-only-do-not-override-14wwjjs).ant-drawer .ant-drawer-body {
    padding: 0 !important;
  } */

  @media screen and (max-width: 1024px) {
    .product_align_filter {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 768px) {
    .shop_filter_right_top {
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      gap: 20px;
    }
    .product_align_filter {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    .shop_filter_align {
      flex-direction: column;
      gap: 20px;
    }

    .shop_filter_right_top {
      /* display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px; */
      display: none;
    }

    .mobile_filter_view {
      display: block;
    }

    .shop_filter_right_top_left_price,
    .shop_filter_right_top_input {
      width: 150px;
    }

    .shop_filter_right_top_left {
      width: 180px;
    }

    .shop_filter_left,
    .shop_filter_right {
      width: 100%;
    }

    .product_align_filter {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 431px) {
    .shop_filter_right_top {
      /* display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px; */
      display: none;
    }
    .mobile_filter_view {
      display: block;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      width: 300px;
    }
  }


  @media screen and (max-width: 425px) {
    .shop_filter_right_top {
      /* display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px; */
      display: none;
    }
    .mobile_filter_view {
      display: block;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      width: 300px;
    }
  }

  @media screen and (max-width: 375px) {
    .shop_filter_right_top {
      /* display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px; */
      display: none;
    }
    .mobile_filter_view {
      display: block;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      width: 300px;
    }
  }

  @media screen and (max-width: 320px) {
    .shop_filter_right_top {
      /* display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px; */
      display: none;
    }
    .mobile_filter_view {
      display: block;
    }
    .product_align_filter {
      grid-template-columns: repeat(1, 1fr);
    }
    .shop_filter_right_top_input {
      width: 100% !important;
    }
    .shop_filter_right_top_left_price {
      width: 100% !important;
    }
    .shop_filter_right_top_left_category {
      width: 100% !important;
    }
    .shop_filter_right_top_left {
      /* width: 250px; */
      width: 100%;
    }
  }
`;

const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
  .Forgot_Password {
    width: 100%;
    text-align: right;
    margin: 0;
    Link {
      color: #000;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    background: ${styles.background};
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
