import { createSlice } from '@reduxjs/toolkit';

const cartLocalLength = createSlice({
  name: 'cart',
  initialState: {
    localCartLength: 0, // Tracks the number of items in the local cart
  },
  reducers: {
    updateLocalCartLength: (state, action) => {
      state.localCartLength = action.payload; // Update the cart length
    },
  },
});

export const { updateLocalCartLength } = cartLocalLength.actions;
export default cartLocalLength.reducer;