import React from 'react'
import styled from "styled-components";
import API from "../../ApiService/ApiService";
import image1 from "../../Assets/Images/OneWear/OneWearService1.svg";
import image2 from "../../Assets/Images/OneWear/OneWearService2.svg";
import image3 from "../../Assets/Images/OneWear/OneWearService3.svg";
import '../../Assets/Fonts/fonts.css';


const OurServices = ({content_all}) => {
  const api = new API();

  return (
    <BannerAlign>
    <div className="home_wrapper">
      <div className="HCFurniture_Main">
        <div className="Left_Section">
          <img src={api.rootUrl1 + content_all?.onewear_service_image1?.image} alt="banner1" />
          <div className="overlay">
            {content_all?.onewear_service_description1}
            </div>
        </div>
        <div className="middle_Section">
          <img src={api.rootUrl1 + content_all?.onewear_service_image2?.image} alt="banner2" />
            <div className="overlay">
                {content_all?.onewear_service_description2}
            </div>
        </div>
        <div className="Right_Section">
          <img src={api.rootUrl1 + content_all?.onewear_service_image3?.image} alt="banner2" />
          <div className="overlay">
            {content_all?.onewear_service_description3}
            </div>   
        </div>
      </div>
    </div>
  </BannerAlign>
  )
}

export default OurServices;

const BannerAlign = styled.div`
background-color: #ECECEC;


.HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
    width: 80%;
    height: 30vh;
    margin: auto;  
    gap: 20px;    
  }

  .Left_Section,
  .Right_Section,
  .middle_Section {
    /* width: 20%; */
    width: 30%;
    /* height: 100%; */
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .Left_Section img,
  .Right_Section img,
  .middle_Section img {
    width: 48px;
    height: 39px;
    /* border: 1px solid red; */
  }

  .overlay{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    width: 80%;
  }

   /* 1024px and below */
   @media (max-width: 1024px) {
    .overlay {
      font-size: 14px;
    }
  }

  /* 991px and below */
  @media (max-width: 991px) {
    .overlay {
      font-size: 14px;
    }
  }

  /* 853px and below */
  @media (max-width: 853px) {
    .overlay {
      font-size: 14px;
    }
  }

  /* 840px and below */
  @media (max-width: 840px) {  

    .overlay {
      font-size: 14px;
    }
  }

  /* 768px and below */
  @media (max-width: 768px) {
    .overlay {
      font-size: 13px;
    }

    .Left_Section,
  .Right_Section,
  .middle_Section {
    width: 31%;
  }
  }

  /* 540px and below */
  @media (max-width: 540px) {
    .overlay {
      font-size: 12px;
    }

    .HCFurniture_Main {
      flex-direction: column;
      height: auto;
      padding-top: 40px;
    }

    .Left_Section,
    .middle_Section,
    .Right_Section {
      width: 100%;
      /* text-align: center; */
    }

    .Left_Section img,
    .Right_Section img,
    .middle_Section img {
      /* width: 80%; */
    }
  }

  /* 430px and below */
  @media (max-width: 430px) {
    .overlay {
      font-size: 11px;
    }
  }

  /* 425px and below */
  @media (max-width: 425px) {
    .overlay {
      font-size: 11px;
    }
  }

  /* 412px and below */
  @media (max-width: 412px) {
    .overlay {
      font-size: 10px;
    }
  }

  /* 390px and below */
  @media (max-width: 390px) {
    .overlay {
      font-size: 10px;
    }
  }

  /* 325px and below */
  @media (max-width: 325px) {
    .overlay {
      font-size: 9px;
    }
  }

  /* 280px and below */
  @media (max-width: 280px) {
    .overlay {
      font-size: 8px;
    }
  }

  /* 220px and below */
  @media (max-width: 220px) {
    .overlay {
      font-size: 7px;
    }
  }

`;