import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {Button,Tooltip} from "antd";
import image from "../../Assets/Images/GirlsHub Template/Trendingofmonth.png";
import API from "../../ApiService/ApiService";

let user = "";

const TrendingOfMonth1 = ({trending, content_all}) => {
  // console.log("trending", trending)
  // console.log("content_all==>trending", content_all)
  const api = new API();

  const Banner = [
    {
      image: image,
    },
    {
      image: image,
    },
    {
      image: image,
    },
  ];

  return (
    <TrendingOfMonthStyle>
      <div className="home_wrapper">
        <div className="Trending_Main">
          <div className="Left_Section">
            <div className="Left_Title">
              <span className="Title">{content_all?.title_tom}</span>
            </div>
            <div className="Left_Description">
              <span className="description">{content_all?.tom_description}</span>
            </div>
            <div className="Left_Btn">
              <a href={`/${content_all?.product_tom_link}`}>
                <Tooltip placement="top" title="View More">
                <Button className="Button"  >
                {content_all?.btn_txt_tom}{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="9"
                  viewBox="0 0 33 9"
                  fill="none"
                >
                  <path
                    d="M32.8536 4.69047C33.0488 4.49521 33.0488 4.17862 32.8536 3.98336L29.6716 0.80138C29.4763 0.606118 29.1597 0.606118 28.9645 0.80138C28.7692 0.996642 28.7692 1.31322 28.9645 1.50849L31.7929 4.33691L28.9645 7.16534C28.7692 7.3606 28.7692 7.67719 28.9645 7.87245C29.1597 8.06771 29.4763 8.06771 29.6716 7.87245L32.8536 4.69047ZM0.5 4.83691H32.5V3.83691H0.5V4.83691Z"
                    fill="black"
                  />
                </svg>
              </Button>
                </Tooltip>
              </a>
            </div>
           
          </div>
          <div className="Right_Section" >
            {content_all?.product_tom?.map((item, index) => (
              <Link to={`/product/${encodeURIComponent(item?.label)}/${item?.value}`} key={index} style={{ textDecoration: "none" }}>
                <div
                className="Right_Main"
                key={index}
                style={{
                  // border: "1px solid red",
                  lineHeight: "30px",
                }}                
                >
                  <div className="Right_Image">
                    <img src={api?.rootUrl1 + item?.image} alt="Banner" title="Product" />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </TrendingOfMonthStyle>
  );
};

export default TrendingOfMonth1;

const TrendingOfMonthStyle = styled.div`
  .home_wrapper {
    padding: 60px 40px;
  }

  .Trending_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }

  .Trending_Main .Left_Section {
    width: 30%;
  }

  .Trending_Main .Right_Section {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  .Trending_Main .Left_Section .Left_Title {
    color: #000;
    font-family: Moneta;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 20px;
    text-align: left;
  }

  .Trending_Main .Left_Section .Left_Description {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    padding-bottom: 10px;
  }

  .Trending_Main .Left_Section .Left_Btn .Button {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    border: none;
    outline: none;
    text-align: left;
  }

  .Right_Image{
    background: #f8f9fa;
  }

  .Right_Image img{
    width: 100%;
    height: auto;
    aspect-ratio: 2 / 3;
    object-fit: contain;
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    /* border: 1px solid red; */
    max-width: 1024px;    
    .Trending_Main .Left_Section .Left_Title {
      font-size: 30px;
    }
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    max-width: 912px;
    .Trending_Main {
      flex-direction: column;
    }    
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 911px) {
    /* max-width: 768px; */
    .Trending_Main {
      flex-direction: column;      
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    max-width: 425px;
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    max-width: 414px;
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }    
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    max-width: 412px;
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }    
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    max-width: 390px;
    .Trending_Main {
      flex-direction: column;
    }    
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    max-width: 375px;
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 360px;
    .Trending_Main {
      flex-direction: column;
    }    
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    /* max-width: 320px; */
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;
    .Trending_Main {
      flex-direction: column;
    }
    .Left_Section {
      width: 100% !important;
      text-align: center;
    }
    .Left_Section .Left_Title {
      text-align: center !important;
    }
    .Left_Section .Left_Title .Title {
      font-size: 30px;
    }
    .Left_Section .Left_Description {
      text-align: center !important;
    }
    .Right_Section {
      width: 100% !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
`;
