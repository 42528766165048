import { createSlice } from '@reduxjs/toolkit';

const wishlistLocalLength = createSlice({
  name: 'wishlist',
  initialState: {
    localWishlistLength: 0, // Tracks the number of items in the local cart
  },
  reducers: {
    updateLocalWishlistLength: (state, action) => {
      state.localWishlistLength = action.payload; // Update the cart length
    },
  },
});

export const { updateLocalWishlistLength } = wishlistLocalLength.actions;
export default wishlistLocalLength.reducer;