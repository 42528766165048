import { createSlice } from "@reduxjs/toolkit";

const priceDecimalsSlice = createSlice({
  name:'pricedecimal',
  initialState:{
    pricedecimal:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    pricedecimalGetAllStart:(state)=>{
      state.isFetching = true;
    },
    pricedecimalGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.pricedecimal = action.payload;
    },
    pricedecimalGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {pricedecimalGetAllStart,pricedecimalGetAllSuccess,pricedecimalGetAllFail} = priceDecimalsSlice?.actions;
export default priceDecimalsSlice.reducer;



