import React from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import API from "../../ApiService/ApiService";
// import image1 from "../../Assets/Images/OneWear/OneWearOurStory1.jpg";
// import image2 from "../../Assets/Images/OneWear/OneWearOurStory2.jpg";
import '../../Assets/Fonts/fonts.css';

const overlayStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(80%, 15%)",
};

const slideStyle = {
  position: "relative",
};

const OurStory = ({ content_all }) => {
  const api = new API();
  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section" 
          // style={slideStyle}
          >
            {/* <Link to={`/${content_all?.right_banner_img_top_link}`}> */}
            <div className="overlay">
              <img src={api.rootUrl1 + content_all?.onewear_ourstory_images?.[0]?.image} alt="banner2" />
            </div>
            <div className="overlay1" 
            // style={overlayStyle}
            >
              <img src={api.rootUrl1 + content_all?.onewear_ourstory_images?.[1]?.image} alt="banner2" />
            </div>           
          </div>
          <div className="Right_Section">
            <div className="overlay2">              
              {/* <p>
                {content_all?.onewear_ourstory_sub_description1}
              </p> */}
              <h1>{content_all?.onewear_ourstory_main_description}</h1>
              <p>
                {content_all?.onewear_ourstory_sub_description2}
              </p>              
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button 
                  className="custom-button" 
                  style={{ 
                    borderRadius: 0, 
                    fontFamily: 'Montserrat' 
                    }}
                    onClick={() => window.location.href = `${content_all?.onewear_story_btn_link}`}
                    >
                    {/* SIGN UP */}
                    {content_all?.onewear_story_btn_txt}
                  </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default OurStory;

const BannerAlign = styled.div`
  .home_wrapper {
    display: flex;
    background-color: #e96027;
    padding: 60px 0px;
    width: 100%;
    height: 100%;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;
  }

  .Left_Section {
    width: 50%;
    position: relative; /* Applying slideStyle here */
  }

  /* 1st image with 2:3 aspect ratio */
  .overlay {
    width: 75%;
    padding-top: 100%; 
    position: relative;
    overflow: hidden;
  }

  .overlay img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* 2nd image with 1:1 aspect ratio */
  .overlay1 {
    width: 50%;
    padding-top: 50%; 
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(100%, 15%); /* Applying overlayStyle here */
    overflow: hidden;
  }

  .overlay1 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .Right_Section {
    width: 50%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
  }

  .Right_Section .overlay2 {
    display: flex;
    flex-direction: column;
    align-items: self-end;
    /* gap: 50px; */
    gap: 20px;
    /* margin-top: 10%; */
    p {
      font-family: Inter;
      font-size: 16px;
      text-align: justify;
      text-align-last: right;
      color: #ffffff;
      width: 80%;
    }

    h1 {
      font-family: Inter;
      font-size: 50px;
      font-weight: 900;
      text-align: right;
      color: #ffffff;
    }
  }

  @media screen and (max-width: 1024px) {
    .Right_Section .overlay2 {
    display: flex;
    flex-direction: column;
    align-items: self-end;
    gap: 20px;
    /* gap: 50px; */
    /* margin-top: 10%; */
    p {
      font-family: Inter;
      font-size: 16px;
      text-align: justify;
      text-align-last: right;
      color: #ffffff;
      width: 90%;
    }

    h1 {
      font-family: Inter;
      font-size: 42px;
      font-weight: 900;
      text-align: right;
      color: #ffffff;
    }
  }
  }

  @media (max-width: 768px) {
    .HCFurniture_Main {
      flex-direction: column;
      height: auto;
    }

    .Left_Section,
    .Right_Section {
      width: 100%;
    }

    .overlay1 {
      left: 75%;
      top: 10%;
      transform: translate(-50%, 0); /* Adjusting for smaller screens */
    }

    .Right_Section .overlay2 {
      padding-top: 5%;
      text-align: center !important;
      align-items: center !important;
      gap: 10px;
      p {
        font-size: 12px;
        text-align: center !important;
        text-align-last: center;
      }
      h1 {
        font-size: 36px;
        text-align: center !important;
      }
    }

    .custom-button {
      margin: auto;
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    .overlay {
      padding-top: 100%;
    }

    .overlay1 {
      left: 75%;
      top: 10%;
    }

    .Right_Section .overlay2 {
      padding-top: 5%;
      gap: 10px;
      p {
        font-size: 12px;
        text-align-last: center;
      }
      h1 {
        font-size: 26px;
      }
    }

    .custom-button {
      margin: auto;
      width: 100%;
    }
  }

  @media (max-width: 320px) {
    .overlay {
      padding-top: 100%;
    }

    .overlay1 {
      left: 75%;
      top: 10%;
    }

    .Right_Section .overlay2 {
      padding-top: 5%;
      p {
        font-size: 12px;
      }
      h1 {
        font-size: 22px;
      }
    }

    .custom-button {
      margin: auto;
      width: 100%;
    }
  }
`;


