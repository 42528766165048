import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import axios from "axios";
import {
  Tag,
  Button,
  Divider,
  Popconfirm,
  message,
  Empty,
  Input,
  Spin,
  Form,
  Card,
  Modal,
  Row,
  Col,

} from "antd";
import { DeleteOutlined, InfoOutlined, LoadingOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Default from "../Assets/Images/Agri/b2.jpg";
import styles from "../ApiService/Data";
import "../../src/Assets/Css/style.css";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";
// import { cartSuccess } from "../Redux/slices/cart";
import {
    userGetAllStart,
    userGetAllSuccess,
    userGetAllFail,
  } from "../Redux/slices/user";
  import {
    addressGetAllFail,
    addressGetAllStart,
    addressGetAllSuccess,
  } from "../Redux/slices/userAddress";
  import {
    registerGetAllStart,
    registerGetAllFail,
    registerGetAllSuccess,
  } from "../Redux/slices/user";
  import { cartStart, cartFailure, cartSuccess } from "../Redux/slices/cart";
  import { updateLocalCartLength } from '../Redux/slices/cartLocalLength';
  import crud_service from "../ApiService/CrudService";
  import { company_code, base_url, api_website, api_admin } from "../config";

const CartGuest = () => {

    const api = new API();
    const api1 = new crud_service();
    const [issSaving, setIsSaving] = useState(false);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user")) || "";
  const token = localStorage.getItem("token") || "";
  const CartLocal = JSON.parse(localStorage.getItem('cartLocal'));
  console.log("CartLocal", CartLocal);
  const [cartUpdate, setCartUpdate] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [specData, setSpecData] = useState([]);
  const [isstoken, setIssToken] = useState("");
  const [otpSent, setOtpSent] = useState(false); // Tracks if OTP has been sent
  const [resendCounter, setResendCounter] = useState(30);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const login = localStorage.getItem("login") || false  
  const [loginForm] = Form.useForm();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  const [isLoginPassword, setIsLoginPassword] = useState(false);
  let userAddressList = useSelector(
    (state) => state?.userAddress?.address?.data
  );

  console.log("userAddressList", userAddressList);

  let isToken = ""

  const data4Checkout = {
    buyer_id: user?._id,
  };

  useEffect(() => {
    getUserAddress();
  }, []); 
  
  useEffect(() => {
    getAllCartss();
  }, []);
  const getAllCartss = () => {
    if (user) {
      api.getcart(data4Checkout).then((res) => {
        // console.log("res--->", res);
        if (res.response?.data?.status === 500) {
          message.error(res?.response?.data?.message);
          // navigate("/delivery-address");
          setCart({
            cart_item: [],
          });
        } else {
          setCart(res?.data?.data?.cart_item);
          setLoading(false);
          dispatch(cartSuccess(res?.data));
        }
      });
    }
  };

  const totalAmount = specData?.reduce((total, item) => {
    // console.log("item", item);
    let productAmount = 0;
    
    if (item?.show_mrp === true && item?.show_sp === false) {
      productAmount = (item?.mrp || 0) + 
                      ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100));
    } else if (item?.product_id?.show_common_mrp === true && item?.product_id?.show_common_sp === false) {
      productAmount = (item?.product_id?.common_mrp || 0) + 
                      ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100));
    } else {
      productAmount = (item?.sp || item?.product_id?.common_sp || 0) + 
                      ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100));
    }
  
    // Return the total amount including quantity
    return total + (productAmount * (item?.qty || 1));
  }, 0);
  
  useEffect(() => {    
    CartLocal.map((item) => {
      getSingleProduct(item?.product_id, item?.product_spec, item?.qty);
    })
  }, []);

  const getSingleProduct = (Id, specId, quantity) => {
    api.getSingleProduct(Id).then((res) => {  
      
      const filteredSpec = res?.data?.data?.productspec?.filter((spec) => spec._id === specId);
      
      // Add qty to the filtered spec items
    const updatedSpec = filteredSpec?.map((spec) => ({
        ...spec,
        qty: quantity
      }));
  
      setSpecData((prevSpecData) => [
        ...prevSpecData,
        ...updatedSpec || []
      ]);           
    });
  };

//   console.log("specData", specData);

  const getUserAddress = async () => {
    setIsLoading(true);
    await api.getAll(
      dispatch,
      [addressGetAllStart, addressGetAllSuccess, addressGetAllFail],
      "buyerbillingdetails"
    );
    
    setIsLoading(false);
  };

  const handleCartUpdate = (specId, proId, action, count) => {
    // console.log("action", action);
    // console.log("specId", specId);
    // setCartUpdate(true);   

    const combinedData = {
        qty: count,
        product_id: proId,
        product_spec: specId,
        company_code: company_code,
        // company_code: user?.company_code,
      };
  
    
        setSpecData((prevSpecData) => {
            return prevSpecData.map((spec) => {
            if (spec._id == specId && spec.product_id?._id == proId) {
                return {
                ...spec,
                qty: action == 'increase' ? spec.qty + 1 : spec.qty > 1 ? spec.qty - 1 : 1 // Prevents qty from going below 1
                };
            }
            return spec;
            });
        });

        let storedList = JSON.parse(localStorage.getItem('cartLocal')) || [];

        const existingIndex = storedList.findIndex(
          item => 
            item.product_id === combinedData.product_id &&
            item.product_spec === combinedData.product_spec
        );
      
        if (existingIndex !== -1) {
          // Update the quantity of the existing item with the action (increase/decrease)
          storedList[existingIndex].qty = action === 'increase' 
            ? storedList[existingIndex].qty + 1 
            : storedList[existingIndex].qty > 1 
              ? storedList[existingIndex].qty - 1 
              : 1; // Prevents qty from going below 1
        } else {
          // Add the new combinedData object to the list with the initial count
          storedList.push(combinedData);
        }
      
        // Store the updated list back to local storage
        localStorage.setItem('cartLocal', JSON.stringify(storedList));

        dispatch(updateLocalCartLength(storedList.length));
  };

  const handleDelete = (specId) => {
    // Remove from specData
    setSpecData((prevSpecData) => prevSpecData.filter((spec) => spec._id !== specId));
  
    // Remove from localStorage
    let storedList = JSON.parse(localStorage.getItem('cartLocal')) || [];
    storedList = storedList.filter((item) => item.product_spec !== specId);
    localStorage.setItem('cartLocal', JSON.stringify(storedList));
    dispatch(updateLocalCartLength(storedList.length));
    if(storedList.length <= 0) {
      window.location.reload();
    };
  };

  const handleCheckout = () => {
    setOpen(true);    
        // navigate("/checkout");    
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };

  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };

  const loginForm1 = async (values) => {
    const Data = {
      ...values,
      company_code: company_code,
    };
    try {
      const user = await api1.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        Data,
        (err, res) => {
          // console.log("res--------->", res);
          if (res?.status === 200) {
          
          // console.log("res--------->", res);
            // message.success("Login Successful");
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("user", JSON.stringify(res?.data?.data));
            localStorage.setItem("login", true);
            localStorage.setItem("buyer_name", values?.buyer_number);
            localStorage.setItem("buyer_pasword", values?.buyer_password);
            if (res?.data?.data?.billing_details == false) {                  
              setAddAddressModal(true);  // Show modal to add address
            } else {
              addToCartInLoop();  // Proceed to add to cart if billing details are available
            }
        } else {
          // Handle other unsuccessful responses
          message.error("Unexpected response from API");
          setIsLoading2(false);
        }
      }
    );
  } catch (error) {
    // Handle unexpected errors
    message.error("Something went wrong");
    console.error("Login error:", error);  // Log the error for debugging
  }
};
  const login1 = async (values) => {
    console.log("values", values); 

    // setLoginLoading(true);
    const Data = {
      ...values,
      company_code: company_code,
    };
    try {
        const user = await api.create1(
          dispatch,
          [userGetAllStart, userGetAllSuccess, userGetAllFail],
          "verify-otp",
          Data,
          (err, res) => {   
            console.log("register res----->10", res);   
            console.log("register err----->", err);   
            if (err) {              
              message.error(err?.response?.data?.message);    
              return;
              setIsLoading2(false);
            }
  
            console.log("register res----->", res);
    
            if (res?.status === 200) {                
                console.log("register res----->123", res?.data?.data?.billing_details);
              setIsLoading2(false);
              localStorage.setItem("token", res.data.token);
              setIssToken(res.data.token);
              isToken = res.data.token;
              localStorage.setItem("user", JSON.stringify(res.data.data));
              localStorage.setItem("login", true);
              localStorage.setItem("buyer_name", values?.name);
              // localStorage.setItem("buyer_pasword", values?.buyer_password);            
                // addToCartInLoop(res.data.data);
                if( res?.data?.data?.billing_details == false) {                  
                  setAddAddressModal(true);
                } else {
                  addToCartInLoop();
                }
            } else if (err) {
              // Handle unsuccessful registration
              message.error(err.response?.data?.message || "Registration failed");
              setIsLoading2(false);
            } else {
              // Handle unexpected response (res is null or undefined)
              message.error("Unexpected response from API");
              setIsLoading2(false);
            }
          }
        );
      } catch (error) {
        message.error("Something went wrong");
        // console.error("Registration error:", error);
      }

    // setAddAddressModal(true);
  };

  const generateOtp = async () => {
    const MobNumber = loginForm.getFieldsValue("buyer_number");
    const MobName = loginForm.getFieldsValue("buyer_name");
    const MobEmail = loginForm.getFieldsValue("buyer_emailid");
    console.log("MobNumber", MobNumber);

    const data1 = {
      ...MobNumber,
      company_code: company_code,
    };
    //  {
    //   name: MobName,
    //   mobile: MobNumber,
    //   email: MobEmail,
    //   company_code: company_code
    // }
    const response = await api.createCommon('guest-register', data1);
    console.log("response", response);
    try {
        if (response?.status === 200) {
            setVisible(false);
            // setCountdown(120);
            // setResendDisabled(true);
            message.success(response.data.message);
            // message.success(response.data.data.code);
        } else {  
            message.error("Unable to generate OTP!");
        }
    } catch (error) {
        message.error(response?.response?.data?.message);
    }

    try {
      // Call API to generate OTP
      // await api.generateOtp(); // Uncomment when integrating API
      setOtpSent(true); // OTP is sent
      setVisible(false);  // Hide OTP input field and show the submit button

      // Start the resend OTP timer (30 seconds countdown)
      let countdown = 30;
      const timer = setInterval(() => {
        if (countdown > 0) {
          setResendCounter(countdown);
          countdown--;
        } else {
          clearInterval(timer);
          setResendCounter(0);  // Reset counter once the time is up
        }
      }, 1000);
    } catch (error) {
      message.error('Failed to generate OTP, please try again.');
    }    
};

  const addToCart = (item) => {
    setLoading(true);
    
    const combinedData = {
      qty: item?.qty,
      product_id: item?.product_id,
      product_spec: item?.product_spec,
      company_code: item?.company_code,      
    };

    const user = JSON.parse(localStorage.getItem("user"));
    
      const combinedDataWithUser = {
        ...combinedData,
        buyer_id: user?._id,
      };
      api.addCart(combinedDataWithUser).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart());
          api.getcart().then((res) => {
            dispatch(cartSuccess(res?.data));
          });
        //   getAllCartss();
        } else {
          dispatch(cartFailure());
        }
      });    
  };
  
    // const addToCartInLoop = async () => {
    //     const cartItems = JSON.parse(localStorage.getItem("cartLocal")) || [];
    //     for (let item of cartItems) {
    //         addToCart(item);
    //     }
    //     setAddAddressModal(false);
    //     navigate("/checkout");
    // };

    const addToCartInLoop = async () => {
      const cartItems = JSON.parse(localStorage.getItem("cartLocal")) || [];
      let allItemsProcessed = false;  
      
      const processCartItems = async () => {
          for (let item of cartItems) {
               addToCart(item); 
          }
          allItemsProcessed = true; 
      };
  
      await processCartItems(); 
      if (allItemsProcessed) {
          setAddAddressModal(false);
          navigate("/checkout");
          setTimeout(() => {
            window.location.reload();
        }, 100);
      }
  };
  

  const customFormItemStyle = {
    marginBottom: "8px", // Adjust the margin as per your requirement
  };

  // const OnfinishAddress = async (values) => {
  //   setIsLoading1(true);
  //   const response = await api.createCommon("buyerbillingdetails", values);
  //   if (response?.data?.success) {
  //     setIsLoading1(false);
  //     form.resetFields();
  //     setAddAddressModal(false);
  //       addToCartInLoop();
  //   } else {
  //     setIsLoading1(false);
  //     message.error(response?.data?.message);
  //   }
  // };

  const OnfinishAddress = async (values) => {
    // setIsLoading1(true);
    console.log("isToken", isstoken);

    try {
        const response = await axios.post(
            `${api_website}buyerbillingdetails`, 
            values,
            {
                headers: {
                    authorization: `Bearer ${isstoken}`,
                    company_code: `ecDigi ${company_code}`,
                },
            }
        );

        if (response?.data?.success) {
            setIsLoading1(false);
            form.resetFields();
            setAddAddressModal(false);
            addToCartInLoop();
        } else {
            setIsLoading1(false);
            message.error(response?.data?.message);
        }
    } catch (error) {
        setIsLoading1(false);
        message.error("An error occurred. Please try again.");
        console.error(error);
    }
};

  return (
    <CartSection1 className="wrapper" style={{ padding: "0px 0px 60px 0px" }}>
          {/* <h1>Cart</h1>           */}
            <div className="cart_section">
              <Card loading={loading} className="cart_left" style={{ backgroundColor: "white" }}>
                <div className="cart_list" id="style-10">
                  {specData?.map((item,index) => (
                    // console.log("item-->", item),
                    <div className="cart_box" key={item?._id}>
                      <div className="cart_box_left">
                        <Link
                          to={"/product/product_name/" + item?.product_id?._id}
                          className="cartbox_img"
                        >
                          <img
                            src={
                              api.rootUrl1 +
                              item?.product_images[0]?.image_path
                            }
                            alt="Cart"
                          />
                        </Link>
                      </div>
                      <div className="cart_box_right">
                        <Link
                          className="Produt_name"
                          to={"/product/product_name/" + item?.product_id?._id}
                        >
                          {item?.product_id?.product_name}
                        </Link>                    
                         

                        {/* {item?.quantity ? ((item?.quantity >= item?.qty) ? "" : (
                          <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                        )) : item?.product_id?.common_stock >= item?.qty ? "" : (
                          <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                        )  
                        }                     */}

                        {
                          item?.quantity
                            ? (
                              item?.quantity >= item?.qty
                                ? ""
                                : <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                            )
                            : (
                              item?.product_id?.common_stock >= item?.qty
                                ? ""
                                : <Tag color="red" style={{ width: "fit-content" }}>Out of Stock</Tag>
                            )
                        }

                        <h4>{item?.product_id?.common_sku ? item?.product_id?.common_sku : item?.sku}</h4>                        
                        <div className="cart_specification">
                          {item?.specification?.map((spec) => (
                            <Tag color="green" key={spec?._id}>
                              {spec?.specification_details}
                            </Tag>
                          ))}
                        </div>           

                        {/* {console.log("item?.product_id?.show_common_mrp", item?.product_id?.show_common_mrp)}             */}
                        {/* {console.log("item?.product_id?.show_common_sp", item?.product_id?.show_common_sp)}             */}
                        {console.log("item?.", item)}            

                       { (item?.show_mrp == true && item?.show_sp == false)  ? 
                        (
                          <div className="price">
                            <div className="mrp">
                            <span>M.R.P: </span>
                            {(item?.show_mrp == true && item?.show_sp == false) ? (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                    : ((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              ) 
                            : (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                    : ((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              )
                          }                             
                            </div>
                          </div> 
                        )
                        :
                       (item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false)  ? 
                        (
                          <div className="price">
                            <div className="mrp">
                            <span>M.R.P: </span>
                            {(item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false) ? (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                    : ((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              ) 
                            : (
                                styles.currency + (
                                  isDecimal?.isDecimal === false
                                    ? Math.round((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                    : ((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                )
                              )
                           }                             
                            </div>
                          </div> 
                        )
                        :
                        (<div className="price">     
                          <div className="sp">
                          {styles.currency + " " + (
                            item?.discount_type === "Percentage" ? (
                              item?.sp ? (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.sp - (item?.sp * item?.per_product_discount_amount / 100) 
                                      ) +
                                      Number(
                                        (item?.sp - (item?.sp * item?.per_product_discount_amount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.sp - (item?.sp * item?.per_product_discount_amount / 100) 
                                      ) +
                                      Number(
                                        (item?.sp - (item?.sp * item?.per_product_discount_amount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              )
                              :
                              (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100) 
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100) 
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - (item?.product_id?.common_sp * item?.per_product_discount_amount / 100)) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              ) 
                            ) : item?.discount_type === "Price" ? (
                              item?.sp ? (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.sp - item?.per_product_discount_amount 
                                      ) +
                                      Number(
                                        (item?.sp - item?.per_product_discount_amount) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.sp - item?.per_product_discount_amount 
                                      ) +
                                      Number(
                                        (item?.sp - item?.per_product_discount_amount) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              )
                              :
                              (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(
                                        item?.product_id?.common_sp - item?.per_product_discount_amount 
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - item?.per_product_discount_amount) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )
                                    )
                                  : (
                                      (Number(
                                        item?.product_id?.common_sp - item?.per_product_discount_amount 
                                      ) +
                                      Number(
                                        (item?.product_id?.common_sp - item?.per_product_discount_amount) * (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                      )).toFixed(isDecimal?.Decimal_points)
                                    )
                              ) 
                            ) : (
                              item?.product_id?.common_sp ? (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(item?.product_id?.common_sp) +
                                      Number(item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                                    )
                                  : (
                                      (Number(item?.product_id?.common_sp) +
                                      Number(item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                    )
                              ) : (
                                isDecimal?.isDecimal === false
                                  ? Math.round(
                                      Number(item?.sp) +
                                      Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                                    )
                                  : (
                                      (Number(item?.sp) +
                                      Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                                    )
                              )
                            )
                          )}
                          </div>

                          <div className="mrp">
                            <span>M.R.P: </span>
                          {item?.mrp ? (
                              styles.currency + (
                                isDecimal?.isDecimal === false
                                  ? Math.round((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                  : ((item?.mrp) + (item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                              )
                            )
                            :
                           (
                              styles.currency + (
                                isDecimal?.isDecimal === false
                                  ? Math.round((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)))
                                  : ((item?.product_id?.common_mrp) + (item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(isDecimal?.Decimal_points)
                              )
                            )}                           
                          </div>                         
                        </div>)}
                          
                            
                        {item?.discount_amount > 0 ? (
                          item?.discount_type == "Percentage" ?
                          (
                          <div className="Tax" style={{ color: "green" }}>
                            {item?.discount_amount > 0 ? " (" + "Discount" + "  " + item?.per_product_discount_amount + "%" + " , Tax" + "  " + item?.product_id?.tax_percentage?.tax_percentage + "%" + " Inclusive" + ")" : ""}
                          </div>
                          ) : (
                            <div className="Tax" style={{ color: "green" }}>
                            {item?.discount_amount > 0 ? " (" + "Discount" + "  " + `${styles?.currency}`+item?.per_product_discount_amount + " , Tax" + "  " + item?.product_id?.tax_percentage?.tax_percentage + "%" + " Inclusive" + ")" : ""}
                          </div>
                          )
                        ) : (
                          <div className="Tax" style={{ color: "green" }}>
                            {item?.tax_amount > 0
                              ? "(" + "Tax " + item?.product_id?.tax_percentage?.tax_percentage + "%" + " Inclusive" + ")"
                              : ""}
                          </div>
                        )}
                        <div className="cart_quantity" style={{ marginBottom: "1%" }}>
                          <div className="quantity_wrapper" style={{ display: "flex", gap: "10px" }}>
                            <Button
                              onClick={() => {
                                handleCartUpdate(item?._id, item?.product_id?._id, "decrease", item?.qty)
                                // setLoading(true)
                              }}
                              disabled={item?.qty === 1}
                              loading={cartUpdate}
                            >
                              -
                            </Button>
                            <Input
                              defaultValue={item?.qty}
                              value={item?.qty}
                              readOnly
                              min={1}
                              onChange={(e) => {
                                let val = parseInt(e.target.value);
                                handleCartUpdate(item?._id, item?.product_id?._id, val);
                              }}
                            />
                            <Button
                              onClick={() =>{
                                handleCartUpdate(item?._id, item?.product_id?._id, "increase")
                                // setLoading(true)
                              }}
                              loading={cartUpdate}
                              // disabled={item?.product_id?.common_stock ? (item?.qty >= Number(item?.product_id?.common_stock)) : item?.qty >= item?.product_spec?.quantity}
                              disabled={   
                                item?.qty >= item?.quantity
                                  ? true
                                  : item?.product_id?.common_stock
                                  ? item?.qty >= Number(item?.product_id?.common_stock) 
                                  : false 
                              }
                            >
                              +
                            </Button>
                          </div>

                         {(item?.show_mrp == true && item?.show_sp == false) ?
                         (
                          <div className="cart_price_total" style={{ display: "flex", marginLeft: "20px" }}>                            
                            {item?.discount_type === "Percentage" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.mrp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.mrp) -
                                          (item?.mrp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.mrp - 
                                          (item?.mrp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.mrp) -
                                            (item?.mrp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.mrp - 
                                            (item?.mrp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          (item?.sp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.sp - 
                                          (item?.sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            (item?.sp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.sp - 
                                            (item?.sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : item?.discount_type === "Price" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.mrp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.mrp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.mrp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.mrp) -
                                            item?.per_product_discount_amount 
                                          ) +
                                          Number(
                                            (item?.mrp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.sp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            item?.per_product_discount_amount 
                                          ) +
                                          Number(
                                            (item?.sp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.mrp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.mrp) +
                                          Number(item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.mrp) +
                                            Number(item?.mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) +
                                          Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) +
                                            Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            )} 
                          </div>
                         )
                         :
                         (item?.product_id?.show_common_mrp == true && item?.product_id?.show_common_sp == false) ?
                         (
                          <div className="cart_price_total" style={{ display: "flex", marginLeft: "20px" }}>                            
                            {item?.discount_type === "Percentage" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_id?.common_sp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_mrp) -
                                          (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.product_id?.common_mrp - 
                                          (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_mrp) -
                                            (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.product_id?.common_mrp - 
                                            (item?.product_id?.common_mrp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          (item?.sp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.sp - 
                                          (item?.sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            (item?.sp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.sp - 
                                            (item?.sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : item?.discount_type === "Price" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_id?.common_sp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_mrp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.product_id?.common_mrp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_mrp) -
                                            item?.per_product_discount_amount 
                                          ) +
                                          Number(
                                            (item?.product_id?.common_mrp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.sp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            item?.per_product_discount_amount 
                                          ) +
                                          Number(
                                            (item?.sp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            ) : (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.product_id?.common_sp ? (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_mrp) +
                                          Number(item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_mrp) +
                                            Number(item?.product_id?.common_mrp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) : (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) +
                                          Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) +
                                            Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))}
                              </Tag>
                            )} 
                          </div>
                         )
                          :
                          (<div className="cart_price_total" style={{ display: "flex", marginLeft: "20px" }}>                            
                            {item?.discount_type === "Percentage" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.sp ?  (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          (item?.sp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.sp - 
                                          (item?.sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            (item?.sp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.sp - 
                                            (item?.sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100)
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) 
                                :
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_sp) -
                                          (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100 
                                        ) +
                                        Number(
                                          (item?.product_id?.common_sp - 
                                          (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_sp) -
                                            (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100 
                                          ) +
                                          Number(
                                            (item?.product_id?.common_sp - 
                                            (item?.product_id?.common_sp * item?.per_product_discount_amount) / 100) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100)
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) 
                                 
                               }
                              </Tag>
                            ) : item?.discount_type === "Price" ? (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.sp ? 
                                 (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.sp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) -
                                            item?.per_product_discount_amount
                                          ) +
                                          Number(
                                            (item?.sp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))
                                :
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_sp) -
                                          item?.per_product_discount_amount 
                                        ) +
                                        Number(
                                          (item?.product_id?.common_sp - item?.per_product_discount_amount) * 
                                          (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_sp) -
                                            item?.per_product_discount_amount
                                          ) +
                                          Number(
                                            (item?.product_id?.common_sp - item?.per_product_discount_amount) * 
                                            (item?.product_id?.tax_percentage?.tax_percentage / 100) 
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                )) 
                                
                               }
                              </Tag>
                            ) : (
                              <Tag className="cart_price_description" color="green" style={{ width: "auto" }}>
                                Total:{" "}
                                {item?.sp ? 
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.sp) +
                                          Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.sp) +
                                            Number(item?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))
                                :
                                (
                                  styles.currency + " " + (isDecimal?.isDecimal === false
                                    ? Math.round(
                                        (Number(item?.product_id?.common_sp) +
                                          Number(item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation
                                        ) * item?.qty
                                      )
                                    : (
                                        (
                                          (Number(item?.product_id?.common_sp) +
                                            Number(item?.product_id?.common_sp * (item?.product_id?.tax_percentage?.tax_percentage / 100)) // Tax calculation
                                          ) * item?.qty
                                        ).toFixed(isDecimal?.Decimal_points)
                                      )
                                ))                                  
                                }
                              </Tag>
                            )} 
                          </div>)
                          }
                        </div>
                        <div className="remove_cart">
                          <Popconfirm
                            title="Delete Product"
                            description="Are you sure to delete this product?"
                            onConfirm={() => handleDelete(item?._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>

              <Card loading={loading} className="cart_right" style={{ backgroundColor: "white" }}>
                {/* <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "max-content" }}>
                        Price({cart?.cart_item?.length} items x{" "}
                        {cart?.total_quantity} qty)
                      </td>
                      {cart?.delivery_charge_before_total ? (
                        <td>₹ {isDecimal?.isDecimal === false 
                          ? Math.round(Number(cart?.delivery_charge_before_total)) 
                          : Math.round(Number(cart?.delivery_charge_before_total)).toFixed(isDecimal?.Decimal_points)}
                          </td>
                      ) : (<td>{"  "}</td>)
                        }                      
                    </tr>                    
                    {
                      cart?.total_delivery_charge > 0 ? (
                        <tr>
                          <td>Delivery Charges</td>
                          {cart?.total_delivery_charge ? (
                            <td>₹ {isDecimal?.isDecimal === false 
                              ? Math.round(Number(cart?.total_delivery_charge)) 
                              : Math.round(Number(cart?.total_delivery_charge)).toFixed(isDecimal?.Decimal_points)}
                            </td>
                          ) : (<td>{"  "}</td>)}                          
                        </tr>
                      ) : ""
                    }
                    <tr>
                      <td>Total Amount</td>
                      {cart?.calculated_total ? (
                        <td>₹ {isDecimal?.isDecimal === false 
                          ? Math.round(Number(cart?.calculated_total)) 
                          : Number(cart?.calculated_total).toFixed(isDecimal?.Decimal_points)}
                        </td>
                      ) : (<td>{"  "}</td>)
                      }                      
                    </tr>
                  </tbody>
                </table>
                <Divider /> */}

                {/* {specData?.map((item, index) => (
                    <tr>
                        <td style={{alignItems:"center",margin_right:"10px"}}>Total Amount :</td>
                          <Tag className="cart_price_description" color="white" style={{ width: "100%" }}>
                              {
                               
                                (item?.product_id?.show_common_mrp === true && item?.product_id?.show_common_sp === false) 
                                ? (
                                    styles.currency + (
                                      isDecimal?.isDecimal === false
                                        ? Math.round(
                                            (item?.product_id?.common_mrp || 0) + 
                                            ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                                          )
                                        : (
                                            (item?.product_id?.common_mrp || 0) + 
                                            ((item?.product_id?.common_mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))
                                          ).toFixed(isDecimal?.Decimal_points)
                                    ) * (item?.qty || 1)
                                  ) 
                                : (
                                  
                                    item?.product_id?.show_mrp === true && item?.product_id?.show_sp === false
                                      ? (
                                          styles.currency + (
                                            isDecimal?.isDecimal === false
                                              ? Math.round(
                                                  ((item?.mrp || 0) + 
                                                  ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))) * (item?.qty || 1)
                                                )
                                              : (
                                                  ((item?.mrp || 0) + 
                                                  ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))) * (item?.qty || 1)
                                                ).toFixed(isDecimal?.Decimal_points)
                                          )
                                        )
                                      : (
                                          
                                          (item?.mrp === null || item?.mrp === undefined || item?.mrp === 0 || item?.product_id?.show_mrp === false)
                                            ? (
                                                styles.currency + (
                                                  isDecimal?.isDecimal === false
                                                    ? Math.round(
                                                        ((item?.sp || item?.product_id?.common_sp || 0) + 
                                                        ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))) * (item?.qty || 1)
                                                      )
                                                    : (
                                                        ((item?.sp || item?.product_id?.common_sp || 0) + 
                                                        ((item?.sp || item?.product_id?.common_sp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))) * (item?.qty || 1)
                                                      ).toFixed(isDecimal?.Decimal_points)
                                                )
                                              )
                                            : (
                                                
                                                styles.currency + (
                                                  isDecimal?.isDecimal === false
                                                    ? Math.round(
                                                        ((item?.mrp || 0) + 
                                                        ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))) * (item?.qty || 1)
                                                      )
                                                    : (
                                                        ((item?.mrp || 0) + 
                                                        ((item?.mrp || 0) * (item?.product_id?.tax_percentage?.tax_percentage / 100))) * (item?.qty || 1)
                                                      ).toFixed(isDecimal?.Decimal_points)
                                                )
                                              )
                                        )
                                  )
                              }
                            </Tag>



                     
                    </tr>))                    
                } */}
<tr>
    <td style={{alignItems: "center", marginRight: "10px"}}>Total Amount :</td>
    <Tag className="cart_price_description" color="white" style={{ width: "100%" }}>
      {styles.currency + (isDecimal?.isDecimal === false
        ? Math.round(totalAmount)
        : totalAmount.toFixed(isDecimal?.Decimal_points))}
    </Tag>
  </tr>

                <Button
                  type="primary"
                  danger
                  block
                  onClick={() => handleCheckout()}                
                  // disabled={storedList.length <= 0 ? true : false}  
                //   disabled={cart?.calculated_total > 0 ? false : true}
                >
                  Checkout
                </Button>
              </Card>
            </div> 
            <Modal
                  open={open}
                  onCancel={handleCancel}
                  onOk={handleOk}
                  footer={null}
                  title="Enter Details"
                  width={400}
                >
                  {isLoginPassword == true ? 
                (<Form
                  layout="vertical"
                  form={loginForm}
                  name="Login_Form"
                  onFinish={loginForm1}
                >
                  <Form.Item
                    label="Enter Mobile number"
                    name="buyer_number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Mobile Number",
                      },
                      {
                        pattern: /^[0-9]{10}$/,
                        message: "Please enter valid Mobile Number!",
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <div className="Forgot_Password">
                    <Link to="/forgot-password">Forgot Password</Link>
                  </div>
                  <Form.Item
                    label="Enter Password"
                    name="buyer_password"
                    rules={[
                      {required: true, message: "Please enter your password!"},
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item>
                    <Button
                    
                      type="primary"
                      htmlType="submit"
                      block
                      loading={issSaving}
                      className="primary_btn"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>)
                  :
                  (<Form
                    layout="vertical"
                    form={loginForm}
                    name="Login_Form"
                    onFinish={login1}
                  >                    
                    <Form.Item
                      label="Enter Mobile number"
                      name="buyer_number"
                      rules={[
                        {
                          required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "Please enter your Mobile number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>                     
                    <Form.Item label="Otp" name="buyer_code" hidden={visible}rules={!visible ? [{ required: true, message: 'Please enter OTP!' }] : []}>
                      <Input placeholder="Otp" />
                    </Form.Item> 
                    {visible == true ? (
                      <Button type="primary" onClick={generateOtp}>
                        <LoginOutlined /> Generate OTP
                      </Button>
                    ) : (
                      <Form.Item>
                        <Button type="primary" htmlType="submit" loading={saving}>
                          <LoginOutlined /> Submit OTP
                        </Button>
                      </Form.Item>
                    )}

                      {otpSent && resendCounter === 0 && (
                        <Button type="link" onClick={generateOtp}>
                          Resend OTP
                        </Button>
                      )}

                      {/* Display the countdown for resend OTP */}
                      {otpSent && resendCounter > 0 && (
                        <p style={{ color: 'gray' }}>You can resend OTP in {resendCounter} seconds.</p>
                      )}
                  </Form>)}
            <Or>or</Or>

            {isLoginPassword == false ? (<NewAccount>
              <Link onClick={() => setIsLoginPassword(true)} >Click Here</Link>
              to Login Using Password
            </NewAccount>) : (<NewAccount>
              <Link onClick={() => setIsLoginPassword(false)} >Click Here</Link>
              to Login Using OTP
            </NewAccount>)}
                  {/* <Or>or</Or>

                  <NewAccount>
                    <Link to="/register">Click Here</Link>
                    to Register a Account
                  </NewAccount> */}
                </Modal>   
                <Modal
                  title="Add Address"
                  name="basic"
                  centered
                  open={addAddressModal}
                  // onCancel={() => setAddAddressModal(false)}
                  footer={null}
                  width={900}
                >
                  <Form
                    onFinish={OnfinishAddress}
                    form={form1}
                    autoComplete="off"
                    layout="vertical"
                    className="custom-form"
                  >               
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Name"
                          name="buyer_name"
                          style={customFormItemStyle}
                          rules={[
                            {
                              required: true,
                              message: "Please input your Name!",
                            },                        
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Email Id"
                          name="buyer_emailid"
                          style={customFormItemStyle}
                          rules={[
                            {
                              required: true,
                              message: "Please input your Email Id!",
                            },                        
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Mobile Number"
                          name="buyer_delivery_contact_number"
                          style={customFormItemStyle}
                          rules={[
                            {
                              required: true,
                              message: "Please input your mobile number!",
                            },
                            {
                              min: 10,
                              max: 10,
                              message: "Phone number must be in 10 digits.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Alternate Mobile Number"
                          name="buyer_delivery_alternate_number"
                          style={customFormItemStyle}
                          rules={[
                            {
                              min: 10,
                              max: 10,
                              message: "Phone number must be in 10 digits.",
                            }
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Address 1"
                          name="buyer_address_line_1"
                          style={customFormItemStyle}
                          rules={[
                            {
                              required: true,
                              message: "Please input your address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Address 2"
                          name="buyer_address_line_2"
                          style={customFormItemStyle}
                          rules={[
                            {
                              required: true,
                              message: "Please input your address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Pincode"
                          name="buyer_pincode"
                          style={customFormItemStyle}
                          rules={[
                            {
                              type: "regexp",
                              pattern: new RegExp("^d{4}$|^d{6}$"),
                              message: "Format is wrong",
                            },
                            {
                              required: true,
                              message: "Please input your pincode",
                            },
                            {
                              min: 6,
                              max: 6,
                              message: "Pincode must be in 6 digits.",
                            },
                          ]}
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Landmark"
                          name="landmark"
                          style={customFormItemStyle}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item label="Country" name="buyer_country">
                          <Input defaultValue="India" readOnly />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="State"
                          name="buyer_state"
                          rules={[
                            {
                              required: true,
                              message: "Please enter state",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="District"
                          name="buyer_district"
                          rules={[
                            {
                              required: true,
                              message: "Please enter city",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="City"
                          name="buyer_city"
                          rules={[
                            {
                              required: true,
                              message: "Please enter city",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Button
                      // type="submit"
                      // className="primary_btn"
                      // variant="contained"
                      htmlType="submit"
                      loading={isLoading1}
                    >
                      Save Address
                    </Button>
                  </Form>
                </Modal>      
        </CartSection1>
  )
}

export default CartGuest;

const CartSection1 = styled.section`
  width: 100%;
  /* margin: 60px 0; */
  padding: 60px 0;
  display: inline-block;

  .Tax {
    margin-top: 4px;
    color: #888;
    font-size: 13px;
  }

  .cart_price_description {
    height: 30px;
    width: 110px;
    font-size: 16px;
    color: #000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Produt_name {
    color: black;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    width: 100%;
  }

  .Produt_name:hover {
    color: ${styles.appColor} !important;
  }

  h1 {
    font-size: 27px;
    margin: 0 0 30px;
    display: inline-block;
    width: 100%;
  }

  .cart_section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .cart_left {
    width: 65%;
    display: inline-block;
    position: relative;
    /* padding: 25px 20px; */
    padding: 0px 0px;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    @media screen and (max-width: 1000px) {
      width: 100%;
      margin-bottom: 25px;
      padding: 0px 0px !important;
    }
  }

  .info_icon {
    padding: 2px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    border-radius: 40%;
    background-color: #54a958;
    margin-bottom: 2px;
  }

  .cart_list {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    padding-right: 15px;
  }

  #style-10::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 10px;
    padding-left: 5px;
  }

  #style-10::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
    padding-left: 10px;
  }

  #style-10::-webkit-scrollbar-thumb {
    padding-left: 10px;
    background-color: #aaa;
    border-radius: 10px;
    /* background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent) */
  }

  .cart_box {
    display: flex;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
  .cart_box:not(:last-child) {
    margin: 0 0 27px 0;
    padding: 0 0 27px 0;
  }
  .cart_box:not(:last-child)::after {
    content: "";
    border: 0;
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
    /* border-style: dashed; */
  }

  .cart_box_left {
    width: 150px;
    height: 100%;
    display: inline-block;
    border: 1px solid #f5f5f5;
    margin-right: 20px;
    @media screen and (max-width: 1000px) {
      margin-right: 20px;
      width: 180px;
    }
    @media screen and (max-width: 500px) {
      margin-right: 20px;
      width: 180px;
    }
  }
  .cart_box_left .cartbox_img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }

  .cart_box_left .cartbox_img:hover {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  .cart_box_right {
    width: 77%;
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    /* position: relative; */
    padding: 0 155px 0 0;
    @media screen and (max-width: 1000px) {
      width: 78%;
    }
    @media screen and (max-width: 500px) {
      padding: 0 25px 0 0;
    }
  }

  .cart_box_right h2 {
    font-size: 16px;
    font-family: var(--semibold);
    @media screen and (max-width: 500px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .cart_box_right h4 {
    font-size: 13px;
    color: #888;
    font-family: var(--light);
  }
  .cart_box_right .cart_specification {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
  }
  .price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
    margin: 5px 0;
  }
  .price div:nth-child(2) {
    font-size: 12px;
    font-family: var(--light);
    color: #888;
    text-decoration: line-through;
  }
  .price div:nth-child(1) {
    font-size: 18px;
    font-family: var(--semibold);
    font-weight: 600;
  }

  .price div:nth-child(3) {
    color: #388e3c;
    font-size: 14px;
    font-weight: 600;
  }

  .cart_quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 9px;
    position: absolute;
    bottom: 0;
    right: 0;
    /* border: 1px solid red;    */
  }
  .cart_quantity button {
    padding: 0;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart_quantity button span {
    font-size: 16px;
    font-family: var(--semibold);
  }
  .cart_quantity input {
    padding: 5px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 60px;
    text-align: center;
    outline: none;
  }
  .remove_cart {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    cursor: pointer;
  }
  .remove_cart span {
    color: red;
  }
  .cart_right {
    border: 1px solid #e9e9e9;
    width: 32%;
    border-radius: 5px;
    padding: 25px 15px;
    @media screen and (max-width: 1000px) {
      width: 60%;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  table {
    width: 100%;
  }
  tbody {
    width: 100%;
  }
  tbody tr {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
  tbody tr:not(:last-child) {
    margin: 0 0 20px;
  }
  tbody tr td:nth-child(1) {
    font-family: var(--semibold);
    text-align: left;
    /* padding: 0 20px 20px 0px; */
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr td:nth-child(2) {
    font-family: var(--regular);
    text-align: right;
    padding: 0 0 20px 10px;
    border-bottom: 1px solid #e9e9e9;
  }
  tbody tr:last-child td:nth-child(1),
  tbody tr:last-child td:nth-child(2) {
    border-bottom: 0px;
  }

  @media screen and (max-width: 1025px) {
    .cart_quantity{
      position: relative;
      margin-top: 20px;
      flex-wrap: nowrap;
    }
    
  }

  @media screen and (max-width: 768px) {    
    .cart_right{
      width: 100%;
    }
  }

  @media screen and (max-width: 540px) {
    .cartbox_img {
      width: 50%;
    }
    .cart_box{
      flex-direction: column;
    }
    .cart_box_left{
      width: 100px;
    }
    .cart_box_right{
      /* padding: 0px 50px 0px 0px; */
      padding: 0px 0px 0px 0px !important;
      width: 100%;
    }
  }

  @media screen and (max-width: 425px) {
    .cart_quantity{
    flex-direction: column;
    align-items: self-start;
    }
    .cart_price_total{
      margin-left: 0px !important;
    }       
  }

  @media screen and (max-width: 375px) {
    .cart_box_right .Produt_name{
      font-size: 14px;     
    }
    .cart_box_right{
      /* padding: 0px 30px 0px 0px; */
      padding: 0px 0px 0px 0px !important;
    }
    .cart_box_right h4 {
      font-size: 12px;
    }
    .mrp{
      font-size: 10px !important;
    }
    .sp {
      font-size: 12px !important;
    }
    .Tax{
      font-size: 12px !important;
    }
    .cart_quantity button{
      width: 20px;
      height: 20px;
    }
    .cart_quantity input{
      width: 40px;
      padding: 0px 8px;
    }
    .cart_price_description {
      font-size: 12px;
    }    
  }

  @media screen and (max-width: 320px) {
    .cart_box_right .Produt_name{
      font-size: 14px;     
    }
    .cart_box_right{
      /* padding: 0px 30px 0px 0px; */
      padding: 0px 0px 0px 0px !important;
    }
    .cart_box_right h4 {
      font-size: 12px;
    }
    .mrp{
      font-size: 10px !important;
    }
    .sp {
      font-size: 12px !important;
    }
    .Tax{
      font-size: 12px !important;
    }
    .cart_quantity button{
      width: 20px;
      height: 20px;
    }
    .cart_quantity input{
      width: 40px;
      padding: 0px 8px;
    }
    .cart_price_description {
      font-size: 12px;
    }    
  }

`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;

const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;