import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import { Link } from "react-router-dom"; 
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import BannerImg1 from "../../Assets/Images/OneWear/MainBannerImg1.jpg";
import BannerImg2 from "../../Assets/Images/OneWear/MainBannerImg1.jpg";
import BannerImg3 from "../../Assets/Images/OneWear/MainBannerImg1.jpg";
import BannerVid1 from "../../Assets/Videos/Onewear/Onewear-banner_6MB.mp4";
import BannerVid2 from "../../Assets/Videos/Onewear/Onewear-banner.mp4";
import "../../Assets/Fonts/fonts.css";

const contentStyle = {
  // height: "100vh",
  height: "auto",
  width: "100%",
  // objectFit: "cover",
  // objectFit: "fill",
  objectFit: "contain",
};

const overlayStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  textAlign: "center",
  width: "50%",
};

const slideStyle = {
  position: "relative",
};

const Banner12 = ({ content_all }) => {
  // console.log("content_allBanner12", content_all);
  // console.log("video", BannerVid1);
  const carouselRef = useRef(null);
  const [scale, setScale] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setScale(true);
      setTimeout(() => {
        setScale(false);
        carouselRef?.current?.next(); // Move to the next slide
      }, 2000); // Duration matches the CSS transition duration
    }, 4000); // Change this to your desired autoplay interval

    return () => clearInterval(interval);
  }, []);

  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  return (
    <BannerSection>
      {BannerVid2 ? (
        <Link to="/products">
         <video
         className="banner-video"
         controls={false} 
         autoPlay
         loop
         muted // Ensures the video starts muted to avoid abrupt sound
         style={{ width: "100%", height: "auto", ...contentStyle }}
       >
         <source
           src={BannerVid2} // Replace with the appropriate video source property
           type="video/mp4" // Ensure to match the video format
         />
         Your browser does not support the video tag.
       </video>
       </Link>
      )
      :
      (<>
      <Carousel 
        ref={carouselRef}
        arrows={false}
        autoplay  
        autoplaySpeed={4000}
        fade 
        infinite={true}
      >
        {content_all?.bannersOneWear?.map((item, index) => (
          <div style={slideStyle} key={index} className="banner-wrapper" className={`carousel-item ${scale ? 'scale' : ''}`}>
            <img
              className="banner-image"
              src={process.env.REACT_APP_BASE + item?.banner_img?.image}
              style={contentStyle}
            />
            <div className="overlay" style={overlayStyle}>
              <h2>{item?.description_main}</h2>
              <p>{item?.description_sub}</p>
              <div
                className="custom-button-wrapper"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "32%",
                  margin: "auto",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {login && token && userData ? (
                  <Button
                    className="custom-button"
                    ghost
                    style={{
                      borderRadius: 0,
                      fontWeight: "bold",
                    }}
                    onClick={() => (window.location.href = `/${item?.btn_link}`)}
                  >
                    {item?.btn_txt}
                  </Button>
                ) : (
                  <>
                    {/* <Button
                      className="custom-button"
                      ghost
                      style={{ borderRadius: 0, fontWeight: "bold" }}
                      onClick={() => (window.location.href = `/register`)}
                    >
                      SIGN UP
                    </Button> */}
                    <Button
                      className="custom-button"
                      ghost
                      style={{
                        borderRadius: 0,
                        fontWeight: "bold",
                      }}
                      onClick={() => (window.location.href = `/${item?.btn_link}`)}
                    >
                      {item?.btn_txt}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <ArrowLeft onClick={() => carouselRef.current.prev()}>
        <LeftOutlined />
      </ArrowLeft>
      <ArrowRight onClick={() => carouselRef.current.next()}>
        <RightOutlined />
      </ArrowRight>
      </>)}
    </BannerSection>
  );
};

export default Banner12;

const BannerSection = styled.div`
  position: relative;

  /* .banner-wrapper {
    background-color: #f5f5f5;
    
  img {      
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 1;
    object-fit: contain;
    }
  } */

  .overlay {
    h2 {
      font-size: 60px;
      font-family: Inter;
      font-weight: 700;
    }
  }

  .custom-button:hover {
    background-color: #ffffff !important;
    color: #c23b2c !important;
    border: 1px solid #ffffff !important;
  }

  /* .banner-image:hover {
    scale: 1.05;
    transition: all 0.3s ease-in-out;
  } */

    .carousel-item {
  transition: transform 2s ease-in-out;
}

.carousel-item.scale {
  transform: scale(1.05);
}

  @media (max-width: 1024px) {
    .banner-image {
      /* height: 45vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 912px) {
    .banner-image {
      /* height: 45vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 860px) {
    .banner-image {
      /* height: 45vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .banner-image {
      /* height: 45vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }
  }

  @media (max-width: 540px) {
    .banner-image {
      /* height: 55vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 480px) {
    .banner-image {
      /* height: 45vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  @media (max-width: 430px) {
    .banner-image {
      /* height: 37vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  @media (max-width: 414px) {
    .banner-image {
      /* height: 35vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 380px) {
    .banner-image {
      /* height: 45vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 360px) {
    .banner-image {
      /* height: 35vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 344px) {
    .banner-image {
      /* height: 32vh !important; */
      height: "auto",
    }
    .overlay {
      h2 {
        font-size: 4.5vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 2vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 18px;
`;

const ArrowLeft = styled(Arrow)`
  left: 10px;
`;

const ArrowRight = styled(Arrow)`
  right: 10px;
`;
