import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import API from "../../ApiService/ApiService";

const FollowUs = ({ content_all }) => {
  const api = new API();

  return (
    <BannerContainer>
      <Overlay className="overlay">
        <p>STAY CONNECTED</p>
        <a href={content_all?.onewear_follow_link} target="_blank" style={{ textDecoration: "none", color: "#E96027" }}>
        {/* <Link
          to={`/${content_all?.onewear_follow_link}`}
          style={{ textDecoration: "none", color: "#E96027" }}
        > */}
          <h4>{content_all?.onewear_follow_txt}</h4>
        {/* </Link> */}
        </a>
      </Overlay>
      <Marquee
        gradient={false}
        speed={100}
        pauseOnHover={true}
        className="marquee"
      >
        {content_all?.onewear_follow_images?.map((image, index) => (
          <img
            key={index}
            src={api.rootUrl1 + image.image}
            alt={`banner${index + 1}`}
            className="marquee-image"
          />
        ))}
      </Marquee>
    </BannerContainer>
  );
};

export default FollowUs;

const BannerContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #676F6B;

  .marquee-image {
    width: 33.33vw; /* Adjust to show 3 images at a time */
    object-fit: contain;
    height: auto;
    aspect-ratio: 1.44 / 1;
  }

  @media screen and (max-width: 768px) {
    .marquee-image {
    width: 50vw !important;   
  }   
  }

  @media screen and (max-width: 540px) {
    .marquee-image {
    width: 80vw !important;   
  }   
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #e96027;
  text-align: center;
  z-index: 2;

  p {
    font-family: Inter;
    font-size: 1rem;
  }

  h4 {
    font-family: Inter;
    font-size: 2rem;
  }

  @media screen and (max-width: 768px) {
    p {
    font-family: Inter;
    font-size: 1rem;
  }

  h4 {
    font-family: Inter;
    font-size: 2rem;
  }
  }

  @media screen and (max-width: 540px) {
    p {
    font-family: Inter;
    font-size: 0.5rem;
  }

  h4 {
    font-family: Inter;
    font-size: 1.5rem;
  }
  }
  
`;
